import { render, staticRenderFns } from "./player.vue?vue&type=template&id=24a948b9&scoped=true&"
import script from "./player.vue?vue&type=script&lang=js&"
export * from "./player.vue?vue&type=script&lang=js&"
import style0 from "./player.vue?vue&type=style&index=0&id=24a948b9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24a948b9",
  null
  
)

export default component.exports