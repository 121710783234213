<template>
  <div class="home">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">收风篮球</el-breadcrumb-item>
      <el-breadcrumb-item>WNBA</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <el-carousel height="297px" style="margin-bottom: 12px;">
        <el-carousel-item v-for="item in home.banner" :key="item">
          <div class="shuffling canClick" @click="jumpNewsDetail(item.id)">
            <img :src="item.head_image" alt="">
            <h3>{{ item.chinese_headline }}</h3>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div>
        <div class="blue">
          <div>头条文章</div>
          <div class="more canClick" @click="jumpPage(4)">更多>></div>
        </div>
        <div class="headline">
          <div v-for="item in home.news" :key="item" @click="jumpNewsDetail(item.id)" class="canClick">
            <img :src="item.team_info.logo" v-if="item.team_info" alt=""/>
            <img src="https://lq.sotips.cn/img/WNBA.png" v-if="!item.team_info" alt="">
            <span>{{ item.chinese_headline }}</span>
          </div>
        </div>
      </div>
      <div>
        <div class="blue">
          <div>近日比赛</div>
          <div class="more canClick" @click="jumpPage(0)">更多>></div>
        </div>
        <div v-for="item in home.games" :key="item" v-loading="loading">
          <div class="matchList">
            <div>
              <div>{{ item.competition_time }}</div>
              <div class="blue">{{ item.status }}</div>
            </div>
            <div class="line"></div>
            <div class="match">
              <div>
                <div class="blue">WNBA</div>
                <div>{{ item.chinese_mini_home }}</div>
                <div>{{ item.chinese_mini_away }}</div>
              </div>
              <div>
                <div>1</div>
                <div>{{ item.home_1 }}</div>
                <div>{{ item.away_1 }}</div>
              </div>
              <div>
                <div>2</div>
                <div>{{ item.home_2 }}</div>
                <div>{{ item.away_2 }}</div>
              </div>
              <div>
                <div>3</div>
                <div>{{ item.home_3 }}</div>
                <div>{{ item.away_3 }}</div>
              </div>
              <div>
                <div>4</div>
                <div>{{ item.home_4 }}</div>
                <div>{{ item.away_4 }}</div>
              </div>
              <div>
                <div>加时</div>
                <div>{{ item.home_ot }}</div>
                <div>{{ item.away_ot }}</div>
              </div>
              <div>
                <div>全场</div>
                <div :style="item.home_all!=='-' ? 'color:#e95c5c;font-weight: bold' : ''">{{ item.home_all }}</div>
                <div :style="item.away_all!=='-' ? 'color:#e95c5c;font-weight: bold' : ''">{{ item.away_all }}</div>
              </div>
              <div>
                <div>分差</div>
                <div v-if="item.status !== '未开赛' && item.home_2 !== '-'">半:{{
                    item.home_1 * 1 + item.home_2 * 1 - item.away_1 * 1 - item.away_2 * 1
                  }}
                </div>
                <div v-if="item.status !== '未开赛' && item.home_2 === '-'">半:{{
                    item.home_1 * 1 - item.away_1 * 1
                  }}
                </div>
                <div v-if="item.status !== '未开赛'">全:{{ item.home_all - item.away_all }}</div>
                <div v-if="item.status === '未开赛'">-</div>
                <div v-if="item.status === '未开赛'">-</div>
              </div>
              <div>
                <div>和数</div>
                <div v-if="item.status !== '未开赛' && item.home_2 !== '-'">半:{{
                    item.home_1 * 1 + item.home_2 * 1 + item.away_1 * 1 + item.away_2 * 1
                  }}
                </div>
                <div v-if="item.status !== '未开赛' && item.home_2 === '-'">半:{{
                    item.home_1 * 1 + item.away_1 * 1
                  }}
                </div>
                <div v-if="item.status !== '未开赛'">全:{{ item.home_all * 1 + item.away_all * 1 }}</div>
                <div v-if="item.status === '未开赛'">-</div>
                <div v-if="item.status === '未开赛'">-</div>
              </div>
              <div style="background: #f0f5fa;color: #cb4a4a">
                <div>胜负</div>
                <div v-if="item.bet365_current_odds">{{ item.bet365_current_odds.home }}</div>
                <div v-if="item.bet365_current_odds">{{ item.bet365_current_odds.away }}</div>
              </div>
              <div class="asian" style="background: #f0f5fa">
                <div>让分</div>
                <div v-if="item.bet365_current_asian">
                  <span v-show="item.bet365_current_asian.handicap>0">{{ item.bet365_current_asian.handicap }}</span>
                  <span>{{ item.bet365_current_asian.home }}</span>
                </div>
                <div v-if="item.bet365_current_asian">
                  <span v-show="item.bet365_current_asian.handicap<0">{{ -item.bet365_current_asian.handicap }}</span>
                  <span>{{ item.bet365_current_asian.away }}</span>
                </div>
              </div>
              <div style="background: #f0f5fa">
                <div>总分</div>
                <div v-if="item.bet365_current_over">
                  大<span>{{ item.bet365_current_over.handicap }}</span>
                  <span>{{ item.bet365_current_over.home }}</span>
                </div>
                <div v-if="item.bet365_current_over">
                  小<span>{{ item.bet365_current_over.handicap }}</span>
                  <span>{{ item.bet365_current_over.away }}</span>
                </div>
              </div>
              <div class="btnPoint">
                <div>统计</div>
                <div>
                  <div class="canClick" @click="jumpPointDetail(item.competition_id,'data')">数据</div>
                  <div class="canClick" @click="jumpPointDetail(item.competition_id,'product')">交易量</div>
                </div>
                <div>
                  <div class="canClick" @click="jumpPointDetail(item.competition_id,'index')">指数</div>
                  <div class="canClick" @click="jumpPointDetail(item.competition_id,'player')">球员</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!home.games.length" style="height: 300px">
          <el-empty description="今日暂无赛事" image="https://lq.sotips.cn/img/empty.png"></el-empty>
        </div>
      </div>
    </div>
    <div class="rank">
      <div>
        <div class="blue">
          <div @click="dChange(1)" :class="['canClick',division===1 ? '':'darkGrey']">东部球队排名</div>
          <div @click="dChange(2)" :class="['canClick',division===2 ? '':'darkGrey']">西部球队排名</div>
          <div class="more canClick" @click="jumpPage(1)">更多>></div>
        </div>
        <el-table :data="showTeam" stripe @row-click="jumpTeamDetail">
          <el-table-column type="index" label="排名" width="50"></el-table-column>
          <el-table-column prop="position" label="球员">
            <template slot-scope="scope">
              <img :src="scope.row.team.logo" width="22" height="22" class="head_pic" alt=""/>
              <span>{{ scope.row.team.chinese_mini_name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="points_per_game" label="胜负" width="60">
            <template slot-scope="scope">
              <span>{{ scope.row.win }}/{{ scope.row.lose }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <div class="blue">
          <div>球员排名</div>
          <div class="more canClick" @click="jumpPage(2)">更多>></div>
        </div>
        <el-table :data="home.players" stripe @row-click="jumpPlayerDetail">
          <el-table-column type="index" label="排名" width="50"></el-table-column>
          <el-table-column prop="player_info.name" label="球员">
            <template slot-scope="scope">
              <img :src="scope.row.player_info.avatar" width="22" height="33" class="head_pic" alt=""/>
              <span v-if="scope.row.player_info.chinese_name">{{ scope.row.player_info.chinese_name }}</span>
              <span v-if="!scope.row.player_info.chinese_name">{{ scope.row.player_info.name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="points_per_game" label="得分" width="60"></el-table-column>
        </el-table>
      </div>
      <div>
        <div class="blue">
          <div>裁判排名</div>
          <div class="more canClick" @click="jumpPage(3)">更多>></div>
        </div>
        <el-table :data="home.referees" stripe @row-click="jumpRefereeDetail">
          <el-table-column type="index" label="排名" width="50"></el-table-column>
          <el-table-column prop="referee_info.name" label="裁判">
            <template slot-scope="scope">
              <span v-if="!scope.row.referee_info.chinese_name">{{ scope.row.referee_info.name }}</span>
              <span v-if="scope.row.referee_info.chinese_name">{{ scope.row.referee_info.chinese_name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="points_per_game" label="胜负" width="60">
            <template slot-scope="scope">
              <span>{{ scope.row.win }}/{{ scope.row.lose }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div style="width:100%;height: 50px"></div>
  </div>
</template>

<script>
import {index} from '@/api/WNBA/WNBA'

export default {
  name: "home",
  data() {
    return {
      home: {},
      showTeam: [],
      division: 1,
    }
  },
  methods: {
    dChange(type) {
      this.division = type
      if (type === 1) {
        this.showTeam = this.home.east_teams
      } else {
        this.showTeam = this.home.west_teams
      }
    },
    jumpPage(type) {
      if (type === 0) {
        this.$router.push('/WNBA/point')
      } else if (type === 1) {
        this.$router.push('/WNBA/ranking')
      } else if (type === 2) {
        this.$router.push({path: '/WNBA/ranking', query: {type: 'player'}})
      } else if (type === 3) {
        this.$router.push('/WNBA/referee')
      } else if (type === 4) {
        this.$router.push('/WNBA/news')
      }
    },
    jumpPointDetail(id, type) {
      const page = this.$router.resolve({path: '/WNBA/pointDetail', query: {id: id, type: type}})
      window.open(page.href, '_blank')
    },
    jumpNewsDetail(id) {
      const page = this.$router.resolve({path: '/WNBA/newsDetail', query: {id: id}})
      window.open(page.href, '_blank')
    },
    jumpTeamDetail(row) {
      const page = this.$router.resolve({path: '/WNBA/teamDetail', query: {team: row.team.name}})
      window.open(page.href, '_blank')
    },
    jumpPlayerDetail(row) {
      const page = this.$router.resolve({path: '/WNBA/playerDetail', query: {id: row.player_id}})
      window.open(page.href, '_blank')
    },
    jumpRefereeDetail(row) {
      const page = this.$router.resolve({path: '/WNBA/refereeDetail', query: {name: row.referee_info.name}})
      window.open(page.href, '_blank')
    },
  },
  mounted() {
    index().then(res => {
      this.home = res.data.data
      this.showTeam = this.home.east_teams
      for (let x in this.home.games) {
        this.home.games[x].competition_time = this.home.games[x].competition_time.substring(5, this.home.games[x].competition_time.length - 3)
        this.home.games[x].status = this.home.games[x].status === 'Final' ? '已完结' : this.home.games[x].status
        this.home.games[x].status = this.home.games[x].status === '' ? '未开赛' : this.home.games[x].status
      }
    })
  }
}
</script>

<style scoped>
.home {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.content {
  width: 890px;
}

.rank {
  width: 290px;
}

.rank img {
  margin-right: 6px;
  vertical-align: middle;
}

.rank > div {
  margin-bottom: 12px;
  background: #FFFFFF;
}

.home > div > div > :first-child {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid #EBEEF5;
}

.more {
  margin-top: 2px;
  font-size: 14px;
}

.darkGrey {
  color: #999999;
}

.content > div > div {
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 12px;
  background: #FFFFFF;
}

.matchList {
  display: flex;
  font-size: 14px;
  text-align: center;
}

.matchList > div:first-child {
  width: 60px;
  padding: 8px 0;
}

.matchList > div:first-child > div {
  padding: 6px 0;
}

.line {
  margin-right: 12px;
  border: 1px solid #E9EAEA;
}

.match {
  width: 800px;
  border: 1px solid #EBEEF5;
  display: flex;
  justify-content: space-around;
}

.match > div > div {
  width: 46px;
  height: 28px;
  line-height: 28px;
  border: 1px solid #EBEEF5;
}

.match > div > div:first-child {
  color: #222222;
  background: #f1f1f1;
}

.match > div:first-child > div {
  width: 60px;
}

.match > div:nth-child(11) > div {
  width: 54px;
}

.match > div:nth-child(12) > div {
  width: 100px;
}

.match > div:last-child > div {
  width: 126px;
}

.match span {
  margin: 0 4px;
}

.match span:last-child {
  color: #cb4a4a;
}

.asian > div {
  padding: 0 10px;
}

.asian > div:nth-child(n+2) {
  display: flex;
  justify-content: flex-end;
}

.btnPoint > div {
  display: flex;
  justify-content: space-around;
}

.btnPoint > div > div {
  width: 60px;
}

.shuffling {
  height: 100%;
  color: #FFFFFF;
  position: relative;
  background: #6993ff;
}

.shuffling img {
  width: 100%;
}

.shuffling h3 {
  left: 20px;
  bottom: 24px;
  position: absolute;
  text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
}

.headline {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.headline > div {
  width: calc(50% - 24px);
  font-size: 14px;
  padding: 10px 12px;
  background: #FFFFFF;
  border-bottom: 1px solid #EBEEF5;
}

.headline img {
  width: 40px;
  margin-top: -4px;
  margin-right: 10px;
  vertical-align: middle;
}
</style>