import ajax from 'axios'
import url from '../baseUrl'

//球队联赛
export const index = team_id => { return ajax.get(url + `/api/nbl/player/index`, {params: {team_id: team_id} ,headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

//球员详情
export const detail = id => { return ajax.get(url + `/api/nbl/player/detail`, {params: {player_id: id} ,headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

//球员新闻
export const news = id => { return ajax.get(url + `/api/nbl/player/news`, {params: {player_id: id} ,headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}