import ajax from 'axios'
import url from '../baseUrl'

//今日裁判
export const index = time => { return ajax.get(url + `/api/nbl/referee/index`, { params: { competition_time: time },headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

//裁判排名
export const rank = () => { return ajax.get(url + `/api/nbl/referee/rank`, {headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

//裁判详情
export const detail = name => { return ajax.get(url + `/api/nbl/referee/detail`, { params: {referee_name: name},headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}