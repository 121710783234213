import ajax from 'axios'
import url from '../baseUrl'

//球队联赛
export const index = team => { return ajax.get(url + `/api/nba/player/index`, {params: {team: team} ,headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

//球员详情
export const detail = id => { return ajax.get(url + `/api/nba/player/detail`, {params: {player_id: id} ,headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

//球员新闻
export const news = id => { return ajax.get(url + `/api/nba/player/news`, {params: {player_id: id} ,headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}