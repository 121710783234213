<template>
  <div class="team">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">收风篮球</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/NBA' }">NBA</el-breadcrumb-item>
      <el-breadcrumb-item>球队</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div>
        <div class="blue">东南赛区</div>
        <router-link v-for="item in teamsName.Southeast" :key="item.id" class="canClick" :to="{path:'/NBA/teamDetail',query:{team:item.name}}" target="_blank">
          <img :src="item.team.logo" alt="">
          <span>{{item.team.chinese_mini_name}}</span>
        </router-link>
      </div>
      <div>
        <div class="blue">中部赛区</div>
        <router-link v-for="item in teamsName.Central" :key="item.id" class="canClick" :to="{path:'/NBA/teamDetail',query:{team:item.name}}" target="_blank">
          <img :src="item.team.logo" alt="">
          <span>{{item.team.chinese_mini_name}}</span>
        </router-link>
      </div>
      <div>
        <div class="blue">大西洋赛区</div>
        <router-link v-for="item in teamsName.Atlantic" :key="item.id" class="canClick" :to="{path:'/NBA/teamDetail',query:{team:item.name}}" target="_blank">
          <img :src="item.team.logo" alt="">
          <span>{{item.team.chinese_mini_name}}</span>
        </router-link>
      </div>
      <div style="width: 10px;background: transparent"></div>
      <div>
        <div class="blue">太平洋赛区</div>
        <router-link v-for="item in teamsName.Pacific" :key="item.id" class="canClick" :to="{path:'/NBA/teamDetail',query:{team:item.name}}" target="_blank">
          <img :src="item.team.logo" alt="">
          <span>{{item.team.chinese_mini_name}}</span>
        </router-link>
      </div>
      <div>
        <div class="blue">西北赛区</div>
        <router-link v-for="item in teamsName.Northwest" :key="item.id" class="canClick" :to="{path:'/NBA/teamDetail',query:{team:item.name}}" target="_blank">
          <img :src="item.team.logo" alt="">
          <span>{{item.team.chinese_mini_name}}</span>
        </router-link>
      </div>
      <div>
        <div class="blue">西南赛区</div>
        <router-link v-for="item in teamsName.Southwest" :key="item.id" class="canClick" :to="{path:'/NBA/teamDetail',query:{team:item.name}}" target="_blank">
          <img :src="item.team.logo" alt="">
          <span>{{item.team.chinese_mini_name}}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {teamsName} from "@/api/NBA/team";

export default {
  name: "team",
  data(){
    return{
      teamsName: {}
    }
  },
  methods:{
    sortNum(a,b){
      return a.rank - b.rank
    }
  },
  mounted() {
    teamsName().then(res => {
      this.teamsName = res.data.data
      this.teamsName.Southeast.sort(this.sortNum)
      this.teamsName.Central.sort(this.sortNum)
      this.teamsName.Atlantic.sort(this.sortNum)
      this.teamsName.Pacific.sort(this.sortNum)
      this.teamsName.Northwest.sort(this.sortNum)
      this.teamsName.Southwest.sort(this.sortNum)
    })
  }
}
</script>

<style scoped>
  .content{
    display: flex;
  }
  .content > div{
    width: 16%;
    text-align: center;
    background-color: #FFFFFF;
  }
  .content > div > div:first-child{
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #E9EAEA;
  }
  .content > div > a:nth-child(n+2){
    height: 83px;
    display: flex;
    line-height: 83px;
  }
  .content > div > a:nth-child(2n+3){
    background-color: #EFEFEF;
  }
  img{
    width: 50px;
    height: 50px;
    margin-top: 18px;
    margin-left: 30px;
    vertical-align:middle;
  }
  .canClick span{
    min-width: 90px;
    font-size: 16px;
  }
</style>