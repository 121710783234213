<template>
  <div class="news" v-loading="loading">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">收风篮球</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/NBL' }">NBL</el-breadcrumb-item>
      <el-breadcrumb-item>火线详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="detail">
      <div>
        <div class="newsTitle">{{detail.news.chinese_headline}}</div>
        <div class="time">
          <img v-if="detail.team" :src="detail.team.logo" alt="" @click="jumpTeam('NBL',detail.team.team_id)">
          <img v-if="!detail.team" src="../../../../assets/logo/NBL.png" alt="">
          <span>{{detail.news.created_at}} </span>
        </div>
        <div>{{detail.news.chinese_describes}}</div>
        <img :src="detail.news.head_image" v-if="detail.news.head_image!=='https://admin.covers.sotips.cn'" alt="">
      </div>
      <div class="player" v-if="detail.player">
        <div @click="jumpPlayer('NBL',detail.player.player.player_id)">
          <img :src="detail.player.player.avatar" alt="">
          <div v-if="detail.player.player.player_name_cn">{{detail.player.player.player_name_cn}}</div>
          <div v-if="!detail.player.player.player_name_cn">{{detail.player.player.player_name_cn_en}}</div>
        </div>
        <div>
          <div>{{detail.player.season}}常规赛</div>
          <div>
            <div>
              <div>号码</div>
              <div>{{detail.player.player.number}}</div>
            </div>
            <div>
              <div>场均时间</div>
              <div>{{detail.player.minutes}}</div>
            </div>
            <div>
              <div>命中率</div>
              <div>{{detail.player.shot_percentage}}%</div>
            </div>
          </div>
          <div>
            <div>
              <div>位置</div>
              <div>{{detail.player.player.position_cn}}</div>
            </div>
            <div>
              <div>场均得分</div>
              <div>{{detail.player.points}}</div>
            </div>
            <div>
              <div>±值</div>
              <div>{{detail.player.positive_negative_value}}</div>
            </div>
          </div>
          <div>
            <div>
              <div>场次</div>
              <div>{{detail.player.game_played}}</div>
            </div>

            <div>
              <div>场均篮板</div>
              <div>{{detail.player.rebound}}</div>
            </div>
            <div>
              <div>场均助攻</div>
              <div>{{detail.player.assists}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="team" v-if="detail.team">
        <div @click="jumpTeam('NBL',detail.team.team_id)">
          <img :src="detail.team.logo" alt="">
          <div>{{detail.team.team_name_abb}}</div>
        </div>
        <div>
          <div>{{detail.team.season}}常规赛</div>
          <div>
            <div>
              <div>胜负</div>
              <div>{{detail.team.win}} - {{detail.team.lose}}</div>
            </div>
            <div>
              <div>让分</div>
              <div>{{detail.team.asian_win}} - {{detail.team.asian_draw}} - {{detail.team.asian_lose}}</div>
            </div>
            <div>
              <div>总分</div>
              <div>{{detail.team.total_win}} - {{detail.team.total_draw}} - {{detail.team.total_lose}}</div>
            </div>
          </div>
          <div>
            <div>
              <div>胜负胜率</div>
              <div>{{detail.team.win_rate}}%</div>
            </div>
            <div>
              <div>让分胜率</div>
              <div>{{detail.team.asian_win_rate}}%</div>
            </div>
            <div>
              <div>大分胜率</div>
              <div>{{detail.team.total_win_rate}}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="more">
      <div class="blue">相关消息</div>
      <div v-for="item in detail.more" :key="item" @click="rush(item.id)">
        <img :src="item.team_info.logo" v-if="item.team_info" alt=""/>
        <img src="https://lq.sotips.cn/img/NBL.png" v-if="!item.team_info" alt="">
        <div>{{item.chinese_headline}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {detail} from '@/api/NBL/news'
export default {
  name: "newsDetail",
  data(){
    return{
      detail: {},
      loading: false
    }
  },
  methods:{
    rush(id){
      const page = this.$router.resolve({path: '/NBL/newsDetail', query: {id: id}})
      window.open(page.href,'_blank')
    },
    getDetail(id){
      this.loading = true
      detail(id).then( res => {
        this.loading = false
        this.detail = res.data.data
        let reg = new RegExp("&nbsp;","g");
        this.detail.news.describes = this.detail.news.describes.replace(reg,'')
        this.detail.news.new = this.detail.news.new.replace(reg,'')
      })
    }
  },
  mounted() {
    this.getDetail(this.$route.query.id)
  }
}
</script>

<style scoped>
  .news{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 50px;
  }

  .detail{
    width: 842px;
    padding: 24px;
    background: #FFFFFF;
  }

  .time{
    color: #aaaaaa;
    font-size: 12px;
  }

  .time img{
    height: 30px;
    margin-top: -4px;
    margin-right: 6px;
    vertical-align: middle;
  }

  .newsTitle{
    font-size: 26px;
    font-weight: bold;
  }

  .detail > :first-child > div{
    margin-bottom: 12px;
  }

  .detail > :first-child > img{
    width: 100%;
  }

  .more{
    width: 290px;
    font-size: 14px;
  }

  .blue{
    font-size: 16px;
  }

  .more > div{
    display: flex;
    padding: 10px 12px;
    background: #FFFFFF;
    border-bottom: 1px solid #EBEEF5;
  }

  .more img{
    width: 40px;
    margin-right: 10px;
  }

  .original{
    color: #1a71c8;
    font-size: 12px;
  }

  .player{
    display: flex;
    font-size: 14px;
    margin-bottom: 8px;
    border-radius: 6px;
    border: 1px solid #EBEEF5;
  }
  .player > div{
    border: 1px solid #EBEEF5;
  }

  .player img{
    margin-top: 20px;
    margin-bottom: 16px;
    width: 60px;
  }

  .player > :first-child{
    width: 90px;
    padding: 6px 12px;
    text-align: center;
  }
  .player > :last-child{
    width: calc(100% - 90px);
  }
  .player > :last-child > div{
    padding: 8px;
    display: flex;
    justify-content: space-around;
  }
  .player > :last-child > :nth-child(n+2){
    border-top: 2px solid #EBEEF5;
  }
  .player > :last-child > div > div{
    width: 33%;
    display: flex;
    justify-content: space-around;
  }
  .player > :last-child > div > div > div{
    width: 50%;
    text-align: center;
  }

  .team{
    display: flex;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid #EBEEF5;
  }
  .team img{
    margin-top: 8px;
    width: 60px;
  }
  .team > div{
    border: 1px solid #EBEEF5;
  }
  .team > :first-child{
    width: 90px;
    padding: 6px 12px;
    text-align: center;
  }
  .team > :last-child{
    width: calc(100% - 90px);
  }
  .team > :last-child > div{
    padding: 8px;
    display: flex;
    justify-content: space-around;
  }
  .team > :last-child > :nth-child(n+2){
    border-top: 2px solid #EBEEF5;
  }
  .team > :last-child > div > div{
    width: 33%;
    display: flex;
    justify-content: space-around;
  }
  .team > :last-child > div > div > div{
    width: 50%;
    text-align: center;
  }
</style>