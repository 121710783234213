<template>
  <div class="team">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">收风篮球</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/NBL' }">NBL</el-breadcrumb-item>
      <el-breadcrumb-item>球队</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <router-link v-for="item in teams" :key="item.id" class="canClick"
                   :to="{path:'/NBL/teamDetail',query:{team:item.team_id}}" target="_blank">
        <img :src="item.logo" alt="">
        <span>{{ item.team_name_abb }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import {teamsName} from "@/api/NBL/team";

export default {
  name: "team",
  data() {
    return {
      teams: {}
    }
  },
  mounted() {
    teamsName().then(res => {
      this.teams = res.data.data
    })
  }
}
</script>

<style scoped>
.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.content > a {
  width: 20%;
  text-align: center;
  background-color: #FFFFFF;
}

.content > a {
  height: 83px;
  display: flex;
  line-height: 83px;
}

.content > a:nth-child(2n) {
  background-color: #EFEFEF;
}

img {
  width: 60px;
  height: auto;
  object-fit: contain;
  margin-left: 30px;
  vertical-align: middle;
}

.canClick span {
  min-width: 90px;
  font-size: 16px;
}
</style>