<template>
  <div v-loading="loading">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">收风篮球</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/WNBA' }">WNBA</el-breadcrumb-item>
      <el-breadcrumb-item>比分详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="head">
      <div v-if="matchDetail">
        <div class="teamName canClick" style="text-align: right" @click="jumpTeam('WNBA',matchDetail.home)">
          {{ matchDetail.chinese_mini_home }}
        </div>
        <img class="canClick" :src="matchDetail.team_home.logo" alt="" @click="jumpTeam('WNBA',matchDetail.home)">
        <div class="point">{{ matchDetail.home_all }}</div>
        <div class="center">
          <div>{{ matchDetail.competitionTime }}</div>
          <div>VS</div>
          <div>{{ matchDetail.status }}</div>
        </div>
        <div class="point">{{ matchDetail.away_all }}</div>
        <img class="canClick" :src="matchDetail.team_away.logo" alt="" @click="jumpTeam('WNBA',matchDetail.away)">
        <div class="teamName canClick" style="text-align: left" @click="jumpTeam('WNBA',matchDetail.away)">
          {{ matchDetail.chinese_mini_away }}
        </div>
      </div>
      <div class="pointTable">
        <div>
          <div>球队</div>
          <div @click="jumpTeam('WNBA',matchDetail.home)" class="canClick">{{ matchDetail.chinese_mini_home }}</div>
          <div @click="jumpTeam('WNBA',matchDetail.away)" class="canClick">{{ matchDetail.chinese_mini_away }}</div>
        </div>
        <div>
          <div>第一节</div>
          <div>{{ matchDetail.home_1 }}</div>
          <div>{{ matchDetail.away_1 }}</div>
        </div>
        <div>
          <div>第二节</div>
          <div>{{ matchDetail.home_2 }}</div>
          <div>{{ matchDetail.away_2 }}</div>
        </div>
        <div>
          <div>第三节</div>
          <div>{{ matchDetail.home_3 }}</div>
          <div>{{ matchDetail.away_3 }}</div>
        </div>
        <div>
          <div>第四节</div>
          <div>{{ matchDetail.home_4 }}</div>
          <div>{{ matchDetail.away_4 }}</div>
        </div>
        <div>
          <div>加时</div>
          <div>{{ matchDetail.home_ot }}</div>
          <div>{{ matchDetail.away_ot }}</div>
        </div>
        <div>
          <div>全场</div>
          <div>{{ matchDetail.home_all }}</div>
          <div>{{ matchDetail.away_all }}</div>
        </div>
        <div>
          <div>分差</div>
          <div v-if="matchDetail.status!=='未开赛'">
            半：{{ matchDetail.home_1 * 1 + matchDetail.home_2 * 1 - matchDetail.away_1 * 1 - matchDetail.away_2 * 1 }}
          </div>
          <div v-if="matchDetail.status!=='未开赛'">全：{{ matchDetail.home_all - matchDetail.away_all }}</div>
          <div v-if="matchDetail.status==='未开赛'">-</div>
          <div v-if="matchDetail.status==='未开赛'">-</div>
        </div>
        <div>
          <div>和数</div>
          <div v-if="matchDetail.status!=='未开赛'">
            半：{{ matchDetail.home_1 * 1 + matchDetail.home_2 * 1 + matchDetail.away_1 * 1 + matchDetail.away_2 * 1 }}
          </div>
          <div v-if="matchDetail.status!=='未开赛'">全：{{ matchDetail.home_all * 1 + matchDetail.away_all * 1 }}</div>
          <div v-if="matchDetail.status==='未开赛'">-</div>
          <div v-if="matchDetail.status==='未开赛'">-</div>
        </div>
        <div>
          <div>胜负</div>
          <div v-if="matchDetail.odds365_handicap">{{ matchDetail.odds365_handicap.home }}</div>
          <div v-if="matchDetail.odds365_handicap">{{ matchDetail.odds365_handicap.away }}</div>
          <div v-if="!matchDetail.odds365_handicap">-</div>
          <div v-if="!matchDetail.odds365_handicap">-</div>
        </div>
        <div>
          <div>让分</div>
          <div v-if="matchDetail.asian365_handicap">
            <span v-show="matchDetail.asian365_handicap.handicap>0">{{ matchDetail.asian365_handicap.handicap }}</span>&nbsp;
            <span>{{ matchDetail.asian365_handicap.home }}</span>
          </div>
          <div v-if="matchDetail.asian365_handicap">
            <span v-show="matchDetail.asian365_handicap.handicap<0">{{ -matchDetail.asian365_handicap.handicap }}</span>&nbsp;
            <span>{{ matchDetail.asian365_handicap.away }}</span>
          </div>
          <div v-if="!matchDetail.asian365_handicap">-</div>
          <div v-if="!matchDetail.asian365_handicap">-</div>
        </div>
        <div>
          <div>总分</div>
          <div v-if="matchDetail.asian365_handicap">
            大&nbsp;<span>{{ matchDetail.over365_handicap.handicap }}</span>&nbsp;
            <span>{{ matchDetail.over365_handicap.home }}</span>
          </div>
          <div v-if="matchDetail.asian365_handicap">
            小&nbsp;<span>{{ matchDetail.over365_handicap.handicap }}</span>&nbsp;
            <span>{{ matchDetail.over365_handicap.away }}</span>
          </div>
          <div v-if="!matchDetail.asian365_handicap">-</div>
          <div v-if="!matchDetail.asian365_handicap">-</div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="total">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="数据" name="data" class="fontSize">
            <div class="blue title" id="total" ref="total">战绩统计</div>
            <div class="boxHeight panTotal">
              <div>
                <div></div>
                <div>总</div>
                <div>主</div>
                <div>客</div>
              </div>
              <div>
                <div></div>
                <div>
                  <div>战绩</div>
                  <div>让分</div>
                  <div>总分</div>
                </div>
                <div>
                  <div>战绩</div>
                  <div>让分</div>
                  <div>总分</div>
                </div>
                <div>
                  <div>战绩</div>
                  <div>让分</div>
                  <div>总分</div>
                </div>
              </div>
              <div>
                <div @click="jumpTeam('WNBA',matchDetail.home)" class="canClick">{{ matchDetail.chinese_mini_home }}</div>
                <div>
                  <div>{{ dataShow.home_result_all }}</div>
                  <div>{{ dataShow.home_asian_all }}</div>
                  <div>{{ dataShow.home_over_all }}</div>
                </div>
                <div>
                  <div>{{ dataShow.home_result_home }}</div>
                  <div>{{ dataShow.home_asian_home }}</div>
                  <div>{{ dataShow.home_over_home }}</div>
                </div>
                <div>
                  <div>{{ dataShow.home_result_away }}</div>
                  <div>{{ dataShow.home_asian_away }}</div>
                  <div>{{ dataShow.home_over_away }}</div>
                </div>
              </div>
              <div>
                <div @click="jumpTeam('WNBA',matchDetail.away)" class="canClick">{{ matchDetail.chinese_mini_away }}</div>
                <div>
                  <div>{{ dataShow.away_result_all }}</div>
                  <div>{{ dataShow.away_asian_all }}</div>
                  <div>{{ dataShow.away_over_all }}</div>
                </div>
                <div>
                  <div>{{ dataShow.away_result_home }}</div>
                  <div>{{ dataShow.away_asian_home }}</div>
                  <div>{{ dataShow.away_over_home }}</div>
                </div>
                <div>
                  <div>{{ dataShow.away_result_away }}</div>
                  <div>{{ dataShow.away_asian_away }}</div>
                  <div>{{ dataShow.away_over_away }}</div>
                </div>
              </div>
            </div>
            <div class="blue title" id="meet" ref="meet">交锋战绩</div>
            <div class="boxHeight">
              <div class="btnMatch">
                <el-radio-group v-model="games.bout" size="small" @change="changeNum">
                  <el-radio-button label="1">近10场</el-radio-button>
                  <el-radio-button label="2">近20场</el-radio-button>
                </el-radio-group>
                <el-checkbox v-model="radioBtn.same" @change="handleCheckedCitiesChange(1)">主客相同</el-checkbox>
                <el-checkbox-group v-model="sessionChoose.same" :min="1" @change="handleCheckedCitiesChange(1)">
                  <el-checkbox v-for="item in session" :label="item.e" :key="item.e">{{ item.c }}</el-checkbox>
                </el-checkbox-group>
              </div>
              <el-table :data="showMeeting" stripe style="width: 100%" v-loading="meetLoading.same">
                <el-table-column prop="competition_time" label="日期"></el-table-column>
                <el-table-column prop="chinese_mini_home" label="主队">
                  <template slot-scope="scope">
                    <span @click="jumpTeam('WNBA',scope.row.home)">{{ scope.row.chinese_mini_home }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="point" label="比分" width="90">
                  <template slot-scope="scope">
                    <span
                        @click="jumpMatch('WNBA',scope.row.competition_id)">{{ scope.row.home_all }} - {{ scope.row.away_all }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="chinese_mini_away" label="客队">
                  <template slot-scope="scope">
                    <span @click="jumpTeam('WNBA',scope.row.away)">{{ scope.row.chinese_mini_away }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="competition_result" label="胜负">
                  <template slot-scope="scope">
                    <span :style="result_in(scope.row.competition_result)">{{ scope.row.competition_result }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="gap" label="分差"></el-table-column>
                <el-table-column prop="total" label="和数"></el-table-column>
                <el-table-column prop="current_covers_asian.handicap" label="让分"></el-table-column>
                <el-table-column prop="asian_current_result" label="走势">
                  <template slot-scope="scope">
                    <span :style="result_in(scope.row.asian_current_result)">{{ scope.row.asian_current_result }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="current_covers_over.handicap" label="总分"></el-table-column>
                <el-table-column prop="over_current_result" label="走势">
                  <template slot-scope="scope">
                    <span :style="result_in(scope.row.over_current_result)">{{ scope.row.over_current_result }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="blue title" id="match" ref="match">主客战绩</div>
            <div class="boxHeight">
              <div>
                <div class="btnMatch">
                  <el-radio-group v-model="games.home" size="small" @change="changeNum">
                    <el-radio-button label="1">近10场</el-radio-button>
                    <el-radio-button label="2">近20场</el-radio-button>
                  </el-radio-group>
                  <el-checkbox v-model="radioBtn.home" @change="handleCheckedCitiesChange(2)">主场</el-checkbox>
                  <el-checkbox-group v-model="sessionChoose.home" :min="1" @change="handleCheckedCitiesChange(2)">
                    <el-checkbox v-for="item in session" :label="item.e" :key="item.e">{{ item.c }}</el-checkbox>
                  </el-checkbox-group>
                </div>
                <el-table :data="showHome" stripe style="width: 100%" v-loading="meetLoading.home">
                  <el-table-column prop="competition_time" width="100">
                    <template slot="header">
                      <img :src="matchDetail.team_home.logo" class="teamLogo" alt="">
                      <span>{{ matchDetail.chinese_mini_home }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="chinese_mini_home" label="主队">
                    <template slot-scope="scope">
                      <span @click="jumpTeam('WNBA',scope.row.home)">{{ scope.row.chinese_mini_home }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="point" label="比分" width="90">
                    <template slot-scope="scope">
                      <span
                          @click="jumpMatch('WNBA',scope.row.competition_id)">{{ scope.row.home_all }} - {{ scope.row.away_all }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="chinese_mini_away" label="客队">
                    <template slot-scope="scope">
                      <span @click="jumpTeam('WNBA',scope.row.away)">{{ scope.row.chinese_mini_away }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="competition_result" label="胜负" width="76">
                    <template slot-scope="scope">
                      <span :style="result_in(scope.row.competition_result)">{{ scope.row.competition_result }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="gap" label="分差" width="76"></el-table-column>
                  <el-table-column prop="total" label="和数" width="76"></el-table-column>
                  <el-table-column prop="current_covers_asian.handicap" label="让分" width="76"></el-table-column>
                  <el-table-column prop="asian_current_result" label="走势" width="76">
                    <template slot-scope="scope">
                      <span
                          :style="result_in(scope.row.asian_current_result)">{{ scope.row.asian_current_result }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="current_covers_over.handicap" label="总分" width="76"></el-table-column>
                  <el-table-column prop="over_current_result" label="走势" width="76">
                    <template slot-scope="scope">
                      <span :style="result_in(scope.row.over_current_result)">{{ scope.row.over_current_result }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div style="height: 12px"></div>
              <div>
                <div class="btnMatch">
                  <el-radio-group v-model="games.away" size="small" @change="changeNum">
                    <el-radio-button label="1">近10场</el-radio-button>
                    <el-radio-button label="2">近20场</el-radio-button>
                  </el-radio-group>
                  <el-checkbox v-model="radioBtn.away" @change="handleCheckedCitiesChange(3)">客场</el-checkbox>
                  <el-checkbox-group v-model="sessionChoose.away" :min="1" @change="handleCheckedCitiesChange(3)">
                    <el-checkbox v-for="item in session" :label="item.e" :key="item.e">{{ item.c }}</el-checkbox>
                  </el-checkbox-group>
                </div>
                <el-table :data="showAway" stripe style="width: 100%" v-loading="meetLoading.away">
                  <el-table-column prop="competition_time" width="100">
                    <template slot="header">
                      <img :src="matchDetail.team_away.logo" class="teamLogo" alt="">
                      <span>{{ matchDetail.chinese_mini_away }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="chinese_mini_home" label="主队">
                    <template slot-scope="scope">
                      <span @click="jumpTeam('WNBA',scope.row.home)">{{ scope.row.chinese_mini_home }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="point" label="比分" width="90">
                    <template slot-scope="scope">
                      <span
                          @click="jumpMatch('WNBA',scope.row.competition_id)">{{ scope.row.home_all }} - {{ scope.row.away_all }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="chinese_mini_away" label="客队">
                    <template slot-scope="scope">
                      <span @click="jumpTeam('WNBA',scope.row.away)">{{ scope.row.chinese_mini_away }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="competition_result" label="胜负" width="76">
                    <template slot-scope="scope">
                      <span :style="result_in(scope.row.competition_result)">{{ scope.row.competition_result }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="gap" label="分差" width="76"></el-table-column>
                  <el-table-column prop="total" label="和数" width="76"></el-table-column>
                  <el-table-column prop="current_covers_asian.handicap" label="让分" width="76"></el-table-column>
                  <el-table-column prop="asian_current_result" label="走势" width="76">
                    <template slot-scope="scope">
                      <span
                          :style="result_in(scope.row.asian_current_result)">{{ scope.row.asian_current_result }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="current_covers_over.handicap" label="总分" width="76"></el-table-column>
                  <el-table-column prop="over_current_result" label="走势" width="76">
                    <template slot-scope="scope">
                      <span :style="result_in(scope.row.over_current_result)">{{ scope.row.over_current_result }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="blue title" id="future" ref="future">未来三场</div>
            <div class="boxHeight future headTop">
              <div>
                <div>
                  <div @click="jumpTeam('WNBA',matchDetail.home)" class="canClick">
                    <img :src="matchDetail.team_home.logo" alt="">
                    <span>{{ matchDetail.chinese_mini_home }}</span>
                  </div>
                </div>
                <div>
                  <div>时间</div>
                  <div>主队</div>
                  <div>客队</div>
                  <div>间隔</div>
                </div>
                <div v-for="item in dataShow.next_home_games" :key="item">
                  <div>{{ item.competitionTime }}</div>
                  <div @click="jumpTeam('WNBA',item.home)">{{ item.chinese_mini_home }}</div>
                  <div @click="jumpTeam('WNBA',item.away)">{{ item.chinese_mini_away }}</div>
                  <div>{{ item.interval }}天</div>
                </div>
                <div v-if="!dataShow.next_home_games.length" class="none">暂无未来赛事</div>
              </div>
              <div>
                <div>
                  <div @click="jumpTeam('WNBA',matchDetail.away)" class="canClick">
                    <img :src="matchDetail.team_away.logo" alt="">
                    <span>{{ matchDetail.chinese_mini_away }}</span>
                  </div>
                </div>
                <div>
                  <div>时间</div>
                  <div>主队</div>
                  <div>客队</div>
                  <div>间隔</div>
                </div>
                <div v-for="item in dataShow.next_away_games" :key="item">
                  <div>{{ item.competitionTime }}</div>
                  <div @click="jumpTeam('WNBA',item.home)">{{ item.chinese_mini_home }}</div>
                  <div @click="jumpTeam('WNBA',item.away)">{{ item.chinese_mini_away }}</div>
                  <div>{{ item.interval }}天</div>
                </div>
                <div v-if="!dataShow.next_away_games.length" class="none">暂无未来赛事</div>
              </div>
            </div>
            <div class="blue title" id="rank" ref="rank">攻防排名</div>
            <div class="boxHeight ranking headTop">
              <div>
                <div>
                  <div>
                    <div>
                      <div @click="jumpTeam('WNBA',matchDetail.home)" class="canClick">
                        <img :src="matchDetail.team_home.logo" alt="">
                        <span>{{ matchDetail.chinese_mini_home }}</span>
                      </div>
                    </div>
                    <div>数据</div>
                    <div>排名</div>
                  </div>
                  <div>
                    <div>场均得分</div>
                    <div v-if="dataShow.home_statistic">{{ dataShow.home_statistic.per_score_get }}</div>
                    <div v-if="dataShow.home_statistic">{{ dataShow.home_statistic.per_score_get_rank }}</div>
                  </div>
                  <div>
                    <div>投篮命中率</div>
                    <div v-if="dataShow.home_statistic">{{ dataShow.home_statistic.fgm_get_rate }}</div>
                    <div v-if="dataShow.home_statistic">{{ dataShow.home_statistic.fgm_get_rate_rank }}</div>
                  </div>
                  <div>
                    <div>3分命中率</div>
                    <div v-if="dataShow.home_statistic">{{ dataShow.home_statistic.pm3_get_rate }}</div>
                    <div v-if="dataShow.home_statistic">{{ dataShow.home_statistic.pm3_get_rate_rank }}</div>
                  </div>
                  <div>
                    <div>罚篮命中率</div>
                    <div v-if="dataShow.home_statistic">{{ dataShow.home_statistic.ftm_get_rate }}</div>
                    <div v-if="dataShow.home_statistic">{{ dataShow.home_statistic.ftm_get_rate_rank }}</div>
                  </div>
                  <div>
                    <div>场均篮板</div>
                    <div v-if="dataShow.home_statistic">{{ dataShow.home_statistic.per_tot_get }}</div>
                    <div v-if="dataShow.home_statistic">{{ dataShow.home_statistic.per_tot_get_rank }}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <div @click="jumpTeam('WNBA',matchDetail.home)" class="canClick">
                        <img :src="matchDetail.team_home.logo" alt="">
                        <span>{{ matchDetail.chinese_mini_home }}</span>
                      </div>
                    </div>
                    <div>数据</div>
                    <div>排名</div>
                  </div>
                  <div>
                    <div>场均失分</div>
                    <div v-if="dataShow.home_statistic">{{ dataShow.home_statistic.per_score_lose }}</div>
                    <div v-if="dataShow.home_statistic">{{ dataShow.home_statistic.per_score_lose_rank }}</div>
                  </div>
                  <div>
                    <div>对方投篮命中率</div>
                    <div v-if="dataShow.home_statistic">{{ dataShow.home_statistic.fgm_lose_rate }}</div>
                    <div v-if="dataShow.home_statistic">{{ dataShow.home_statistic.fgm_lose_rate_rank }}</div>
                  </div>
                  <div>
                    <div>对方3分命中率</div>
                    <div v-if="dataShow.home_statistic">{{ dataShow.home_statistic.pm3_lose_rate }}</div>
                    <div v-if="dataShow.home_statistic">{{ dataShow.home_statistic.pm3_lose_rate_rank }}</div>
                  </div>
                  <div>
                    <div>对方罚篮命中率</div>
                    <div v-if="dataShow.home_statistic">{{ dataShow.home_statistic.ftm_lose_rate }}</div>
                    <div v-if="dataShow.home_statistic">{{ dataShow.home_statistic.ftm_lose_rate_rank }}</div>
                  </div>
                  <div>
                    <div>对方场均篮板</div>
                    <div v-if="dataShow.home_statistic">{{ dataShow.home_statistic.per_tot_lose }}</div>
                    <div v-if="dataShow.home_statistic">{{ dataShow.home_statistic.per_tot_lose_rank }}</div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <div>
                      <div @click="jumpTeam('WNBA',matchDetail.away)" class="canClick">
                        <img :src="matchDetail.team_away.logo" alt="">
                        <span>{{ matchDetail.chinese_mini_away }}</span>
                      </div>
                    </div>
                    <div>数据</div>
                    <div>排名</div>
                  </div>
                  <div>
                    <div>场均得分</div>
                    <div v-if="dataShow.away_statistic">{{ dataShow.away_statistic.per_score_get }}</div>
                    <div v-if="dataShow.away_statistic">{{ dataShow.away_statistic.per_score_get_rank }}</div>
                  </div>
                  <div>
                    <div>投篮命中率</div>
                    <div v-if="dataShow.away_statistic">{{ dataShow.away_statistic.fgm_get_rate }}</div>
                    <div v-if="dataShow.away_statistic">{{ dataShow.away_statistic.fgm_get_rate_rank }}</div>
                  </div>
                  <div>
                    <div>3分命中率</div>
                    <div v-if="dataShow.away_statistic">{{ dataShow.away_statistic.pm3_get_rate }}</div>
                    <div v-if="dataShow.away_statistic">{{ dataShow.away_statistic.pm3_get_rate_rank }}</div>
                  </div>
                  <div>
                    <div>罚篮命中率</div>
                    <div v-if="dataShow.away_statistic">{{ dataShow.away_statistic.ftm_get_rate }}</div>
                    <div v-if="dataShow.away_statistic">{{ dataShow.away_statistic.ftm_get_rate_rank }}</div>
                  </div>
                  <div>
                    <div>场均篮板</div>
                    <div v-if="dataShow.away_statistic">{{ dataShow.away_statistic.per_tot_get }}</div>
                    <div v-if="dataShow.away_statistic">{{ dataShow.away_statistic.per_tot_get_rank }}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <div @click="jumpTeam('WNBA',matchDetail.away)" class="canClick">
                        <img :src="matchDetail.team_away.logo" alt="">
                        <span>{{ matchDetail.chinese_mini_away }}</span>
                      </div>
                    </div>
                    <div>数据</div>
                    <div>排名</div>
                  </div>
                  <div>
                    <div>场均失分</div>
                    <div v-if="dataShow.away_statistic">{{ dataShow.away_statistic.per_score_lose }}</div>
                    <div v-if="dataShow.away_statistic">{{ dataShow.away_statistic.per_score_lose_rank }}</div>
                  </div>
                  <div>
                    <div>对方投篮命中率</div>
                    <div v-if="dataShow.away_statistic">{{ dataShow.away_statistic.fgm_lose_rate }}</div>
                    <div v-if="dataShow.away_statistic">{{ dataShow.away_statistic.fgm_lose_rate_rank }}</div>
                  </div>
                  <div>
                    <div>对方3分命中率</div>
                    <div v-if="dataShow.away_statistic">{{ dataShow.away_statistic.pm3_lose_rate }}</div>
                    <div v-if="dataShow.away_statistic">{{ dataShow.away_statistic.pm3_lose_rate_rank }}</div>
                  </div>
                  <div>
                    <div>对方罚篮命中率</div>
                    <div v-if="dataShow.away_statistic">{{ dataShow.away_statistic.ftm_lose_rate }}</div>
                    <div v-if="dataShow.away_statistic">{{ dataShow.away_statistic.ftm_lose_rate_rank }}</div>
                  </div>
                  <div>
                    <div>对方场均篮板</div>
                    <div v-if="dataShow.away_statistic">{{ dataShow.away_statistic.per_tot_lose }}</div>
                    <div v-if="dataShow.away_statistic">{{ dataShow.away_statistic.per_tot_lose_rank }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="blue title" id="referee" ref="referee">裁判统计</div>
            <div class="boxHeight">
              <el-table :data="dataShow.referees" style="width: 100%" v-loading="meetLoading.away"
                        @row-click="jumpToReferee">
                <el-table-column prop="name" label="裁判">
                  <template slot-scope="scope">
                    <span v-if="!scope.row.referee_info.chinese_name">{{ scope.row.referee_info.name }}</span>
                    <span v-if="scope.row.referee_info.chinese_name">{{ scope.row.referee_info.chinese_name }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="position" label="赛果">
                  <template slot-scope="scope">
                    <span>{{ scope.row.win }} - {{ scope.row.lose }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="status" label="让分">
                  <template slot-scope="scope">
                    <span>{{ scope.row.asian_win }} - {{ scope.row.asian_draw }} - {{ scope.row.asian_lose }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="status" label="总分">
                  <template slot-scope="scope">
                    <span>{{ scope.row.over_win }} - {{ scope.row.over_draw }} - {{ scope.row.over_lose }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="points_per_game" label="场均得分"></el-table-column>
                <el-table-column prop="points_per_game_lose" label="场均失分"></el-table-column>
                <el-table-column prop="points" label="净胜分"></el-table-column>
                <el-table-column prop="points_all_per_game" label="场均和数"></el-table-column>
              </el-table>
            </div>
            <div class="blue title" id="injured" ref="injured">球员伤停</div>
            <div class="boxHeight headTop">
              <div>
                <div>
                  <div class="btnMatch">
                    <div @click="jumpTeam('WNBA',matchDetail.home)" class="canClick">
                      <img :src="matchDetail.team_home.logo" alt="">
                      <span>{{ matchDetail.chinese_mini_home }}</span>
                    </div>
                  </div>
                  <el-table :data="dataShow.home_injury" style="width: 100%" v-loading="meetLoading.away"
                            @row-click="jumpToPlayer">
                    <el-table-column prop="player" label="球员" width="120">
                      <template slot-scope="scope">
                        <span v-if="scope.row.players.chinese_name">{{ scope.row.players.chinese_name }}</span>
                        <span v-if="!scope.row.players.chinese_name">{{ scope.row.player }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="position" label="位置" width="60"></el-table-column>
                    <el-table-column prop="chinese_status" label="状态" width="300">
                      <template slot-scope="scope">
                        <span v-if="scope.row.display">{{scope.row.chinese_status}}</span>
                        <span v-if="!scope.row.display">{{scope.row.status}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="chinese_content" label="详情">
                      <template slot-scope="scope">
                        <span v-if="scope.row.display">{{scope.row.chinese_content}}</span>
                        <span v-if="!scope.row.display">{{scope.row.content}}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div style="height: 12px"></div>
                <div>
                  <div class="btnMatch">
                    <div @click="jumpTeam('WNBA',matchDetail.away)" class="canClick">
                      <img :src="matchDetail.team_away.logo" alt="">
                      <span>{{ matchDetail.chinese_mini_away }}</span>
                    </div>
                  </div>
                  <el-table :data="dataShow.away_injury" style="width: 100%" v-loading="meetLoading.away"
                            @row-click="jumpToPlayer">
                    <el-table-column prop="player" label="球员" width="120">
                      <template slot-scope="scope">
                        <span v-if="scope.row.players.chinese_name">{{ scope.row.players.chinese_name }}</span>
                        <span v-if="!scope.row.players.chinese_name">{{ scope.row.player }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="position" label="位置" width="90"></el-table-column>
                    <el-table-column prop="chinese_status" label="状态" width="300">
                      <template slot-scope="scope">
                        <span v-if="scope.row.display">{{scope.row.chinese_status}}</span>
                        <span v-if="!scope.row.display">{{scope.row.status}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="chinese_content" label="详情">
                      <template slot-scope="scope">
                        <span v-if="scope.row.display">{{scope.row.chinese_content}}</span>
                        <span v-if="!scope.row.display">{{scope.row.content}}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="指数" name="index">
            <el-radio-group v-model="radio" size="medium" @change="changeNum" class="btn" v-if="showIndex.length">
              <el-radio-button label="1">让分</el-radio-button>
              <el-radio-button label="2">总分</el-radio-button>
              <el-radio-button label="3">胜负</el-radio-button>
            </el-radio-group>
            <el-table stripe :data="showIndex" style="width: 100%" v-if="showIndex.length"
                      :header-cell-style="{'text-align':'center'}"
                      :cell-style="{'text-align':'center'}">
              <el-table-column prop="company" label="公司"></el-table-column>
              <el-table-column label="初">
                <el-table-column prop="home" :label="showLabel[0]"></el-table-column>
                <el-table-column prop="handicap" :label="showLabel[2]" v-if="showIndex[0].handicap"></el-table-column>
                <el-table-column prop="away" :label="showLabel[1]"></el-table-column>
              </el-table-column>
              <el-table-column label="即">
                <el-table-column prop="current_last.home" :label="showLabel[0]"></el-table-column>
                <el-table-column prop="current_last.handicap" :label=showLabel[2]
                                 v-if="showIndex[0].handicap"></el-table-column>
                <el-table-column prop="current_last.away" :label="showLabel[1]"></el-table-column>
              </el-table-column>
            </el-table>
            <el-empty description="暂无指数数据" v-if="!showIndex.length" image="https://lq.sotips.cn/img/empty.png"
                      :image-size="100" style="background: #FFFFFF"></el-empty>
          </el-tab-pane>
          <el-tab-pane label="交易量" name="product">
            <div class="consensus">
              <div v-if="consensus">
                <div>普通散户交易量</div>
                <div>
                  <div>玩法</div>
                  <div>指数</div>
                  <div>主赢</div>
                  <div>客赢</div>
                </div>
                <div>
                  <div>让分</div>
                  <div>{{ consensus.sides }}</div>
                  <div v-if="!consensus.home_win"></div>
                  <div v-if="!consensus.away_win"></div>
                  <div v-if="consensus.home_win" :style="win_lose(consensus.home_win)">{{consensus.home_win}}%</div>
                  <div v-if="consensus.away_win" :style="win_lose(consensus.away_win)">{{consensus.away_win}}%</div>
                </div>
                <div>
                  <div>玩法</div>
                  <div>指数</div>
                  <div>大分</div>
                  <div>小分</div>
                </div>
                <div>
                  <div>总分</div>
                  <div>{{ consensus.total }}</div>
                  <div v-if="!consensus.over"></div>
                  <div v-if="!consensus.under"></div>
                  <div v-if="consensus.over" :style="win_lose(consensus.over)">{{consensus.over}}%</div>
                  <div v-if="consensus.under" :style="win_lose(consensus.under)">{{consensus.under}}%</div>
                </div>
              </div>
              <img src="https://lq.sotips.cn/img/ad.png" @click="soTips" alt="">
            </div>
          </el-tab-pane>
          <el-tab-pane label="球员" name="player" :disabled="!player.home">
            <el-radio-group v-model="team" size="medium" @change="changeNum" class="btn">
              <el-radio-button label="1">{{ matchDetail.chinese_mini_home }}</el-radio-button>
              <el-radio-button label="2">{{ matchDetail.chinese_mini_away }}</el-radio-button>
            </el-radio-group>
            <el-table stripe :data="showTeam" style="width: 100%" @row-click="jumpToPlayer" v-if="player.home">
              <el-table-column prop="player" label="球员" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.players.chinese_name">{{ scope.row.players.chinese_name }}</span>
                  <span v-if="!scope.row.players.chinese_name">{{ scope.row.player }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="pos" label="位置" width="50"></el-table-column>
              <el-table-column prop="min" label="时间" width="60"></el-table-column>
              <el-table-column prop="fgm-a" label="投篮" width="60"></el-table-column>
              <el-table-column prop="3pm-a" label="3分" width="60"></el-table-column>
              <el-table-column prop="ftm-a" label="罚篮" width="60"></el-table-column>
              <el-table-column prop="pts" label="得分" width="53"></el-table-column>
              <el-table-column prop="off" label="进攻" width="53"></el-table-column>
              <el-table-column prop="def" label="防守" width="53"></el-table-column>
              <el-table-column prop="tot" label="篮板" width="53"></el-table-column>
              <el-table-column prop="a" label="助攻" width="53"></el-table-column>
              <el-table-column prop="pf" label="犯规" width="53"></el-table-column>
              <el-table-column prop="stl" label="抢断" width="53"></el-table-column>
              <el-table-column prop="blk" label="盖帽" width="53"></el-table-column>
              <el-table-column prop="efficiency" label="±值" width="53"></el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="sidebar">
        <div>
          <div>其他信息</div>
          <div>裁判：
            <span v-for="(item,key) in matchDetail.referees" :key="item" @click="jumpReferee('WNBA',item.name)"
                  class="canClick">
              <span v-if="!item.chinese_name">{{ item.name }}</span>
              <span v-if="item.chinese_name">{{ item.chinese_name }}</span>
              <span v-if="key !== matchDetail.referees.length-1"> , </span>
            </span>
          </div>
          <div>观众人数：
            <span>{{ matchDetail.attendance }}</span>
          </div>
          <div>场地：
            <span v-if="matchDetail.chinese_location">{{ matchDetail.chinese_location }}</span>
            <span v-if="!matchDetail.chinese_location">{{ matchDetail.location }}</span>
          </div>
        </div>
        <div>
          <div>今日比赛</div>
          <div v-for="item in todayMatch" :key="item" @click="jumpDetail(item.competition_id)"
               :class="[item.competition_id !== $route.query.id*1? '':'currentTeam']">
            <div>
              <img :src="item.team_home.logo" alt="">
              <div>{{ item.chinese_mini_home }}</div>
            </div>
            <div>
              <div>{{ item.status }}</div>
              <div>{{ item.home_all }} : {{ item.away_all }}</div>
            </div>
            <div>
              <img :src="item.team_away.logo" alt="">
              <div>{{ item.chinese_mini_away }}</div>
            </div>
          </div>
          <div v-if="!todayMatch.length" style="height: 160px">
            <el-empty description="今日暂无赛事" image="https://lq.sotips.cn/img/empty.png" :image-size="100"></el-empty>
          </div>
        </div>
      </div>
    </div>
    <div class="floatBar" v-if="activeName==='data'">
      <a href="#total">
        <div :class="[show===1? 'choose':'']" @click="show = 1">战绩统计</div>
      </a>
      <a href="#meet">
        <div :class="[show===2? 'choose':'']" @click="show = 2">交锋战绩</div>
      </a>
      <a href="#match">
        <div :class="[show===3? 'choose':'']" @click="show = 3">主客战绩</div>
      </a>
      <a href="#future">
        <div :class="[show===4? 'choose':'']" @click="show = 4">未来三场</div>
      </a>
      <a href="#rank">
        <div :class="[show===5? 'choose':'']" @click="show = 5">攻防排名</div>
      </a>
      <a href="#referee">
        <div :class="[show===6? 'choose':'']" @click="show = 6">裁判统计</div>
      </a>
      <a href="#injured">
        <div :class="[show===7? 'choose':'']" @click="show = 7">球员伤停</div>
      </a>
    </div>
    <div style="height: 50px"></div>
    <el-backtop :bottom="150"></el-backtop>
  </div>
</template>

<script>
import {
  detail,
  todayCompetitions,
  getData,
  handicap,
  players,
  sameResult,
  homeResult,
  awayResult,
  consensus
} from '@/api/WNBA/point'

export default {
  name: "team",
  data() {
    return {
      //外层变量
      loading: false,
      todayMatch: [],
      matchDetail: {},
      //数据
      //选项卡
      activeName: 'data',
      //走势统计、未来三场、攻防排名、球员伤停
      dataShow: [],
      //交锋战绩
      meetingTop: {},
      showMeeting: [],
      //主队战绩
      homeTop: {},
      showHome: [],
      //客队战绩
      awayTop: {},
      showAway: [],
      //战绩按钮组
      meetLoading: {
        same: false,
        home: false,
        away: false,
      },
      radioBtn: {
        same: false,
        home: false,
        away: false,
      },
      games: {
        bout: '1',
        home: '1',
        away: '1',
      },
      sessionChoose: {
        same: ['Regular Season', 'Post Season'],
        home: ['Regular Season', 'Post Season'],
        away: ['Regular Season', 'Post Season'],
      },
      session: [{e: 'Regular Season', c: '常规赛'}, {e: 'Post Season', c: '季后赛'}],
      //球队
      team: '1',
      player: [],
      showTeam: [],
      //交易量
      consensus: '',
      //指数
      radio: '1',
      index: {},
      showIndex: [],
      showLabel: ['主', '客', '让分'],
      radioLabel: [
        ['主', '客', '让分'],
        ['大', '小', '总分'],
        ['主', '客', '']
      ],
      //浮动栏
      show: 1,
    }
  },
  methods: {
    //比赛详情跳转
    jumpDetail(id) {
      if (id !== this.$route.query.id * 1) {
        this.$router.push({query: {id: id, type: this.activeName}});
        this.getDetail(id)
      }
    },
    //裁判统计跳转
    jumpToReferee(row) {
      const page = this.$router.resolve({path: '/WNBA/refereeDetail', query: {name: row.name}})
      window.open(page.href, '_blank')
    },
    //伤病球员跳转
    jumpToPlayer(row) {
      const page = this.$router.resolve({path: '/WNBA/playerDetail', query: {id: row.player_id}})
      window.open(page.href, '_blank')
    },
    soTips() {
      window.open('http://vip.sotips.cn/', '_blank')
    },
    //按钮切换
    changeNum() {
      switch (this.radio) {
        case "1":
          this.showIndex = this.index.first_asian;
          this.showLabel = this.radioLabel[0];
          break;
        case "2":
          this.showIndex = this.index.first_over;
          this.showLabel = this.radioLabel[1];
          break;
        case "3":
          this.showIndex = this.index.first_odds;
          this.showLabel = this.radioLabel[2];
          break;
      }
      switch (this.team) {
        case "1":
          this.showTeam = this.player.home.player;
          break;
        case "2":
          this.showTeam = this.player.away.player;
          break;
      }
      switch (this.games.bout) {
        case "1":
          this.showMeeting = this.meetingTop.ten;
          break;
        case "2":
          this.showMeeting = this.meetingTop.twenty;
          break;
      }
      switch (this.games.home) {
        case "1":
          this.showHome = this.homeTop.ten;
          break;
        case "2":
          this.showHome = this.homeTop.twenty;
          break;
      }
      switch (this.games.away) {
        case "1":
          this.showAway = this.awayTop.ten;
          break;
        case "2":
          this.showAway = this.awayTop.twenty;
          break;
      }
    },
    //展示切换
    handleClick() {
      if (this.activeName !== this.$route.query.type) {
        this.$router.push({query: {id: this.$route.query.id, type: this.activeName}});
      }
    },
    //勾选请求数据
    handleCheckedCitiesChange(type) {
      switch (type) {
        case 1:
          this.getMeeting();
          break;
        case 2:
          this.getHomeMatch();
          break;
        case 3:
          this.getAwayMatch();
          break;
      }
    },
    //获取比赛详情
    getDetail(id) {
      this.loading = true
      detail(id).then(res => {
        this.loading = false
        this.matchDetail = res.data.data[0]
        this.matchDetail.competitionTime = this.matchDetail.competition_time.substring(5, this.matchDetail.competition_time.length - 3)
        this.matchDetail.status = this.matchDetail.status === 'Final' ? '已完结' : this.matchDetail.status
        this.matchDetail.status = this.matchDetail.status === '' ? '未开赛' : this.matchDetail.status
        this.getData(id)
        this.getIndex(id)
        if (this.matchDetail.status !== '未开赛') {
          this.getPlayer(id)
        }
        this.getConsensus(id)
        //获取今日比赛
        todayCompetitions(this.matchDetail.competition_time).then(res => {
          this.todayMatch = res.data.data
          for (let x in this.todayMatch) {
            this.todayMatch[x].status = this.todayMatch[x].status === 'Final' ? '已完结' : this.todayMatch[x].status
            this.todayMatch[x].status = this.todayMatch[x].status === '' ? '未开赛' : this.todayMatch[x].status
          }
        })
      })
    },
    //获取指数详情
    getIndex(id) {
      handicap(id).then(res => {
        this.index = res.data.data
        this.showIndex = this.index.first_asian
      })
    },
    //获取球员详情
    getPlayer(id) {
      players(id).then(res => {
        this.player = res.data.data
        this.showTeam = this.player.home.player
      })
    },
    //获取交易量
    getConsensus(id) {
      consensus(id).then(res => {
        this.consensus = res.data.data
      })
    },
    //走势统计、未来三场、攻防排名、球员伤停
    getData(id) {
      getData(id).then(res => {
        this.dataShow = res.data.data
        let matchTime = new Date(this.matchDetail.competition_time.replace(/-/g, "/")).getTime()
        for (let x in this.dataShow.next_home_games){
          let time = this.dataShow.next_home_games[x].competition_time.replace(/-/g, "/")
          let futureMatch = new Date(time).getTime()
          this.dataShow.next_home_games[x].interval = Math.round((futureMatch - matchTime)/(24*60*60*1000))
          this.dataShow.next_home_games[x].competitionTime = this.dataShow.next_home_games[x].competition_time.substring(5,this.dataShow.next_home_games[x].competition_time.length-9)
        }
        for (let x in this.dataShow.next_away_games){
          let time = this.dataShow.next_away_games[x].competition_time.replace(/-/g, "/")
          let futureMatch = new Date(time).getTime()
          this.dataShow.next_away_games[x].interval = Math.round((futureMatch - matchTime)/(24*60*60*1000))
          this.dataShow.next_away_games[x].competitionTime = this.dataShow.next_away_games[x].competition_time.substring(5,this.dataShow.next_away_games[x].competition_time.length-9)
        }
      })
      this.getMeeting()
      this.getHomeMatch()
      this.getAwayMatch()
    },
    //交锋战绩
    getMeeting() {
      this.meetLoading.same = true
      sameResult({
        home: this.matchDetail.home,
        away: this.matchDetail.away,
        time: this.matchDetail.competition_time,
        same: this.radioBtn.same,
        category: this.sessionChoose.same
      }).then(res => {
        this.meetLoading.same = false
        for (let x in res.data.data) {
          res.data.data[x].competition_time = res.data.data[x].competition_time.substring(2, res.data.data[x].competition_time.length - 9)
          if(res.data.data[x].status) {
            res.data.data[x].competition_result = this.result(res.data.data[x].competition_result)
            res.data.data[x].gap = res.data.data[x].home_all - res.data.data[x].away_all
            res.data.data[x].total = res.data.data[x].home_all * 1 + res.data.data[x].away_all * 1
            res.data.data[x].asian_current_result = this.result(res.data.data[x].asian_current_result, 'asian')
            res.data.data[x].over_current_result = this.result(res.data.data[x].over_current_result, 'over')
          }
        }
        this.meetingTop.ten = JSON.parse(JSON.stringify(res.data.data))
        this.meetingTop.ten.length = 10
        this.meetingTop.twenty = JSON.parse(JSON.stringify(res.data.data))
        this.meetingTop.twenty.length = 20
        if (this.games.bout === '2') {
          this.showMeeting = this.meetingTop.twenty
        } else {
          this.showMeeting = this.meetingTop.ten
        }
      })
    },
    //主队战绩
    getHomeMatch() {
      this.meetLoading.home = true
      homeResult({
        team: this.matchDetail.home,
        time: this.matchDetail.competition_time,
        home: this.radioBtn.home,
        category: this.sessionChoose.home
      }).then(res => {
        this.meetLoading.home = false
        for (let x in res.data.data) {
          res.data.data[x].competition_time = res.data.data[x].competition_time.substring(2, res.data.data[x].competition_time.length - 9)
          if(res.data.data[x].status) {
            res.data.data[x].competition_result = this.result(res.data.data[x].competition_result)
            res.data.data[x].gap = res.data.data[x].home_all - res.data.data[x].away_all
            res.data.data[x].total = res.data.data[x].home_all * 1 + res.data.data[x].away_all * 1
            res.data.data[x].asian_current_result = this.result(res.data.data[x].asian_current_result, 'asian')
            res.data.data[x].over_current_result = this.result(res.data.data[x].over_current_result, 'over')
          }
        }
        this.homeTop.ten = JSON.parse(JSON.stringify(res.data.data))
        this.homeTop.ten.length = 10
        this.homeTop.twenty = JSON.parse(JSON.stringify(res.data.data))
        this.homeTop.twenty.length = 20
        if (this.games.home === '2') {
          this.showHome = this.homeTop.twenty
        } else {
          this.showHome = this.homeTop.ten
        }
      })
    },
    //客队战绩
    getAwayMatch() {
      this.meetLoading.away = true
      awayResult({
        team: this.matchDetail.away,
        time: this.matchDetail.competition_time,
        away: this.radioBtn.away,
        category: this.sessionChoose.away
      }).then(res => {
        this.meetLoading.away = false
        for (let x in res.data.data) {
          res.data.data[x].competition_time = res.data.data[x].competition_time.substring(2, res.data.data[x].competition_time.length - 9)
          if(res.data.data[x].status) {
            res.data.data[x].point = res.data.data[x].home_all + ' - ' + res.data.data[x].away_all
            res.data.data[x].competition_result = this.result(res.data.data[x].competition_result)
            res.data.data[x].gap = res.data.data[x].home_all - res.data.data[x].away_all
            res.data.data[x].total = res.data.data[x].home_all * 1 + res.data.data[x].away_all * 1
            res.data.data[x].asian_current_result = this.result(res.data.data[x].asian_current_result, 'asian')
            res.data.data[x].over_current_result = this.result(res.data.data[x].over_current_result, 'over')
          }
        }
        this.awayTop.ten = JSON.parse(JSON.stringify(res.data.data))
        this.awayTop.ten.length = 10
        this.awayTop.twenty = JSON.parse(JSON.stringify(res.data.data))
        this.awayTop.twenty.length = 20
        if (this.games.away === '2') {
          this.showAway = this.awayTop.twenty
        } else {
          this.showAway = this.awayTop.ten
        }
      })
    },
    //悬浮栏监听
    handleScrollX() {
      if (this.activeName === 'data') {
        let title = document.getElementsByClassName('blue title')
        let boxHeight = document.getElementsByClassName('boxHeight')
        if (400 >= title[6].getBoundingClientRect().top) {
          if (window.location.href.indexOf('referee') === -1) {
            this.show = 7
          }
        } else if (260 >= title[5].getBoundingClientRect().top && title[5].getBoundingClientRect().top > -boxHeight[5].clientHeight) {
          this.show = 6
        } else if (0 >= title[4].getBoundingClientRect().top && title[4].getBoundingClientRect().top > -boxHeight[4].clientHeight) {
          this.show = 5
        } else if (0 >= title[3].getBoundingClientRect().top && title[3].getBoundingClientRect().top > -boxHeight[3].clientHeight) {
          this.show = 4
        } else if (0 >= title[2].getBoundingClientRect().top && title[2].getBoundingClientRect().top > -boxHeight[2].clientHeight) {
          this.show = 3
        } else if (0 >= title[1].getBoundingClientRect().top && title[1].getBoundingClientRect().top > -boxHeight[1].clientHeight) {
          this.show = 2
        } else if (title[0].getBoundingClientRect().top > -boxHeight[0].clientHeight) {
          this.show = 1
        }
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScrollX, true)
    this.activeName = this.$route.query.type ? this.$route.query.type : this.activeName
    this.getDetail(this.$route.query.id)
  },
  computed: {
    result_in() {
      return (icontent) => {
        if (icontent === "主") {
          return {'color': '#DE3C31'}
        } else if (icontent === "平") {
          return {'color': '#67c23a'}
        } else if (icontent === "客") {
          return {'color': '#1a71c8'}
        } else if (icontent === "赢") {
          return {'color': '#DE3C31'}
        } else if (icontent === "走") {
          return {'color': '#67c23a'}
        } else if (icontent === "输") {
          return {'color': '#1a71c8'}
        } else if (icontent === "大") {
          return {'color': '#DE3C31'}
        } else if (icontent === "小") {
          return {'color': '#1a71c8'}
        } else if (icontent === "胜") {
          return {'color': '#DE3C31'}
        } else if (icontent === "负") {
          return {'color': '#1a71c8'}
        }
      }
    },
    win_lose() {
      return (icontent) => {
        if (icontent >= 60) {
          return {'color': '#DE3C31'}
        } else if (icontent <= 40) {
          return {'color': '#67c23a'}
        }
      }
    }
  }
}
</script>

<style scoped>
.head {
  height: 213px;
  padding: 10px;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 12px;
  background-image: url("../../../../assets/pointBack.png");
}

.head img {
  width: 70px;
  height: 70px;
  margin-top: -10px;
}

.head > :first-child {
  padding: 34px 150px 10px;
  display: flex;
  justify-content: space-around;
}

.teamName {
  width: 200px;
  font-size: 26px;
  margin: 6px 20px 0;
}

.point {
  font-size: 26px;
  margin: 6px 40px 0;
}

.center {
  width: 120px;
  margin-top: -8px;
  font-size: 14px;
}

.center > :nth-child(2) {
  font-size: 22px;
  margin: 4px 0;
}

.pointTable {
  margin-top: 8px;
  display: flex;
  justify-content: space-around;
}

.pointTable > div > div {
  width: 80px;
  padding: 4px 0;
  line-height: 22px;
}

.pointTable > :last-child > div {
  width: 102px;
}

.content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.fontSize > :nth-child(2n) {
  font-size: 14px;
}

.panTotal {
  text-align: center;
  background: #FFFFFF;
}

.panTotal > div {
  display: flex;
  justify-content: space-between;
}

.panTotal > div > div {
  width: 30%;
  padding: 6px;
}

.panTotal > div > :first-child {
  width: 10%;
}

.panTotal > div:nth-child(n+2) > div {
  border: 1px solid #EBEEF5;
}

.panTotal > div:nth-child(2n) {
  background: #FAFAFA;
}

.panTotal > :nth-child(n+2) > :nth-child(n+2) {
  display: flex;
  justify-content: space-between;
}

.panTotal > div > div > div {
  width: 33%;
}

.total {
  width: 892px;
  background: #f1f1f1;
}

.future {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.future > div {
  width: 49%;
  background: #FFFFFF;
  border-bottom: 1px solid #EBEEF5;
}

.future > div > div {
  padding: 12px;
  border-bottom: 1px solid #E9EAEA;
  display: flex;
  justify-content: space-between;
}

.future > div > div > div {
  width: 25%;
}

.future > :first-child > :nth-child(n+3) {
  background: #FBF7E7;
}

.future > :first-child > :nth-child(4) {
  background: #FEFDEE;
}

.future > :last-child > :nth-child(n+3) {
  background: #E8F0FA;
}

.future > :last-child > :nth-child(4) {
  background: #EEF5FF;
}

.future .none {
  justify-content: center !important;
}

.ranking > div {
  display: flex;
  justify-content: space-between;
}

.ranking > :first-child {
  margin-bottom: 12px;
}

.ranking > div > div {
  width: 49%;
  text-align: center;
  background: #FFFFFF;
}

.ranking > div > div > div {
  border-bottom: 1px solid #E9EAEA;
  display: flex;
  justify-content: space-between;
}

.ranking > div > div > div > div {
  width: 33%;
  padding: 12px;
}

.ranking > div > div > :nth-child(2n) {
  background: #FAFAFA;
}

.total .btn {
  padding: 12px;
}

.totalBar {
  font-size: 14px;
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.totalBar > div {
  width: 49%;
  background: #FFFFFF;
}

.totalBar > :first-child > div {
  padding: 12px;
  border-bottom: 1px solid #EBEEF5;
  display: flex;
  justify-content: space-between;
}

.totalBar > div > div > div {
  width: 33%;
}

.totalBar > div > div > div:nth-child(2) {
  text-align: center;
}

.totalBar > div > div > div:last-child {
  text-align: right;
}

.totalBar > :last-child > div {
  display: flex;
  justify-content: space-between;
}

.totalBar > :last-child > :first-child {
  padding: 12px;
  border-bottom: 1px solid #EBEEF5;
}

.totalBar > :last-child > div > div {
  width: 50%;
}

.totalBar > :last-child > div > div > div {
  padding: 12px;
  border-bottom: 1px solid #EBEEF5;
}

.btnMatch {
  height: 50px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border-bottom: 1px solid #EBEEF5;
}

.btnMatch > :nth-child(n+2) {
  margin-left: 36px;
}

.consensus {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  font-size: 14px;
  text-align: center;
  margin: 20px 0 0;
  border-radius: 6px;
  background: #FFFFFF;
  border: 1px solid #EBEEF5;
}

.consensus img {
  width: 46%;
}

.consensus > div {
  width: 48%;
  padding: 0 4px;
  border: 1px solid #EBEEF5;
}

.consensus > :first-child {
  padding-bottom: 6px;
}

.consensus > div > div {
  padding: 6px;
  display: flex;
  justify-content: space-around;
}

.consensus > div > :nth-child(2n) {
  background: #f8f8f8;
}

.consensus > div > :nth-child(2n+3) {
  background: #e7e5e5;
}

>>> .el-tabs__header {
  margin: 0;
  padding: 0 205px;
  background: #FFFFFF;
}

>>> .el-tabs__nav > div {
  padding: 0;
  width: 120px;
  font-size: 15px;
  text-align: center;
}

>>> .el-tabs__content > div {
  background: #F7F7F8;
}

.sidebar {
  width: 291px;
  background: #f1f1f1;
}

.sidebar > div {
  padding: 12px;
  background: #FFFFFF;
}

.sidebar > :first-child {
  margin-bottom: 12px;
}

.sidebar > :first-child > :nth-child(n+2) {
  padding: 6px 0;
  font-size: 14px;
}

.sidebar > :first-child > :first-child {
  padding: 6px 0;
  font-weight: bold;
  margin-bottom: 6px;
  border-bottom: 2px solid #F7F7F8;
}

.sidebar > :first-child span {
  color: #999999;
}

.sidebar img {
  width: 40px;
  height: 40px;
}

.sidebar > :last-child > :first-child {
  padding: 6px 0;
  font-weight: bold;
  margin-bottom: 6px;
  border-bottom: 2px solid #F7F7F8;
}

.sidebar > :last-child > :nth-child(n+2) {
  height: 66px;
  padding: 6px 0;
  font-size: 14px;
  text-align: center;
  border-bottom: 2px solid #F7F7F8;
  display: flex;
  justify-content: space-around;
}

.sidebar > :last-child > :nth-child(n+2) > :nth-child(2) {
  margin-top: 14px;
}

.floatBar {
  top: 220px;
  right: calc(50% + 620px);
  width: 90px;
  height: 300px;
  position: fixed;
  text-align: center;
  background: #FFFFFF;
}

.floatBar div {
  margin: 10px;
  padding: 6px 0;
  font-size: 14px;
}

.choose {
  color: #FFFFFF;
  border-radius: 4px;
  background: #409EFF;
}

.currentTeam {
  background: #ececec;
}

.none {
  color: #aaaaaa;
}

.teamLogo {
  width: 20px;
  height: 20px;
  margin-top: -4px;
  margin-right: 4px;
  vertical-align: middle;
}
</style>