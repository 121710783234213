<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">收风篮球</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/WNBA' }">WNBA</el-breadcrumb-item>
      <el-breadcrumb-item>火线</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="news">
      <div class="teamN">
        <div>
          <div class="blue">东部联盟</div>
          <div v-for="item in bigRank.east" :key="item.id" class="team canClick" @click="addTab(item.name,item.team.chinese_mini_name)" :class="[item.name === team ? 'currentTeam':'']">
            <img :src="item.team.logo" alt="">
            <span>{{ item.team.chinese_mini_name }}</span>
          </div>
        </div>
        <div>
          <div class="blue">西部联盟</div>
          <div v-for="item in bigRank.west" :key="item.id" class="team canClick" @click="addTab(item.name,item.team.chinese_mini_name)" :class="[item.name === team ? 'currentTeam':'']">
            <img :src="item.team.logo" alt="">
            <span>{{ item.team.chinese_mini_name }}</span>
          </div>
        </div>
      </div>
      <div class="new">
        <el-carousel height="297px">
          <el-carousel-item v-for="item in banner" :key="item">
            <div class="shuffling" @click="jumpDetail(item.id)">
              <img :src="item.head_image" alt="">
              <h3>{{ item.chinese_headline }}</h3>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="main">
          <el-tabs v-model="editableTabsValue" type="card" @tab-click="handleClick" @tab-remove="removeTab">
            <el-tab-pane
                v-for="(team, key) in editableTabs"
                :key="key"
                :label="team.title"
                :name="team.name"
                v-loading="loading"
                :closable="key!==0">
              <div class="headline">
                <div v-for="item in team.content.news" :key="item" @click="jumpDetail(item.id)">
                  <img :src="item.team_info.logo" v-if="item.team_info" alt=""/>
                  <img src="https://lq.sotips.cn/img/wnba.png" v-if="!item.team_info" alt="">
                  <span>{{item.chinese_headline}}</span>
                </div>
              </div>
              <div v-if="!team.content.news.length" class="none">暂无消息</div>
              <div v-if="team.content.total > 16" class="pageChange">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="team.content.page"
                    :page-size="16"
                    background
                    layout="total, prev, pager, next, jumper"
                    :total="team.content.total">
                </el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import {bigRank} from "@/api/WNBA/team";
import {banner,index} from "@/api/WNBA/news"

export default {
  name: "team",
  data(){
    return{
      team: '',
      banner: [],
      bigRank: {},
      loading: false,
      editableTabsValue: '1',
      editableTabs: [{
        page: 1,
        title: '全部',
        name: '1',
        content: ''
      }],
      tabIndex: 1
    }
  },
  methods:{
    handleCurrentChange(val) {
      this.editableTabs[this.editableTabsValue*1-1].page = val
      this.getIndex()
    },
    jumpDetail(id){
      const page = this.$router.resolve({path: '/WNBA/newsDetail', query: {id: id}})
      window.open(page.href,'_blank')
    },
    getIndex(){
      this.loading = true
      index({
        team: this.team,
        page: this.editableTabs[this.editableTabsValue*1-1].page
      }).then(res => {
        this.loading = false
        this.editableTabs[this.editableTabsValue*1-1].content = res.data.data
      })
    },
    //添加标签
    addTab(name,cname) {
      //查重
      let flag = true
      for (let x in this.editableTabs){
        if (this.editableTabs[x].title === cname){
          flag = false
          this.editableTabsValue = this.editableTabs[x].name
        }
      }
      if (flag){
        this.team = name
        index({
          team: this.team,
          page: 1
        }).then(res => {
          let newTabName = ++this.tabIndex + '';
          this.editableTabs.push({
            title: cname,
            name: newTabName,
            content: res.data.data,
            page: 1
          });
          this.editableTabsValue = newTabName;
        })
      }
    },
    //选择标签
    handleClick(tab) {
      if (tab.label === '全部'){
        this.team = ''
      } else {
        for (let x in this.bigRank.east){
          if (this.bigRank.east[x].team.chinese_mini_name===tab.label){
            this.team = this.bigRank.east[x].name
          }
        }
        for (let x in this.bigRank.west){
          if (this.bigRank.west[x].team.chinese_mini_name===tab.label){
            this.team = this.bigRank.west[x].name
          }
        }
        this.getIndex()
      }
    },
    //去除标签
    removeTab(targetName) {
      if (targetName !== '1'){
        let tabs = this.editableTabs;
        let activeName = this.editableTabsValue;
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }
        this.editableTabsValue = activeName;
        this.editableTabs = tabs.filter(tab => tab.name !== targetName);
      }
    }
  },
  mounted() {
    bigRank().then(res => {
      this.bigRank = res.data.data
    })
    banner().then(res => {
      this.banner = res.data.data
    })
    this.getIndex()
  }
}
</script>

<style scoped>
  .news{
    display: flex;
    justify-content: space-between;
  }
  .teamN {
    width: 292px;
    display: flex;
    justify-content: space-between;
  }

  .teamN > div {
    width: 50%;
    text-align: center;
  }

  .teamN > div > div {
    height: 50px;
    line-height: 50px;
    background: #FFFFFF;
    border-bottom: 1px solid #EBEEF5;
  }

  .teamN > div > div:nth-child(2n) {
    background: #FAFAFA;
  }

  .teamN img {
    width: 36px;
    height: 36px;
    margin: 8px 20px 0;
    vertical-align: middle;
  }

  .teamN .team {
    display: flex;
    font-size: 14px;
  }

  .currentTeam{
    background: #ececec !important;
  }

  .new{
    width: 892px
  }

  .headline{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .headline > div{
    width: calc(50% - 24px);
    font-size: 14px;
    padding: 10px 12px;
    background: #FFFFFF;
    border-bottom: 1px solid #EBEEF5;
  }
  .headline img{
    width: 40px;
    margin-top: -4px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .main{
    padding: 12px;
    background: #FFFFFF;
  }
  .main .none{
    font-size: 14px;
    text-align: center;
  }
  .pageChange{
    width: 100%;
    padding: 8px 0;
    margin-top: 12px;
    text-align: center;
    background: #FFFFFF;
  }
  .shuffling{
    height: 100%;
    color: #FFFFFF;
    position: relative;
    background: #6993ff;
  }
  .shuffling img{
    width: 100%;
  }
  .shuffling h3{
    left: 20px;
    bottom: 24px;
    position: absolute;
    text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
  }
</style>