import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);
axios.defaults.withCredentials = true;
Vue.prototype.$ajax = axios;
Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'testing'){
  Vue.prototype.webUrl = 'http://api.covers.sotips.cn/'            // 测试开发
} else if (process.env.NODE_ENV === 'production'){
  Vue.prototype.webUrl = 'http://lq.sotips.cn/'                   // 正式开发
} else {
  Vue.prototype.webUrl = 'http://api.covers.sotips.cn/'            // 本地开发
}

Vue.prototype.result = function (x , type){
  x =x * 1
  if (type === 'over'){
    if (x === 3){
      return '大'
    } else if (x === 1){
      return '走'
    } else if (x === 0){
      return '小'
    }
  } else if (type === 'asian'){
    if (x === 3){
      return '赢'
    } else if (x === 1){
      return '走'
    } else if (x === 0){
      return '输'
    }
  } else {
    if (x === 3){
      return '胜'
    } else if (x === 1){
      return '平'
    } else if (x === 0){
      return '负'
    }
  }
}

Vue.prototype.resultN = function (x , type){
  x =x * 1
  if (type === 'over'){
    if (x === 1){
      return '大'
    } else if (x === 2){
      return '走'
    } else if (x === 3){
      return '小'
    }
  } else if (type === 'asian'){
    if (x === 1){
      return '赢'
    } else if (x === 2){
      return '走'
    } else if (x === 3){
      return '输'
    }
  } else {
    if (x === 1){
      return '胜'
    } else if (x === 2){
      return '平'
    } else if (x === 3){
      return '负'
    }
  }
}

Vue.prototype.season = function (x){
  if (x === 'NBA Atlantic'){
    return '大西洋赛区'
  } else if (x === 'NBA Central'){
    return '中央赛区'
  } else if (x === 'NBA Southeast'){
    return '东南赛区'
  } else if (x === 'NBA Northwest'){
    return '西北赛区'
  } else if (x === 'NBA Pacific'){
    return '太平洋赛区'
  } else if (x === 'NBA Southwest'){
    return '西南赛区'
  }
}

Vue.prototype.jumpTeam = function (race,team){
  const page = this.$router.resolve({path: `/${race}/teamDetail`, query: {team: team}})
  window.open(page.href,'_blank')
}
Vue.prototype.jumpMatch = function (race,match){
  const page = this.$router.resolve({path: `/${race}/pointDetail`, query: {id: match}})
  window.open(page.href,'_blank')
}
Vue.prototype.jumpPlayer = function (race,player){
  const page = this.$router.resolve({path: `/${race}/playerDetail`, query: {id: player}})
  window.open(page.href,'_blank')
}
Vue.prototype.jumpReferee = function (race,referee){
  const page = this.$router.resolve({path: `/${race}/refereeDetail`, query: {name: referee}})
  window.open(page.href,'_blank')
}

let _hmt = _hmt || [];
window._hmt = _hmt; // 修改为window 全局变量
(function () {
  let hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?96da469aed54e46ab1b8fb76480d4a81";
  let s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
