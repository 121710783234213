let URL

if (process.env.NODE_ENV === 'testing'){
    URL = 'https://test.api.covers.sotips.cn'       // 测试开发
} else if (process.env.NODE_ENV === 'production'){
    URL = 'https://api.covers.sotips.cn'            // 正式开发
}else {
    URL = ''                                        // 本地开发
}

export default URL;
