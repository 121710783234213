<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">收风篮球</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/NBA' }">NBA</el-breadcrumb-item>
      <el-breadcrumb-item>球员详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="head">
      <div>
        <div class="headImg">
          <img :src="detail.info.avatar" class="logo" alt="">
        </div>
        <div>
          <h2 v-if="detail.info.chinese_name">{{ detail.info.chinese_name }}</h2>
          <h2 v-if="!detail.info.chinese_name">{{ detail.info.name }}</h2>
          <div>
            <span>NO.{{ detail.info.num }}</span>
            <span> | {{ detail.info.position }}</span>
            <span @click="jumpTeam('NBA',detail.team.name)"> | {{
                detail.team.chinese_name
              }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="main">
        <div class="blue title">信息</div>
        <div class="information">
          <div>
            <div>基本信息</div>
            <div>
              <div>身⾼(米)</div>
              <div>{{ detail.info.height }}</div>
            </div>
            <div>
              <div>体重(公斤)</div>
              <div>{{ detail.info.weight }}</div>
            </div>
            <div>
              <div>出⽣⽇期</div>
              <div>{{ detail.info.birthdate }}</div>
            </div>
            <div>
              <div>出⽣地</div>
              <div v-if="detail.info.chinese_birthplace">{{ detail.info.chinese_birthplace }}</div>
              <div v-if="!detail.info.chinese_birthplace">{{ detail.info.birthplace }}</div>
            </div>
            <div>
              <div>选秀</div>
              <div v-if="detail.info.chinese_draft">{{ detail.info.chinese_draft }}</div>
              <div v-if="!detail.info.chinese_draft">{{ detail.info.draft }}</div>
            </div>
          </div>
          <div>
            <el-radio-group v-model="radio" size="mini" @change="changeNum" class="btn">
              <el-radio-button label="1">常规赛表现</el-radio-button>
              <el-radio-button label="2">季后赛表现</el-radio-button>
            </el-radio-group>
            <div>
              <div>场次</div>
              <div v-if="radio==='1'">{{ detail.regular_now.games_played }}</div>
              <div v-if="radio==='2'">{{ detail.post_now.games_played }}</div>
            </div>
            <div>
              <div>场均得分</div>
              <div v-if="radio==='1'">{{ detail.regular_now.points_per_game }}</div>
              <div v-if="radio==='2'">{{ detail.post_now.points_per_game }}</div>
            </div>
            <div>
              <div>场均篮板</div>
              <div v-if="radio==='1'">{{ detail.regular_now.rebounds_per_game }}</div>
              <div v-if="radio==='2'">{{ detail.post_now.rebounds_per_game }}</div>
            </div>
            <div>
              <div>场均助攻</div>
              <div v-if="radio==='1'">{{ detail.regular_now.assists_per_game }}</div>
              <div v-if="radio==='2'">{{ detail.post_now.assists_per_game }}</div>
            </div>
            <div>
              <div>场均正负值</div>
              <div v-if="radio==='1'">{{ detail.regular_now.efficiency_per_game }}</div>
              <div v-if="radio==='2'">{{ detail.post_now.efficiency_per_game }}</div>
            </div>
          </div>
        </div>
        <div class="blue title">伤停</div>
        <el-table :data="detail.injury" style="width: 100%">
          <el-table-column prop="chinese_status" label="状态" width="300">
            <template slot-scope="scope">
              <span v-if="scope.row.display">{{scope.row.chinese_status}}</span>
              <span v-if="!scope.row.display">{{scope.row.status}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="chinese_content" label="详情">
            <template slot-scope="scope">
              <span v-if="scope.row.display">{{scope.row.chinese_content}}</span>
              <span v-if="!scope.row.display">{{scope.row.content}}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="blue title">战绩</div>
        <el-radio-group v-model="num" size="small" @change="changeNum" class="btn">
          <el-radio-button label="1">近10场</el-radio-button>
          <el-radio-button label="2">本赛季</el-radio-button>
        </el-radio-group>
        <el-table :data="showMatch" stripe style="width: 100%">
          <el-table-column prop="competition.competition_time" label="日期"></el-table-column>
          <el-table-column prop="competition.chinese_mini_home" label="主队">
            <template slot-scope="scope">
              <span @click="jumpTeam('NBA',scope.row.competition.home)">{{
                  scope.row.competition.chinese_mini_home
                }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="point" label="比分">
            <template slot-scope="scope">
            <span
                @click="jumpMatch('NBA',scope.row.competition_id)">{{
                scope.row.competition.home_all
              }} - {{ scope.row.competition.away_all }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="competition.chinese_mini_away" label="客队">
            <template slot-scope="scope">
              <span @click="jumpTeam('NBA',scope.row.competition.away)">{{
                  scope.row.competition.chinese_mini_away
                }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="competition.competition_result" label="胜负" width="50">
            <template slot-scope="scope">
            <span
                :style="result_in(scope.row.competition.competition_result)">{{
                scope.row.competition.competition_result
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="competition.asian_current_result" label="让分" width="50">
            <template slot-scope="scope">
            <span
                :style="result_in(scope.row.competition.asian_current_result)">{{
                scope.row.competition.asian_current_result
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="competition.over_current_result" label="总分" width="50">
            <template slot-scope="scope">
            <span
                :style="result_in(scope.row.competition.over_current_result)">{{
                scope.row.competition.over_current_result
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="min" label="时间" width="60"></el-table-column>
          <el-table-column prop="pts" label="得分" width="50"></el-table-column>
          <el-table-column prop="a" label="助攻" width="50"></el-table-column>
          <el-table-column prop="tot" label="篮板" width="50"></el-table-column>
          <el-table-column prop="blk" label="盖帽" width="50"></el-table-column>
          <el-table-column prop="to" label="失误" width="50"></el-table-column>
          <el-table-column prop="stl" label="抢断" width="50"></el-table-column>
          <el-table-column prop="efficiency" label="±值" width="50"></el-table-column>
        </el-table>
        <div class="blue title">生涯统计</div>
        <el-radio-group v-model="season" size="small" @change="changeNum" class="btn">
          <el-radio-button label="1">常规赛</el-radio-button>
          <el-radio-button label="2">季后赛</el-radio-button>
        </el-radio-group>
        <el-table :data="showSeason" stripe style="width: 100%" @row-click="jumpToTeam">
          <el-table-column prop="season" label="赛季"></el-table-column>
          <el-table-column prop="team_info.chinese_mini_name" label="球队"></el-table-column>
          <el-table-column prop="games_played" label="出场" width="60"></el-table-column>
          <el-table-column prop="minutes_per_game" label="时间" width="60"></el-table-column>
          <el-table-column prop="points_per_game" label="得分" width="60"></el-table-column>
          <el-table-column prop="field_goal_percentage" label="命中率" width="70">
            <template slot-scope="scope">
              <span>{{ scope.row.field_goal_percentage }}%</span>
            </template>
          </el-table-column>
          <el-table-column prop="three_points_made" label="3分" width="60">
            <template slot-scope="scope">
            <span
                v-if="scope.row.games_played">{{
                (scope.row.three_points_made / scope.row.games_played).toFixed(1)
              }}</span>
              <span v-if="!scope.row.games_played">0</span>
            </template>
          </el-table-column>
          <el-table-column prop="three_point_field_goal_percentage" label="3分命中率" width="90">
            <template slot-scope="scope">
              <span>{{ scope.row.three_point_field_goal_percentage }}%</span>
            </template>
          </el-table-column>
          <el-table-column prop="rebounds_per_game" label="篮板" width="60"></el-table-column>
          <el-table-column prop="assists_per_game" label="助攻" width="60"></el-table-column>
          <el-table-column prop="steals_per_game" label="抢断" width="60"></el-table-column>
          <el-table-column prop="blocks_per_game" label="盖帽" width="60"></el-table-column>
          <el-table-column prop="efficiency_per_game" label="±值" width="60"></el-table-column>
        </el-table>
        <div class="blue title" v-if="playerNews.length">火线情报</div>
        <div>
          <div class="headline">
            <div v-for="item in playerNews" :key="item" @click="jumpToNew(item.id)">
              <img :src="item.team_info.logo" v-if="item.team_info" alt=""/>
              <img src="https://lq.sotips.cn/img/nba.png" v-if="!item.team_info" alt="">
              <span>{{ item.chinese_headline }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="player">
        <div style="width: 100%" @click="jumpTeam('NBA',detail.team.name)">
          <img :src="detail.team.logo" alt="">
          <div>{{detail.team.chinese_name}}</div>
        </div>
        <div v-for="item in players" :key="item" @click="rush(item.player_id)">
          <img :src="item.avatar" alt="">
          <div v-if="item.chinese_name">{{ item.chinese_name }}</div>
          <div v-if="!item.chinese_name">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import {index,news,detail} from "@/api/NBA/player"

export default {
  name: "team",
  data() {
    return {
      num: '1',
      radio: '1',
      season: '1',
      detail: {},
      players: [],
      showMatch: [],
      playerNews: [],
      showSeason: [],
    }
  },
  methods: {
    changeNum() {
      if (this.num === '1') {
        this.showMatch = this.detail.last_ten
      } else {
        this.showMatch = this.detail.competitions
      }
      if (this.season === '1') {
        this.showSeason = this.detail.regular
      } else {
        this.showSeason = this.detail.post
      }
    },
    jumpToTeam(row) {
      const page = this.$router.resolve({path: '/NBA/teamDetail', query: {team: row.team}})
      window.open(page.href, '_blank')
    },
    //球员切换
    rush(id) {
      if (id*1 !== this.$route.query.id*1) {
        const page = this.$router.resolve({path: '/NBA/playerDetail', query: {id: id}})
        window.open(page.href, '_blank')
      }
    },
    //新闻跳转
    jumpToNew(id){
      const page = this.$router.resolve({path: '/NBA/newsDetail', query: {id: id}})
      window.open(page.href,'_blank')
    },
  },
  mounted() {
    detail(this.$route.query.id).then(res => {
      this.detail = res.data.data
      for (let x in this.detail.last_ten) {
        this.detail.last_ten[x].competition.competition_time = this.detail.last_ten[x].competition.competition_time.substring(2, this.detail.last_ten[x].competition.competition_time.length - 9)
        this.detail.last_ten[x].competition.competition_result = this.result(this.detail.last_ten[x].competition.competition_result)
        this.detail.last_ten[x].competition.asian_current_result = this.result(this.detail.last_ten[x].competition.asian_current_result, 'asian')
        this.detail.last_ten[x].competition.over_current_result = this.result(this.detail.last_ten[x].competition.over_current_result, 'over')
      }
      for (let x in this.detail.competitions) {
        this.detail.competitions[x].competition.competition_time = this.detail.competitions[x].competition.competition_time.substring(2, this.detail.competitions[x].competition.competition_time.length - 9)
        this.detail.competitions[x].competition.competition_result = this.result(this.detail.competitions[x].competition.competition_result)
        this.detail.competitions[x].competition.asian_current_result = this.result(this.detail.competitions[x].competition.asian_current_result, 'asian')
        this.detail.competitions[x].competition.over_current_result = this.result(this.detail.competitions[x].competition.over_current_result, 'over')
      }
      this.showMatch = this.detail.last_ten
      this.showSeason = this.detail.regular
      index(this.detail.team.name).then(res => {
        this.players = res.data.data
      })
      news(this.$route.query.id).then(res => {
        this.playerNews = res.data.data
      })
    })
  },
  computed: {
    result_in() {
      return (icontent) => {
        if (icontent === "主") {
          return {'color': '#DE3C31'}
        } else if (icontent === "平") {
          return {'color': '#67c23a'}
        } else if (icontent === "客") {
          return {'color': '#1a71c8'}
        } else if (icontent === "赢") {
          return {'color': '#DE3C31'}
        } else if (icontent === "走") {
          return {'color': '#67c23a'}
        } else if (icontent === "输") {
          return {'color': '#1a71c8'}
        } else if (icontent === "大") {
          return {'color': '#DE3C31'}
        } else if (icontent === "小") {
          return {'color': '#1a71c8'}
        } else if (icontent === "胜") {
          return {'color': '#DE3C31'}
        } else if (icontent === "负") {
          return {'color': '#1a71c8'}
        }
      }
    }
  }
}
</script>

<style scoped>
.head {
  height: 84px;
  padding: 30px;
  color: #FFFFFF;
  background-image: url("../../../../assets/teamBack.png");
  display: flex;
  justify-content: space-between;
}

.head > :first-child {
  display: flex;
  margin-top: 4px;
}

.headImg {
  width: 60px;
  height: 90px;
  margin-top: -14px;
  margin-right: 18px;
  overflow: hidden;
  background: white;
  padding: 5px 20px;
  border-radius: 50%;
}

.head .logo {
  width: 60px;
  height: 90px;
}

.head h2 {
  padding: 4px 0 10px;
}

.content {
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.main {
  width: 892px;
  background: #F7F7F8;
}

.information {
  display: flex;
  justify-content: space-between;
}

.information > div > div {
  width: 446px;
  height: 35px;
  font-size: 14px;
  line-height: 35px;
  text-align: left;
  background: #FFFFFF;
  border-bottom: 1px solid #EBEEF5;
}

.information > div > :first-child {
  width: 386px;
  padding: 0 30px;
}

.information > div > :nth-child(n+2) {
  display: flex;
  justify-content: space-around;
}

.information > div > :nth-child(n+2) > :first-child {
  width: 22%;
}

.information > div > :nth-child(n+2) > :last-child {
  width: 78%;
}

.information > div > div > div {
  width: 50%;
  padding: 0 30px;
}

.information > div > div:nth-child(2n) {
  background: #FAFAFA;
}

.player {
  width: 292px;
  display: flex;
  flex-wrap: wrap;
}

.player img {
  width: 30px;
  margin: 8px 20px 0;
  vertical-align: middle;
}

.player > div {
  width: 50%;
  line-height: 30px;
  text-align: center;
  background: #FFFFFF;
  border-bottom: 1px solid #EBEEF5;
}

.player > div:nth-child(4n-2) {
  background: #FAFAFA;
}
.player > div:nth-child(4n-1) {
  background: #FAFAFA;
}
.headline {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.headline > div {
  width: calc(50% - 24px);
  font-size: 14px;
  padding: 10px 12px;
  background: #FFFFFF;
  border-bottom: 1px solid #EBEEF5;
}

.headline img {
  width: 40px;
  margin-top: -4px;
  margin-right: 10px;
  vertical-align: middle;
}
</style>