<template>
  <div v-if="teamT" v-loading.fullscreen.lock="fullscreenLoading">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">收风篮球</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/NBA' }">NBA</el-breadcrumb-item>
      <el-breadcrumb-item>球队详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="head">
      <div>
        <img :src="teamT.team.team.logo" class="logo" alt="">
        <div>
          <h2>{{ teamT.team.team.chinese_name }}</h2>
          <div>
            {{ teamT.team.zone }}&nbsp;第{{ teamT.team.zone_rank }}&nbsp;{{ teamT.team.win }}胜{{ teamT.team.lose }}负
          </div>
        </div>
      </div>
      <div>
        <div>
          <div v-if="teamT.team.team.division">分区冠军指数</div>
          <el-divider direction="vertical" v-if="teamT.team.team.division"></el-divider>
          <div v-if="teamT.team.team.conference">联盟冠军指数</div>
          <el-divider direction="vertical" v-if="teamT.team.team.conference"></el-divider>
          <div v-if="teamT.team.team.championship">NBA总冠军指数</div>
        </div>
        <div>
          <div v-if="teamT.team.team.division">{{ teamT.team.team.division }}</div>
          <div v-if="teamT.team.team.division"></div>
          <div v-if="teamT.team.team.conference">{{ teamT.team.team.conference }}</div>
          <div v-if="teamT.team.team.conference"></div>
          <div v-if="teamT.team.team.championship">{{ teamT.team.team.championship }}</div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="teamD">
        <el-tabs v-model="activeName">
          <el-tab-pane label="统计" name="first">
            <div class="blue title">攻防统计</div>
            <div class="statistical">
              <div>
                <div>
                  <div class="blue" style="font-weight: bold">进攻</div>
                  <div>赛季统计</div>
                  <div>排名</div>
                </div>
                <div>
                  <div>场均得分</div>
                  <div>{{ teamT.statistic.per_score_get }}</div>
                  <div>{{ teamT.statistic.per_score_get_rank }}</div>
                </div>
                <div>
                  <div>投篮命中率</div>
                  <div>{{ teamT.statistic.fgm_get_rate }}%</div>
                  <div>{{ teamT.statistic.fgm_get_rate_rank }}</div>
                </div>
                <div>
                  <div>三分命中率</div>
                  <div>{{ teamT.statistic.pm3_get_rate }}%</div>
                  <div>{{ teamT.statistic.pm3_get_rate_rank }}</div>
                </div>
                <div>
                  <div>罚篮命中率</div>
                  <div>{{ teamT.statistic.ftm_get_rate }}%</div>
                  <div>{{ teamT.statistic.ftm_get_rate_rank }}</div>
                </div>
                <div>
                  <div>场均篮板</div>
                  <div>{{ teamT.statistic.per_tot_get }}</div>
                  <div>{{ teamT.statistic.per_tot_get_rank }}</div>
                </div>
              </div>
              <div>
                <div>
                  <div class="blue" style="font-weight: bold">防守</div>
                  <div>赛季统计</div>
                  <div>排名</div>
                </div>
                <div>
                  <div>场均失分</div>
                  <div>{{ teamT.statistic.per_score_lose }}</div>
                  <div>{{ teamT.statistic.per_score_lose_rank }}</div>
                </div>
                <div>
                  <div>对方投篮命中率</div>
                  <div>{{ teamT.statistic.fgm_lose_rate }}%</div>
                  <div>{{ teamT.statistic.fgm_lose_rate_rank }}</div>
                </div>
                <div>
                  <div>对方三分命中率</div>
                  <div>{{ teamT.statistic.pm3_lose_rate }}%</div>
                  <div>{{ teamT.statistic.pm3_lose_rate_rank }}</div>
                </div>
                <div>
                  <div>对方罚篮命中率</div>
                  <div>{{ teamT.statistic.ftm_lose_rate }}%</div>
                  <div>{{ teamT.statistic.ftm_lose_rate_rank }}</div>
                </div>
                <div>
                  <div>对方场均篮板</div>
                  <div>{{ teamT.statistic.per_tot_lose }}</div>
                  <div>{{ teamT.statistic.per_tot_lose_rank }}</div>
                </div>
              </div>
            </div>
            <div class="blue title">比赛战绩</div>
            <div>
              <el-radio-group v-model="radio" size="small" @change="changeNum" class="btn">
                <el-radio-button label="1">近10场</el-radio-button>
                <el-radio-button label="2">本赛季</el-radio-button>
              </el-radio-group>
              <el-table :data="tableData" stripe style="width: 100%">
                <el-table-column prop="competition_time" label="日期"></el-table-column>
                <el-table-column prop="chinese_mini_home" label="主队">
                  <template slot-scope="scope">
                    <span @click="jumpTeam('NBA',scope.row.home)">{{ scope.row.chinese_mini_home }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="point" label="比分" width="90">
                  <template slot-scope="scope">
                    <span
                        @click="jumpMatch('NBA',scope.row.competition_id)">{{
                        scope.row.home_all
                      }} - {{ scope.row.away_all }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="chinese_mini_away" label="客队">
                  <template slot-scope="scope">
                    <span @click="jumpTeam('NBA',scope.row.away)">{{ scope.row.chinese_mini_away }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="competition_result" label="胜负">
                  <template slot-scope="scope">
                    <span :style="result_in(scope.row.competition_result)">{{ scope.row.competition_result }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="gap" label="分差"></el-table-column>
                <el-table-column prop="total" label="和数"></el-table-column>
                <el-table-column prop="current_covers_asian.handicap" label="让分"></el-table-column>
                <el-table-column prop="asian_current_result" label="走势">
                  <template slot-scope="scope">
                    <span :style="result_in(scope.row.asian_current_result)">{{ scope.row.asian_current_result }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="current_covers_over.handicap" label="总分"></el-table-column>
                <el-table-column prop="over_current_result" label="走势">
                  <template slot-scope="scope">
                    <span :style="result_in(scope.row.over_current_result)">{{ scope.row.over_current_result }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="blue title">{{ teamT.team.zone }}排名</div>
            <div class="rank">
              <el-table :data="teamT.rank" style="width: 100%" :row-class-name="tableRowClassName"
                        @row-click="jumpToTeam">
                <el-table-column type="index" label="排名" width="50"></el-table-column>
                <el-table-column prop="team.chinese_name" label="球队" width="100">
                  <template slot-scope="scope">
                    <img :src="scope.row.team.logo" width="40" height="40" class="head_pic" alt=""/>
                    <span>{{ scope.row.team.chinese_mini_name }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="win" label="胜场" width="60"></el-table-column>
                <el-table-column prop="lose" label="负场" width="60"></el-table-column>
                <el-table-column prop="rate" label="胜率" width="70">
                  <template slot-scope="scope">
                    <span>{{ scope.row.rate }}%</span>
                  </template>
                </el-table-column>
                <el-table-column prop="home" label="主场">
                  <template slot-scope="scope">
                    <span>{{ scope.row.home_win + ' - ' + scope.row.home_lose }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="away" label="客场">
                  <template slot-scope="scope">
                    <span>{{ scope.row.away_win + ' - ' + scope.row.away_lose }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="ew_rank" label="联盟">
                  <template slot-scope="scope">
                    <span>{{ scope.row.league_win + ' - ' + scope.row.league_lose }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="zone_rank" label="分区">
                  <template slot-scope="scope">
                    <span>{{ scope.row.zone_win + ' - ' + scope.row.zone_lose }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="asian" label="让分" width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.asian_win + ' - ' + scope.row.asian_draw + ' - ' + scope.row.asian_lose }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="over" label="总分" width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.over_win + ' - ' + scope.row.over_draw + ' - ' + scope.row.over_lose }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="blue title">球员伤停</div>
            <div class="rank">
              <el-table :data="teamT.injury" style="width: 100%" :row-class-name="tableRowClassName">
                <el-table-column prop="player" label="球员" width="120">
                  <template slot-scope="scope">
                    <span v-if="scope.row.players.chinese_name">{{ scope.row.players.chinese_name }}</span>
                    <span v-if="!scope.row.players.chinese_name">{{ scope.row.player }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="position" label="位置" width="50"></el-table-column>
                <el-table-column prop="chinese_status" label="状态" width="300">
                  <template slot-scope="scope">
                    <span v-if="scope.row.display">{{ scope.row.chinese_status }}</span>
                    <span v-if="!scope.row.display">{{ scope.row.status }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="chinese_content" label="详情">
                  <template slot-scope="scope">
                    <span v-if="scope.row.display">{{ scope.row.chinese_content }}</span>
                    <span v-if="!scope.row.display">{{ scope.row.content }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="赛程" name="second">
            <div class="btnBar">
              <el-radio-group v-model="seasonType" size="small" class="btn" @change="rChange">
                <el-radio-button label="Regular Season">常规赛</el-radio-button>
                <el-radio-button label="Post Season">季后赛</el-radio-button>
              </el-radio-group>
              <el-select v-model="seasonValue" placeholder="请选择" size="small" @change="sChange">
                <el-option
                    v-for="item in seasonYear"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </div>
            <div v-for="item in competitions" :key="item.day" v-loading="loading">
              <div class="blue tTitle">{{ item.day }}</div>
              <el-table :data="item.data" stripe style="width: 100%">
                <el-table-column prop="competitionTime" label="日期"></el-table-column>
                <el-table-column prop="chinese_mini_home" label="主队">
                  <template slot-scope="scope">
                    <span @click="jumpTeam('NBA',scope.row.home)">{{ scope.row.chinese_mini_home }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="point" label="比分" width="90">
                  <template slot-scope="scope">
                    <span v-if="scope.row.status !== ''"
                          @click="jumpMatch('NBA',scope.row.competition_id)">{{
                        scope.row.home_all
                      }} - {{ scope.row.away_all }}</span>
                    <span v-if="scope.row.status === ''">{{ scope.row.home_all }} - {{ scope.row.away_all }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="chinese_mini_away" label="客队">
                  <template slot-scope="scope">
                    <span @click="jumpTeam('NBA',scope.row.away)">{{ scope.row.chinese_mini_away }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="competition_result" label="胜负">
                  <template slot-scope="scope">
                    <span :style="result_in(scope.row.competition_result)">{{ scope.row.competition_result }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="gap" label="分差"></el-table-column>
                <el-table-column prop="total" label="和数"></el-table-column>
                <el-table-column prop="current_covers_asian.handicap" label="让分"></el-table-column>
                <el-table-column prop="asian_current_result" label="走势">
                  <template slot-scope="scope">
                    <span :style="result_in(scope.row.asian_current_result)">{{ scope.row.asian_current_result }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="current_covers_over.handicap" label="总分"></el-table-column>
                <el-table-column prop="over_current_result" label="走势">
                  <template slot-scope="scope">
                    <span :style="result_in(scope.row.over_current_result)">{{ scope.row.over_current_result }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-empty description="暂无赛事" image="https://lq.sotips.cn/img/empty.png"
                      v-if="!competitions.length && showEmpty"></el-empty>
          </el-tab-pane>
          <el-tab-pane label="球员" name="third">
            <div class="btnBar" style="margin-bottom: 6px">
              <el-radio-group v-model="seasonType" size="small" class="btn" @change="rChange">
                <el-radio-button label="Regular Season">常规赛</el-radio-button>
                <el-radio-button label="Post Season">季后赛</el-radio-button>
              </el-radio-group>
              <el-select v-model="seasonValue" placeholder="请选择" size="small" @change="sChange">
                <el-option
                    v-for="item in seasonYear"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </div>
            <el-table :data="players" stripe style="width: 100%" v-loading="loading" @row-click="jumpPlayer">
              <el-table-column prop="player" label="球员(场均数据)" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.player_info.chinese_name">{{ scope.row.player_info.chinese_name }}</span>
                  <span v-if="!scope.row.player_info.chinese_name">{{ scope.row.player_info.name }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="player_info.num" label="号码" width="50"></el-table-column>
              <el-table-column prop="player_info.position" label="位置" width="50"></el-table-column>
              <el-table-column prop="games_played" label="场次" width="50"></el-table-column>
              <el-table-column prop="minutes_per_game" label="出场时间"></el-table-column>
              <el-table-column prop="points_per_game" label="得分" width="55"></el-table-column>
              <el-table-column prop="field_goal_percentage" label="命中率" width="70"></el-table-column>
              <el-table-column prop="three_per_game" label="3分" width="50">
                <template slot-scope="scope">
                <span
                    v-if="scope.row.games_played">{{
                    (scope.row.three_points_made / scope.row.games_played).toFixed(1)
                  }}</span>
                  <span v-if="!scope.row.games_played">0</span>
                </template>
              </el-table-column>
              <el-table-column prop="three_point_field_goal_percentage" label="3分%" width="70"></el-table-column>
              <el-table-column prop="rebounds_per_game" label="篮板" width="60"></el-table-column>
              <el-table-column prop="assists_per_game" label="助攻" width="60"></el-table-column>
              <el-table-column prop="blocks_per_game" label="盖帽" width="60"></el-table-column>
              <el-table-column prop="steals_per_game" label="抢断" width="60"></el-table-column>
              <el-table-column prop="efficiency_per_game" label="±值" width="55"></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="火线" name="forth">
            <div class="headline">
              <div v-for="item in teamNews" :key="item" @click="jumpToNew(item.id)">
                <img :src="item.team_info.logo" v-if="item.team_info" alt=""/>
                <img src="https://lq.sotips.cn/img/nba.png" v-if="!item.team_info" alt="">
                <span>{{ item.chinese_headline }}</span>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="teamN">
        <div>
          <div class="blue">东部联盟</div>
          <div v-for="item in bigRank.east" :key="item.id" class="team canClick" @click="rush(item.name)">
            <img :src="item.team.logo" alt="">
            <span>{{ item.team.chinese_mini_name }}</span>
          </div>
        </div>
        <div>
          <div class="blue">西部联盟</div>
          <div v-for="item in bigRank.west" :key="item.id" class="team canClick" @click="rush(item.name)">
            <img :src="item.team.logo" alt="">
            <span>{{ item.team.chinese_mini_name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import {index} from "@/api/NBA/news"
import {teamsDetail, seasons, bigRank, competitions, players} from "@/api/NBA/team";

export default {
  name: "team",
  data() {
    return {
      radio: '1',
      teamT: '',
      players: [],
      bigRank: {},
      teamNews: [],
      playersR: [],
      playersP: [],
      tableData: {},
      seasonYear: [],
      seasonValue: '',
      loading: false,
      showEmpty: false,
      competitions: [],
      competitionsR: [],
      competitionsP: [],
      activeName: 'first',
      fullscreenLoading: false,
      seasonType: 'Regular Season',
    }
  },
  methods: {
    //统计 — 赛程数量切换
    changeNum() {
      if (this.radio === '1') {
        this.tableData = this.teamT.last_ten
      } else {
        this.tableData = this.teamT.now_season
      }
    },
    //排名颜色
    tableRowClassName({row}) {
      if (row.team.chinese_name === this.teamT.team.team.chinese_name) {
        return 'current_team';
      } else {
        if (row.zone_rank === 2) {
          return 'grey_bac';
        }
        if (row.zone_rank === 4) {
          return 'grey_bac';
        }
      }
      return '';
    },
    //球队切换
    rush(name) {
      if (name !== this.$route.query.team) {
        const page = this.$router.resolve({path: '/NBA/teamDetail', query: {team: name}})
        window.open(page.href, '_blank')
      }
    },
    //获取球队统计
    getTeam(name) {
      this.fullscreenLoading = true
      teamsDetail(name).then(res => {
        this.fullscreenLoading = false
        this.teamT = res.data.data
        this.teamT.team.zone = this.season(this.teamT.team.zone)
        for (let x in this.teamT.last_ten) {
          this.teamT.last_ten[x].competition_time = this.teamT.last_ten[x].competition_time.substring(5, this.teamT.last_ten[x].competition_time.length - 9)
          if(this.teamT.last_ten[x].status) {
            this.teamT.last_ten[x].competition_result = this.result(this.teamT.last_ten[x].competition_result)
            this.teamT.last_ten[x].gap = this.teamT.last_ten[x].home_all - this.teamT.last_ten[x].away_all
            this.teamT.last_ten[x].total = this.teamT.last_ten[x].home_all * 1 + this.teamT.last_ten[x].away_all * 1
            this.teamT.last_ten[x].asian_current_result = this.result(this.teamT.last_ten[x].asian_current_result, 'asian')
            this.teamT.last_ten[x].over_current_result = this.result(this.teamT.last_ten[x].over_current_result, 'over')
          }
        }
        for (let x in this.teamT.now_season) {
          this.teamT.now_season[x].competition_time = this.teamT.now_season[x].competition_time.substring(5, this.teamT.now_season[x].competition_time.length - 9)
          if(this.teamT.now_season[x].status){
            this.teamT.now_season[x].competition_result = this.result(this.teamT.now_season[x].competition_result)
            this.teamT.now_season[x].gap = this.teamT.now_season[x].home_all - this.teamT.now_season[x].away_all
            this.teamT.now_season[x].total = this.teamT.now_season[x].home_all * 1 + this.teamT.now_season[x].away_all * 1
            this.teamT.now_season[x].asian_current_result = this.result(this.teamT.now_season[x].asian_current_result, 'asian')
            this.teamT.now_season[x].over_current_result = this.result(this.teamT.now_season[x].over_current_result, 'over')
          }
        }
        this.tableData = this.teamT.last_ten
        this.getSeason()
        this.getTeamNews()
      })
    },
    //获取赛季
    getSeason() {
      seasons().then(res => {
        this.seasonYear = res.data.data
        this.seasonValue = this.seasonValue ? this.seasonValue : this.seasonYear[0]
        this.getCompetitions()
        this.getPlayers()
      })
    },
    //获取赛程
    getCompetitions() {
      this.loading = true
      this.showEmpty = false
      competitions({
        team: this.teamT.team.name,
        season: this.seasonValue,
        category: this.seasonType,
      }).then(res => {
        this.loading = false
        for (let x in res.data.data) {
          res.data.data[x].competitionTime = res.data.data[x].competition_time.substring(5, res.data.data[x].competition_time.length - 9)
          if (res.data.data[x].status === 'Final') {
            res.data.data[x].point = res.data.data[x].home_all + ' - ' + res.data.data[x].away_all
            res.data.data[x].competition_result = this.result(res.data.data[x].competition_result)
            res.data.data[x].gap = res.data.data[x].home_all - res.data.data[x].away_all
            res.data.data[x].total = res.data.data[x].home_all * 1 + res.data.data[x].away_all * 1
            res.data.data[x].asian_current_result = this.result(res.data.data[x].asian_current_result, 'asian')
            res.data.data[x].over_current_result = this.result(res.data.data[x].over_current_result, 'over')
          } else {
            res.data.data[x].point = '——'
            res.data.data[x].competition_result = '——'
            res.data.data[x].gap = '——'
            res.data.data[x].total = '——'
            res.data.data[x].asian_current_result = '——'
            res.data.data[x].over_current_result = '——'
            res.data.data[x].current_covers_asian = {}
            res.data.data[x].current_covers_over = {}
            res.data.data[x].current_covers_asian.handicap = '——'
            res.data.data[x].current_covers_over.handicap = '——'
          }
        }
        let num = 0, competitions = []
        for (let x in res.data.data) {
          let oDate = new Date(res.data.data[x].competition_time.replace(/-/g, "/"))
          if (x === '0') {
            competitions[num] = {}
            competitions[num].day = oDate.getFullYear() + '年' + (oDate.getMonth() + 1) + '月赛程'
            competitions[num].data = []
            competitions[num].data[0] = res.data.data[x]
          } else {
            if (new Date(res.data.data[x - 1].competition_time.replace(/-/g, "/")).getMonth() === oDate.getMonth()) {
              competitions[num].data.push(res.data.data[x])
            } else {
              num++
              competitions[num] = {}
              competitions[num].day = oDate.getFullYear() + '年' + (oDate.getMonth() + 1) + '月赛程'
              competitions[num].data = []
              competitions[num].data[0] = res.data.data[x]
            }
          }
        }
        if (this.seasonType === 'Regular Season') {
          this.competitionsR = competitions
          this.competitions = this.competitionsR
        } else {
          this.competitionsP = competitions
          this.competitions = this.competitionsP
        }
        this.$nextTick(function () {
          this.showEmpty = true
        })
      })
    },
    //获取球队火线
    getTeamNews() {
      index({
        page: 1,
        team: this.teamT.team.name
      }).then(res => {
        this.teamNews = res.data.data.news
      })
    },
    //赛程 — 常规赛季后赛切换
    rChange() {
      // this.competitions = []
      // if (this.activeName === 'second'){
      if (this.seasonType === 'Regular Season') {
        if (!this.competitionsR.length) {
          this.getCompetitions()
        } else {
          if (this.seasonValue !== this.competitionsR[0].data[0].season) {
            this.getCompetitions()
          } else {
            this.competitions = this.competitionsR
          }
        }
      } else {
        if (!this.competitionsP.length) {
          this.getCompetitions()
        } else {
          if (this.seasonValue !== this.competitionsP[0].data[0].season) {
            this.getCompetitions()
          } else {
            this.competitions = this.competitionsP
          }
        }
      }
      // } else if (this.activeName === 'third'){
      if (this.seasonType === 'Regular Season') {
        if (!this.playersR.length) {
          this.getPlayers()
        } else {
          if (this.seasonValue !== this.playersR[0].season) {
            this.getPlayers()
          } else {
            this.players = this.playersR
          }
        }
      } else {
        if (!this.playersP.length) {
          this.getPlayers()
        } else {
          if (this.seasonValue !== this.playersP[0].season) {
            this.getPlayers()
          } else {
            this.players = this.playersP
          }
        }
      }
      // }
    },
    //赛程 - 赛季切换
    sChange() {
      this.getCompetitions()
      this.getPlayers()
    },
    //获取球队球员
    getPlayers() {
      this.loading = true
      this.showEmpty = false
      players({
        team: this.teamT.team.name,
        season: this.seasonValue,
        category: this.seasonType,
      }).then(res => {
        this.loading = false
        for (let x in res.data.data) {
          res.data.data[x].field_goal_percentage += '%'
          res.data.data[x].three_point_field_goal_percentage += '%'
        }
        if (this.seasonType === 'Regular Season') {
          this.playersR = res.data.data
          this.players = this.playersR
        } else {
          this.playersP = res.data.data
          this.players = this.playersP
        }
        this.$nextTick(function () {
          this.showEmpty = true
        })
      })
    },
    //球员跳转
    jumpPlayer(row) {
      const page = this.$router.resolve({path: '/NBA/playerDetail', query: {id: row.player_id}})
      window.open(page.href, '_blank')
    },
    //球队跳转
    jumpToTeam(row) {
      const page = this.$router.resolve({path: '/NBA/teamDetail', query: {team: row.name}})
      window.open(page.href, '_blank')
    },
    //新闻跳转
    jumpToNew(id) {
      const page = this.$router.resolve({path: '/NBA/newsDetail', query: {id: id}})
      window.open(page.href, '_blank')
    },
  },
  mounted() {
    this.getTeam(this.$route.query.team)
    bigRank().then(res => {
      this.bigRank = res.data.data
    })
  },
  computed: {
    result_in() {
      return (icontent) => {
        if (icontent === "主") {
          return {'color': '#DE3C31'}
        } else if (icontent === "平") {
          return {'color': '#67c23a'}
        } else if (icontent === "客") {
          return {'color': '#1a71c8'}
        } else if (icontent === "赢") {
          return {'color': '#DE3C31'}
        } else if (icontent === "走") {
          return {'color': '#67c23a'}
        } else if (icontent === "输") {
          return {'color': '#1a71c8'}
        } else if (icontent === "大") {
          return {'color': '#DE3C31'}
        } else if (icontent === "小") {
          return {'color': '#1a71c8'}
        } else if (icontent === "胜") {
          return {'color': '#DE3C31'}
        } else if (icontent === "负") {
          return {'color': '#1a71c8'}
        }
      }
    }
  }
}
</script>

<style scoped>
.head {
  height: 84px;
  display: flex;
  color: #FFFFFF;
  padding: 30px;
  justify-content: space-between;
  background-image: url("../../../../assets/teamBack.png");
}

.head > :first-child {
  display: flex;
  margin-top: 4px;
}

.head .logo {
  width: 100px;
  height: 100px;
  margin-top: -13px;
  margin-right: 16px;
}

.head h2 {
  padding: 4px 0 10px;
}

.head > :last-child > div {
  display: flex;
  padding: 10px 0;
  text-align: center;
}

.head > :last-child > div > :nth-child(2n+1) {
  width: 130px;
}

.head > :last-child > div > :nth-child(2n) {
  margin: 4px 8px 0;
}

.content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 12px;
}

.teamD {
  width: 892px;
  background: #f1f1f1;
}

>>> .el-tabs__header {
  margin: 0;
  padding: 0 205px;
  background: #FFFFFF;
}

>>> .el-tabs__nav > div {
  padding: 0;
  width: 120px;
  font-size: 15px;
  text-align: center;
}

>>> .el-tabs__content > div {
  background: #F7F7F8;
}

.statistical {
  display: flex;
  justify-content: space-between;
}

.statistical > div > div {
  width: 435px;
  height: 35px;
  font-size: 14px;
  line-height: 35px;
  text-align: center;
  background: #FFFFFF;
  border-bottom: 1px solid #EBEEF5;
  display: flex;
  justify-content: space-around;
}

.statistical > div > div > div {
  width: 33%;
}

.statistical > div > div:nth-child(2n) {
  background: #FAFAFA;
}

.teamN {
  width: 292px;
  display: flex;
  justify-content: space-between;
}

.teamN > div {
  width: 50%;
  text-align: center;
}

.teamN > div > div {
  height: 50px;
  line-height: 50px;
  background: #FFFFFF;
  border-bottom: 1px solid #EBEEF5;
}

.teamN > div > div:nth-child(2n) {
  background: #FAFAFA;
}

.teamN img {
  width: 36px;
  height: 36px;
  margin: 8px 20px 0;
  vertical-align: middle;
}

.teamN .team {
  display: flex;
  font-size: 14px;
}

.rank img {
  width: 30px;
  height: 30px;
  margin: 4px 4px 4px -4px;
  vertical-align: middle;
}

.btnBar {
  display: flex;
  margin-top: 12px;
  background: #FFFFFF;
}

.btnBar > .btn {
  width: 140px;
  border-bottom: none;
}

.btnBar .el-select {
  width: 120px;
  margin-top: 10px;
}

.tTitle {
  padding: 10px;
  margin-top: 6px;
  font-weight: bold;
  background: #FFFFFF;
  border-bottom: 1px solid #EBEEF5;
}

.headline {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.headline > div {
  width: calc(50% - 24px);
  font-size: 14px;
  padding: 10px 12px;
  background: #FFFFFF;
  border-bottom: 1px solid #EBEEF5;
}

.headline img {
  width: 40px;
  margin-top: -4px;
  margin-right: 10px;
  vertical-align: middle;
}
</style>