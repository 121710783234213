<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">收风篮球</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/NBA' }">NBA</el-breadcrumb-item>
      <el-breadcrumb-item>排名</el-breadcrumb-item>
    </el-breadcrumb>
    <el-radio-group v-model="type" size="medium" @change="changeNum">
      <el-radio-button label="1">球队</el-radio-button>
      <el-radio-button label="2">球员</el-radio-button>
    </el-radio-group>
    <div class="content">
      <div v-show="type==='1'">
        <el-radio-group v-model="teamType" size="small" class="btn">
          <el-radio-button label="1">联赛</el-radio-button>
          <el-radio-button label="2">走势</el-radio-button>
          <el-radio-button label="3">数据</el-radio-button>
        </el-radio-group>
        <div v-show="teamType==='1'">
          <div class="blue title">东部联盟排名</div>
          <el-table stripe :data="match.east" style="width: 100%" @row-click="jumpToTeam">
            <el-table-column type="index" label="排名"></el-table-column>
            <el-table-column prop="team.chinese_mini_name" label="球队">
              <template slot-scope="scope">
                <img :src="scope.row.team.logo" width="30" height="30" class="head_pic" alt=""/>
                <span>{{scope.row.team.chinese_mini_name}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="win" label="胜场"></el-table-column>
            <el-table-column prop="lose" label="负场"></el-table-column>
            <el-table-column prop="rate" label="胜率">
              <template slot-scope="scope">
                <span>{{scope.row.rate}}%</span>
              </template>
            </el-table-column>
            <el-table-column prop="home" label="主场">
              <template slot-scope="scope">
                <span>{{scope.row.home_win + ' - ' + scope.row.home_lose}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="away" label="客场">
              <template slot-scope="scope">
                <span>{{scope.row.away_win + ' - ' + scope.row.away_lose}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ew_rank" label="联盟">
              <template slot-scope="scope">
                <span>{{scope.row.league_win + ' - ' + scope.row.league_lose}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="zone_rank" label="分区">
              <template slot-scope="scope">
                <span>{{scope.row.zone_win + ' - ' + scope.row.zone_lose}}</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="blue title">西部联盟排名</div>
          <el-table stripe :data="match.west" style="width: 100%" @row-click="jumpToTeam">
            <el-table-column type="index" label="排名"></el-table-column>
            <el-table-column prop="team.chinese_mini_name" label="球队">
              <template slot-scope="scope">
                <img :src="scope.row.team.logo" width="30" height="30" class="head_pic" alt=""/>
                <span>{{scope.row.team.chinese_mini_name}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="win" label="胜场"></el-table-column>
            <el-table-column prop="lose" label="负场"></el-table-column>
            <el-table-column prop="rate" label="胜率">
              <template slot-scope="scope">
                <span>{{scope.row.rate}}%</span>
              </template>
            </el-table-column>
            <el-table-column prop="home" label="主场">
              <template slot-scope="scope">
                <span>{{scope.row.home_win + ' - ' + scope.row.home_lose}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="away" label="客场">
              <template slot-scope="scope">
                <span>{{scope.row.away_win + ' - ' + scope.row.away_lose}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="ew_rank" label="联盟">
              <template slot-scope="scope">
                <span>{{scope.row.league_win + ' - ' + scope.row.league_lose}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="zone_rank" label="分区">
              <template slot-scope="scope">
                <span>{{scope.row.zone_win + ' - ' + scope.row.zone_lose}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-table stripe :data="odds" style="width: 100%" v-show="teamType==='2'" @row-click="jumpToTeam">
          <el-table-column prop="date">
            <el-table-column type="index" label="排名" width="70"></el-table-column>
            <el-table-column prop="team.chinese_mini_name" label="球队">
              <template slot-scope="scope">
                <img :src="scope.row.team.logo" width="30" height="30" class="head_pic" alt=""/>
                <span>{{scope.row.team.chinese_mini_name}}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="让分" align="center" prop="asian_rate" :sortable="true" :sort-method="sortByAsian">
            <el-table-column prop="asian_win" label="赢"></el-table-column>
            <el-table-column prop="asian_draw" label="走"></el-table-column>
            <el-table-column prop="asian_lose" label="输"></el-table-column>
            <el-table-column prop="asian_rate" label="赢指率">
              <template slot-scope="scope">
                <span>{{scope.row.asian_rate}}%</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="总分" align="center" prop="over_rate" :sortable="true" :sort-method="sortByOver">
            <el-table-column prop="over_win" label="大"></el-table-column>
            <el-table-column prop="over_draw" label="走"></el-table-column>
            <el-table-column prop="over_lose" label="小"></el-table-column>
            <el-table-column prop="over_rate" label="大分率">
              <template slot-scope="scope">
                <span>{{scope.row.over_rate}}%</span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        <el-table stripe :data="teamData" style="width: 100%" v-show="teamType==='3'" @row-click="jumpToTeam">
          <el-table-column prop="date">
            <el-table-column type="index" label="排名" width="70"></el-table-column>
            <el-table-column prop="team_info.chinese_mini_name" label="球队" width="120">
              <template slot-scope="scope">
                <img :src="scope.row.team_info.logo" width="30" height="30" class="head_pic" alt=""/>
                <span>{{scope.row.team_info.chinese_mini_name}}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="进攻" align="center" prop="asian_rate" :sortable="true" :sort-method="sortByAtt">
            <el-table-column prop="per_score_get" label="场均得分"></el-table-column>
            <el-table-column prop="fgm_get_rate" label="命中率">
              <template slot-scope="scope">
                <span>{{scope.row.fgm_get_rate}}%</span>
              </template>
            </el-table-column>
            <el-table-column prop="pm3_get_rate" label="3分命中率">
              <template slot-scope="scope">
                <span>{{scope.row.pm3_get_rate}}%</span>
              </template>
            </el-table-column>
            <el-table-column prop="ftm_get_rate" label="罚篮命中率">
              <template slot-scope="scope">
                <span>{{scope.row.ftm_get_rate}}%</span>
              </template>
            </el-table-column>
            <el-table-column prop="per_tot_get" label="场均篮板"></el-table-column>
          </el-table-column>
          <el-table-column label="防守" align="center" prop="over_rate" :sortable="true" :sort-method="sortByDef">
            <el-table-column prop="per_score_lose" label="场均失分"></el-table-column>
            <el-table-column prop="fgm_lose_rate" label="命中率">
              <template slot-scope="scope">
                <span>{{scope.row.fgm_lose_rate}}%</span>
              </template>
            </el-table-column>
            <el-table-column prop="pm3_lose_rate" label="3分命中率">
              <template slot-scope="scope">
                <span>{{scope.row.pm3_lose_rate}}%</span>
              </template>
            </el-table-column>
            <el-table-column prop="ftm_lose_rate" label="罚篮命中率">
              <template slot-scope="scope">
                <span>{{scope.row.ftm_lose_rate}}%</span>
              </template>
            </el-table-column>
            <el-table-column prop="per_tot_lose" label="场均篮板"></el-table-column>
          </el-table-column>
        </el-table>
      </div>
      <div v-show="type==='2'" class="player">
        <el-table stripe :data="player" style="width: 100%" @header-click="sortByPlayer" v-loading="loading.player" @row-click="jumpToPlayer">
          <el-table-column type="index" label="排名"></el-table-column>
          <el-table-column prop="player" label="球员(场均数据)" width="115">
            <template slot-scope="scope">
              <span v-if="scope.row.player">{{scope.row.player}}</span>
              <span v-if="!scope.row.player">{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="team_info.chinese_mini_name" label="球队" width="115">
            <template slot-scope="scope">
              <img :src="scope.row.logo" width="30" height="30" class="head_pic" alt=""/>
              <span>{{scope.row.team}}</span>
            </template>
          </el-table-column >
          <el-table-column prop="games_played" label="场次" width="60"></el-table-column>
          <el-table-column prop="minutes_per_game" label="时间" width="74"></el-table-column>
          <el-table-column prop="points_per_game" label="得分" width="74" :sortable="true" :sort-orders="['ascending']" :sort-method="sortByNum"></el-table-column>
          <el-table-column prop="rebounds_per_game" label="篮板" width="74" :sortable="true" :sort-orders="['ascending']" :sort-method="sortByNum"></el-table-column>
          <el-table-column prop="assists_per_game" label="助攻" width="74" :sortable="true" :sort-orders="['ascending']" :sort-method="sortByNum"></el-table-column>
          <el-table-column prop="field_goal_percentage" label="命中率" width="90" :sortable="true" :sort-orders="['ascending']" :sort-method="sortByNum">
            <template slot-scope="scope">
              <span>{{scope.row.field_goal_percentage}}%</span>
            </template>
          </el-table-column>
          <el-table-column prop="three_point_field_goal_percentage" label="3分命中率" width="110" :sortable="true" :sort-orders="['ascending']" :sort-method="sortByNum">
            <template slot-scope="scope">
              <span>{{scope.row.three_point_field_goal_percentage}}%</span>
            </template>
          </el-table-column>
          <el-table-column prop="free_throw_percentage" label="罚篮命中率" width="115" :sortable="true" :sort-orders="['ascending']" :sort-method="sortByNum">
            <template slot-scope="scope">
              <span>{{scope.row.free_throw_percentage}}%</span>
            </template>
          </el-table-column>
          <el-table-column prop="steals_per_game" label="抢断" width="74" :sortable="true" :sort-orders="['ascending']" :sort-method="sortByNum"></el-table-column>
          <el-table-column prop="blocks_per_game" label="盖帽" width="74" :sortable="true" :sort-orders="['ascending']" :sort-method="sortByNum"></el-table-column>
          <el-table-column prop="efficiency_per_game" label="±值" width="74" sortable="true" :sort-orders="['ascending']" :sort-method="sortByNum"></el-table-column>
        </el-table>
      </div>
    </div>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import {index,odds,data,player} from '@/api/NBA/other'
export default {
  name: "ranking",
  data(){
    return{
      type: '1',
      teamType: '1',
      orderType: 'points_per_game',
      odds: [],
      match: [],
      player: [],
      teamData: [],
      loading:{
        team: false,
        player: false,
      }
    }
  },
  methods:{
    //球队排名 - 联赛
    getIndex(){
      index().then(res => {
        this.match = res.data.data
      })
    },
    //球队排名 - 走势
    getOdds(){
      odds().then(res => {
        this.odds = res.data.data
      })
    },
    //球队排名 - 数据
    getData(){
      data().then(res => {
        this.teamData = res.data.data
      })
    },
    //球员排名
    getPlayer(order){
      this.loading.player = true
      player(order).then(res => {
        this.loading.player = false
        this.player = res.data.data
      })
    },
    //球队走势 - 让分排序
    sortByAsian(obj1, obj2) {
      let num1 = obj1.asian_rate;
      let num2 = obj2.asian_rate;
      return num1-num2;
    },
    //球队走势 - 总分排序
    sortByOver(obj1, obj2) {
      let num1 = obj1.over_rate;
      let num2 = obj2.over_rate;
      return num1-num2;
    },
    //球队数据 - 进攻排序
    sortByAtt(obj1, obj2){
      let num1 = obj1.per_score_get;
      let num2 = obj2.per_score_get;
      return num1-num2;
    },
    //球队数据 - 防守排序
    sortByDef(obj1, obj2){
      let num1 = obj1.per_score_lose;
      let num2 = obj2.per_score_lose;
      return num1-num2;
    },
    //球员排序
    sortByNum(obj1,obj2){
      let num1 = obj1*1;
      let num2 = obj2*1;
      return num1 - num2;
    },
    //依据选择类型请求球员数据
    sortByPlayer(column){
      if (column.property && column.property !== 'player' && column.property !== 'team_info.chinese_mini_name' && column.property !== 'games_played' && column.property !== 'minutes_per_game'){
        if (this.orderType !== column.property){
          this.orderType = column.property
          this.getPlayer(column.property)
        }
      }
    },
    //球队跳转
    jumpToTeam(row){
      if (this.teamType === '3'){
        const page = this.$router.resolve({path: '/NBA/teamDetail', query: {team: row.team}})
        window.open(page.href,'_blank')
      } else {
        const page = this.$router.resolve({path: '/NBA/teamDetail', query: {team: row.name}})
        window.open(page.href,'_blank')
      }
    },
    //球员跳转
    jumpToPlayer(row){
      const page = this.$router.resolve({path: '/NBA/playerDetail', query: {id: row.player_id}})
      window.open(page.href,'_blank')
    },
  },
  mounted() {
    if (this.$route.query.type){
      this.type = '2'
    }
    this.getIndex()
    this.getOdds()
    this.getData()
    this.getPlayer(this.orderType)
  }
}
</script>

<style scoped>
  .content{
    padding: 12px;
    margin-top: 12px;
    background: #FFFFFF;
  }
  .content >>>.el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: #409EFF;
    background-color: #F7F7F8;
    border: none !important;
    box-shadow: none;
  }
  .content >>>.el-radio-button__inner{
    border: none !important;
  }
  .content img{
    vertical-align: middle;
    margin-right: 6px;
  }
  .btn{
    width: 100%;
    padding: 12px 0;
    border-bottom: 1px solid #EBEEF5;
  }
  /* 只显示降序 */
  .player .el-table /deep/ .sort-caret.descending {
    display: none;
  }
  .player .el-table /deep/ .sort-caret.ascending {
    top: 12px;
    transform:rotate(180deg);
  }
</style>