import ajax from 'axios'
import url from '../baseUrl'

// 球队名称
export const teamsName = params => { return ajax.get(url + `/api/nba/teams/rank`, params,{headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

//球队详情页
export const teamsDetail = teamName => { return ajax.get(url + `/api/nba/teams/detail`, { params: { team: teamName },headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

//东西部排名
export const bigRank = params => { return ajax.get(url + `/api/nba/teams/bigRank`, params,{headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

//赛季获取
export const seasons = params => { return ajax.get(url + `/api/nba/teams/seasons`, params,{headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

//球队赛程
export const competitions = params => { return ajax.get(url + `/api/nba/teams/competitions`, { params: { team: params.team , season: params.season , category: params.category},headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

//球队球员
export const players = params => { return ajax.get(url + `/api/nba/teams/players`, { params: { team: params.team , season: params.season , category: params.category},headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}
