<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">收风篮球</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/CBA' }">CBA</el-breadcrumb-item>
      <el-breadcrumb-item>伤停</el-breadcrumb-item>
    </el-breadcrumb>
    <div v-for="(item,key) in injured" :key="item">
      <div style="height: 18px" v-if="key>0"></div>
      <div class="headTop" @click="jumpTeam('CBA',item.team_id)">
        <img :src="item.logo" alt="">
        <span>{{item.team_name}}</span>
        <span v-if="item.injury.length">({{item.injury.length}})</span>
      </div>
      <el-table :data="item.injury" style="width: 100%" @row-click="jumpToPlayer">
        <el-table-column prop="player_name" label="球员" width="120"></el-table-column>
        <el-table-column prop="player_position" label="位置" width="90"></el-table-column>
        <el-table-column prop="status" label="状态" width="300"></el-table-column>
        <el-table-column prop="content" label="详情"></el-table-column>
      </el-table>
    </div>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import {injured} from '@/api/CBA/other'
export default {
  name: "injured",
  data(){
    return{
      injured: []
    }
  },
  methods:{
    jumpToPlayer(row){
      const page = this.$router.resolve({path: '/CBA/playerDetail', query: {id: row.player_id}})
      window.open(page.href,'_blank')
    }
  },
  mounted() {
    injured().then( res => {
      this.injured = res.data.data
    })
  }
}
</script>

<style scoped>
  .headTop{
    padding: 12px 6px;
    background: #FFFFFF;
    border-bottom: 1px solid #EBEEF5;
  }
</style>