<template>
  <div v-loading="loading">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">收风篮球</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/NBL' }">NBL</el-breadcrumb-item>
      <el-breadcrumb-item>裁判详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="head">
      <h1 v-if=" !detail.info.referee_name_cn ">{{ detail.info.referee_name }}</h1>
      <h1 v-if=" detail.info.referee_name_cn ">{{ detail.info.referee_name_cn }}</h1>
    </div>
    <div class="content">
      <div>
        <div>
          <el-radio-group v-model="num" size="small" @change="changeNum" class="btn">
            <el-radio-button label="1">近10场</el-radio-button>
            <el-radio-button label="2">本赛季</el-radio-button>
          </el-radio-group>
          <el-table :data="showMatch" stripe style="width: 100%">
            <el-table-column prop="competition_time" label="日期"></el-table-column>
            <el-table-column prop="chinese_mini_home" label="主队">
              <template slot-scope="scope">
                <span @click="jumpTeam('NBL',scope.row.home)">{{scope.row.home_name_abb}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="point" label="比分" width="90">
              <template slot-scope="scope">
                <span @click="jumpMatch('NBL',scope.row.competition_id)">{{scope.row.home_all}} - {{scope.row.away_all}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="chinese_mini_away" label="客队">
              <template slot-scope="scope">
                <span @click="jumpTeam('NBL',scope.row.away)">{{scope.row.away_name_abb}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="competition_result" label="胜负">
              <template slot-scope="scope">
                <span :style="result_in(scope.row.competition_result)">{{scope.row.competition_result}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="gap" label="分差">
              <template slot-scope="scope">
                <span>{{scope.row.home_all - scope.row.away_all}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="total" label="和数">
              <template slot-scope="scope">
                <span>{{scope.row.home_all*1 + scope.row.away_all*1}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="bet365_current_asian.handicap" label="让分"></el-table-column>
            <el-table-column prop="asian_current_result" label="走势">
              <template slot-scope="scope">
                <span :style="result_in(scope.row.asian_current_result)">{{scope.row.asian_current_result}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="bet365_current_over.handicap" label="总分"></el-table-column>
            <el-table-column prop="total_current_result" label="走势">
              <template slot-scope="scope">
                <span :style="result_in(scope.row.total_current_result)">{{scope.row.total_current_result}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="height: 12px"></div>
        <div class="tabBar blue">数据统计</div>
        <el-table :data="detail.detail" stripe style="width: 100%">
          <el-table-column prop="season" label="赛季"></el-table-column>
          <el-table-column prop="played" label="场次"></el-table-column>
          <el-table-column prop="" label="胜负">
            <template slot-scope="scope">
              <span>{{scope.row.win}} - {{scope.row.lose}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="让分">
            <template slot-scope="scope">
              <span>{{scope.row.asian_win}} - {{scope.row.asian_draw}} - {{scope.row.asian_lose}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="gap" label="总分">
            <template slot-scope="scope">
              <span>{{scope.row.total_win}} - {{scope.row.total_draw}} - {{scope.row.total_lose}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="points_per_game" label="场均得分"></el-table-column>
          <el-table-column prop="points_per_game_lose" label="场均失分"></el-table-column>
          <el-table-column prop="points" label="场均净胜"></el-table-column>
          <el-table-column prop="points_all_per_game" label="场均和数"></el-table-column>
        </el-table>
      </div>
      <div class="today_match" v-if="detail.today_game">
        <div>相关比赛</div>
        <div>
          <div>
            <img :src="detail.today_game.team_home.logo" alt="">
            <div>{{detail.today_game.chinese_mini_home}}</div>
          </div>
          <div>
            <div>{{detail.today_game.status}}</div>
            <div>{{detail.today_game.home_all}} : {{detail.today_game.away_all}}</div>
          </div>
          <div>
            <img :src="detail.today_game.team_away.logo" alt="">
            <div>{{detail.today_game.chinese_mini_away}}</div>
          </div>
        </div>
        <div>
          <div>
            <div :class="[detail.today_game.referees[0]===this.$route.query.name? 'blue':'']">{{detail.today_game.referees[0]}}</div>
            <div :class="[detail.today_game.referees[1]===this.$route.query.name? 'blue':'']">{{detail.today_game.referees[1]}}</div>
          </div>
          <div>
            <div :class="[detail.today_game.referees[2]===this.$route.query.name? 'blue':'']">{{detail.today_game.referees[2]}}</div>
            <div :class="[detail.today_game.referees[3]===this.$route.query.name? 'blue':'']">{{detail.today_game.referees[3]}}</div>
          </div>
        </div>
      </div>
      <div v-if="!detail.today_game">
        <div class="blue tabBar">裁判排行</div>
        <el-table :data="detail.rank" stripe style="width: 100%" @row-click="rush">
          <el-table-column type="index" label="排名"></el-table-column>
          <el-table-column prop="name" label="裁判名称" width="109">
            <template slot-scope="scope">
              <span v-if="!scope.row.referee.referee_name_cn">{{scope.row.referee.referee_name}}</span>
              <span v-if="scope.row.referee.referee_name_cn">{{scope.row.referee.referee_name_cn}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="让分" width="68">
            <template slot-scope="scope">
              <span>{{scope.row.asian_win}}/{{scope.row.asian_draw}}/{{scope.row.asian_lose}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="总分" width="68">
            <template slot-scope="scope">
              <span>{{scope.row.total_win}}/{{scope.row.total_draw}}/{{scope.row.total_lose}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import {detail} from '@/api/NBL/rederee'
export default {
  name: "team",
  data(){
    return{
      num: '1',
      detail: {},
      showMatch: [],
      loading: false,
    }
  },
  methods:{
    changeNum() {
      if (this.num === '1') {
        this.showMatch = this.detail.competition_ten
      } else {
        this.showMatch = this.detail.competition_now
      }
    },
    rush(row){
      if (row.name !== this.$route.query.name) {
        const page = this.$router.resolve({path: '/NBL/refereeDetail', query: {name: row.name}})
        window.open(page.href,'_blank')
      }
    },
    getDetail(){
      this.loading = true
      detail(this.$route.query.name).then(res => {
        this.loading = false
        this.detail = res.data.data
        for (let x in this.detail.competition_ten){
          this.detail.competition_ten[x].competition_time = this.detail.competition_ten[x].competition_time.substring(2,this.detail.competition_ten[x].competition_time.length-9)
          this.detail.competition_ten[x].competition_result = this.resultN(this.detail.competition_ten[x].competition_result)
          this.detail.competition_ten[x].asian_current_result = this.resultN(this.detail.competition_ten[x].asian_current_result, 'asian')
          this.detail.competition_ten[x].total_current_result = this.resultN(this.detail.competition_ten[x].total_current_result, 'over')
        }
        for (let x in this.detail.competition_now){
          this.detail.competition_now[x].competition_time = this.detail.competition_now[x].competition_time.substring(2,this.detail.competition_now[x].competition_time.length-9)
          this.detail.competition_now[x].competition_result = this.resultN(this.detail.competition_now[x].competition_result)
          this.detail.competition_now[x].asian_current_result = this.resultN(this.detail.competition_now[x].asian_current_result, 'asian')
          this.detail.competition_now[x].total_current_result = this.resultN(this.detail.competition_now[x].total_current_result, 'over')
        }
        this.showMatch = this.detail.competition_ten
        this.detail.today_game.referees = this.detail.today_game.referees.split(',  ')
      })
    }
  },
  mounted() {
    this.getDetail()
  },
  computed: {
    result_in() {
      return (icontent) => {
        if (icontent === "主") {
          return {'color': '#DE3C31'}
        } else if (icontent === "平") {
          return {'color': '#67c23a'}
        } else if (icontent === "客") {
          return {'color': '#1a71c8'}
        } else if (icontent === "赢") {
          return {'color': '#DE3C31'}
        } else if (icontent === "走") {
          return {'color': '#67c23a'}
        } else if (icontent === "输") {
          return {'color': '#1a71c8'}
        } else if (icontent === "大") {
          return {'color': '#DE3C31'}
        } else if (icontent === "小") {
          return {'color': '#1a71c8'}
        } else if (icontent === "胜") {
          return {'color': '#DE3C31'}
        } else if (icontent === "负") {
          return {'color': '#1a71c8'}
        }
      }
    }
  }
}
</script>

<style scoped>
  .head {
    height: 84px;
    padding: 30px;
    color: #FFFFFF;
    margin-bottom: 12px;
    background-image: url("../../../../assets/teamBack.png");
    display: flex;
    justify-content: space-between;
  }

  .head h1 {
    padding: 20px 0;
  }

  .tabBar{
    padding: 12px;
    background: #FFFFFF;
    border-bottom: 1px solid #EBEEF5;
  }

  .content{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .content > :first-child{
    width: 890px;
  }

  .content > :last-child{
    width: 295px;
  }
  .today_match img{
    width: 50px;
    height: 50px;
  }
  .today_match{
    width: 270px !important;
    padding: 10px;
    background: #FFFFFF;
  }
  .today_match > :first-child {
    padding: 6px 0;
    font-weight: bold;
    margin-bottom: 6px;
    border-bottom: 1px solid #F7F7F8;
  }
  .today_match > :nth-child(2){
    height: 80px;
    padding: 6px 0;
    font-size: 14px;
    text-align: center;
    border-bottom: 2px solid #F7F7F8;
    display: flex;
    justify-content: space-around;
  }
  .today_match > :nth-child(2) > :nth-child(2) > div{
    margin-top: 12px;
  }
  .today_match > :last-child > div{
    padding: 6px 0;
    display: flex;
    justify-content: space-around;
  }
  .today_match > :last-child > div > div{
    width: 50%;
  }
</style>