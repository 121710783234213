import ajax from 'axios'
import url from '../baseUrl'

//球队联赛
export const index = () => { return ajax.get(url + `/api/cba/rank/index`, {headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

//球队走势
export const odds = () => { return ajax.get(url + `/api/cba/rank/odds`, {headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

//球队数据
export const data = () => { return ajax.get(url + `/api/cba/rank/data`, {headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

//球员
export const player = order => { return ajax.get(url + `/api/cba/rank/player`, {params: {order : order} , headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

//伤病
export const injured = () => { return ajax.get(url + `/api/cba/injury/index`, { headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}