<template>
  <div class="news" v-loading="loading">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">收风篮球</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/WNBA' }">WNBA</el-breadcrumb-item>
      <el-breadcrumb-item>火线详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="detail">
      <div>
        <div class="newsTitle">{{detail.news.chinese_headline}}</div>
        <div class="time">
          <img v-if="detail.team" :src="detail.team.team.logo" alt="" @click="jumpTeam('WNBA',detail.team.team.name)">
          <img v-if="!detail.team" src="https://lq.sotips.cn/img/wnba.png" alt="">
          <span>{{detail.news.time}} </span>
        </div>
        <div>{{detail.news.chinese_describes}}</div>
        <img :src="detail.news.head_image" v-if="detail.news.head_image!=='https://admin.covers.sotips.cn'" alt="">
      </div>
      <div class="player" v-if="detail.player">
        <div @click="jumpPlayer('WNBA',detail.player.player_id)">
          <img :src="detail.player.player_info.avatar" alt="">
          <div v-if="detail.player.player_info.chinese_name">{{detail.player.player_info.chinese_name}}</div>
          <div v-if="!detail.player.player_info.chinese_name">{{detail.player.player_info.name}}</div>
        </div>
        <div>
          <div>{{detail.player.season}}常规赛</div>
          <div>
            <div>
              <div>号码</div>
              <div>{{detail.player.player_info.num}}</div>
            </div>
            <div>
              <div>场均时间</div>
              <div>{{detail.player.minutes_per_game}}</div>
            </div>
            <div>
              <div>命中率</div>
              <div>{{detail.player.field_goal_percentage}}%</div>
            </div>
          </div>
          <div>
            <div>
              <div>位置</div>
              <div>{{detail.player.player_info.position}}</div>
            </div>
            <div>
              <div>场均得分</div>
              <div>{{detail.player.points_per_game}}</div>
            </div>
            <div>
              <div>±值</div>
              <div>{{detail.player.efficiency_per_game}}</div>
            </div>
          </div>
          <div>
            <div>
              <div>场次</div>
              <div>{{detail.player.games_played}}</div>
            </div>

            <div>
              <div>场均篮板</div>
              <div>{{detail.player.rebounds_per_game}}</div>
            </div>
            <div>
              <div>场均助攻</div>
              <div>{{detail.player.assists_per_game}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="team" v-if="detail.team">
        <div @click="jumpTeam('WNBA',detail.team.team.name)">
          <img :src="detail.team.team.logo" alt="">
          <div>{{detail.team.team.chinese_mini_name}}</div>
        </div>
        <div>
          <div>{{detail.team.season}}常规赛</div>
          <div>
            <div>
              <div>联盟排名</div>
              <div>{{detail.team.big_rank}}</div>
            </div>
            <div>
              <div>东西排名</div>
              <div>{{detail.team.ew_rank}}</div>
            </div>
            <div>
              <div>赛区排名</div>
              <div>{{detail.team.zone_rank}}</div>
            </div>
          </div>
          <div>
            <div>
              <div>胜负</div>
              <div>{{detail.team.win}} - {{detail.team.lose}}</div>
            </div>
            <div>
              <div>让分</div>
              <div>{{detail.team.asian_win}} - {{detail.team.asian_draw}} - {{detail.team.asian_lose}}</div>
            </div>
            <div>
              <div>总分</div>
              <div>{{detail.team.over_win}} - {{detail.team.over_draw}} - {{detail.team.over_lose}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="more">
      <div class="blue">相关消息</div>
      <div v-for="item in detail.more" :key="item" @click="rush(item.id)">
        <img :src="item.team_info.logo" v-if="item.team_info" alt=""/>
        <img src="https://lq.sotips.cn/img/wnba.png" v-if="!item.team_info" alt="">
        <div>{{item.chinese_headline}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {detail} from '@/api/WNBA/news'
export default {
  name: "newsDetail",
  data(){
    return{
      detail: {},
      loading: false
    }
  },
  methods:{
    rush(id){
      const page = this.$router.resolve({path: '/WNBA/newsDetail', query: {id: id}})
      window.open(page.href,'_blank')
    },
    getDetail(id){
      this.loading = true
      detail(id).then( res => {
        this.loading = false
        this.detail = res.data.data
        let reg = new RegExp("&nbsp;","g");
        this.detail.news.describes = this.detail.news.describes.replace(reg,'')
        this.detail.news.new = this.detail.news.new.replace(reg,'')
      })
    }
  },
  mounted() {
    this.getDetail(this.$route.query.id)
  }
}
</script>

<style scoped>
  .news{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 50px;
  }

  .detail{
    width: 842px;
    padding: 24px;
    background: #FFFFFF;
  }

  .time{
    color: #aaaaaa;
    font-size: 12px;
  }

  .time img{
    width: 30px;
    height: 30px;
    margin-top: -4px;
    margin-right: 6px;
    vertical-align: middle;
  }

  .newsTitle{
    font-size: 26px;
    font-weight: bold;
  }

  .detail > :first-child > div{
    margin-bottom: 12px;
  }

  .detail > :first-child > img{
    width: 100%;
  }

  .more{
    width: 290px;
    font-size: 14px;
  }

  .blue{
    font-size: 16px;
  }

  .more > div{
    display: flex;
    padding: 10px 12px;
    background: #FFFFFF;
    border-bottom: 1px solid #EBEEF5;
  }

  .more img{
    width: 40px;
    margin-right: 10px;
  }

  .original{
    color: #1a71c8;
    font-size: 12px;
  }

  .player{
    display: flex;
    font-size: 14px;
    margin-bottom: 8px;
    border-radius: 6px;
    border: 1px solid #EBEEF5;
  }
  .player > div{
    border: 1px solid #EBEEF5;
  }

  .player img{
    margin-top: 8px;
    margin-bottom: 6px;
    width: 60px;
  }

  .player > :first-child{
    width: 90px;
    padding: 6px 12px;
    text-align: center;
  }
  .player > :last-child{
    width: calc(100% - 90px);
  }
  .player > :last-child > div{
    padding: 8px;
    display: flex;
    justify-content: space-around;
  }
  .player > :last-child > :nth-child(n+2){
    border-top: 2px solid #EBEEF5;
  }
  .player > :last-child > div > div{
    width: 33%;
    display: flex;
    justify-content: space-around;
  }
  .player > :last-child > div > div > div{
    width: 50%;
    text-align: center;
  }

  .team{
    display: flex;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid #EBEEF5;
  }
  .team img{
    margin-top: 8px;
    width: 60px;
  }
  .team > div{
    border: 1px solid #EBEEF5;
  }
  .team > :first-child{
    width: 90px;
    padding: 6px 12px;
    text-align: center;
  }
  .team > :last-child{
    width: calc(100% - 90px);
  }
  .team > :last-child > div{
    padding: 8px;
    display: flex;
    justify-content: space-around;
  }
  .team > :last-child > :nth-child(n+2){
    border-top: 2px solid #EBEEF5;
  }
  .team > :last-child > div > div{
    width: 33%;
    display: flex;
    justify-content: space-around;
  }
  .team > :last-child > div > div > div{
    width: 50%;
    text-align: center;
  }
</style>