<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">收风篮球</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/NBL' }">NBL</el-breadcrumb-item>
      <el-breadcrumb-item>比分</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="btnGroup">
      <div>
        <el-button size="small" type="primary" @click="dateChange(0)" :disabled="date===matchDay[matchDay.length-1]">
          前一比赛日
        </el-button>
        <el-date-picker
            v-model="date"
            type="date"
            size="small"
            @change="pickTime"
            style="width: 130px"
            placeholder="选择日期"
            :picker-options="pickerOptionsO"
            :start-placeholder="matchDay[0]">
        </el-date-picker>
        <el-button size="small" type="primary" @click="dateChange(1)" :disabled="date===matchDay[0]">后一比赛日</el-button>
      </div>
      <div>
        <div>指数选择:</div>
        <el-select v-model="value" placeholder="请选择" size="small">
          <el-option
              v-for="item in options"
              :key="item"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
      </div>
    </div>
    <el-card v-for="item in matchList" :key="item" v-loading="loading">
      <div class="matchList">
        <div>
          <div>{{ item.competition_time }}</div>
          <div class="blue">{{ item.status }}</div>
        </div>
        <div class="line"></div>
        <div class="match">
          <div>
            <div class="blue">NBL</div>
            <div @click="jumpTeam('NBL',item.home_id)">{{ item.home_name_abb }}</div>
            <div @click="jumpTeam('NBL',item.away_id)">{{ item.away_name_abb }}</div>
          </div>
          <div>
            <div>1</div>
            <div>{{ item.home_1 }}</div>
            <div>{{ item.away_1 }}</div>
          </div>
          <div>
            <div>2</div>
            <div>{{ item.home_2 }}</div>
            <div>{{ item.away_2 }}</div>
          </div>
          <div>
            <div>3</div>
            <div>{{ item.home_3 }}</div>
            <div>{{ item.away_3 }}</div>
          </div>
          <div>
            <div>4</div>
            <div>{{ item.home_4 }}</div>
            <div>{{ item.away_4 }}</div>
          </div>
          <div>
            <div>加时</div>
            <div>{{ item.home_t }}</div>
            <div>{{ item.away_t }}</div>
          </div>
          <div>
            <div>全场</div>
            <div :style="item.home_all!=='-' ? 'color:#e95c5c;font-weight: bold' : ''">{{ item.home_all }}</div>
            <div :style="item.away_all!=='-' ? 'color:#e95c5c;font-weight: bold' : ''">{{ item.away_all }}</div>
          </div>
          <div>
            <div>分差</div>
            <div v-if="item.status !== '未开赛' && item.home_2 !== '-'">半：{{
                item.home_1 * 1 + item.home_2 * 1 - item.away_1 * 1 - item.away_2 * 1
              }}
            </div>
            <div v-if="item.status !== '未开赛' && item.home_2 === '-'">半：{{
                item.home_1 * 1 - item.away_1 * 1
              }}
            </div>
            <div v-if="item.status !== '未开赛'">全：{{ item.home_all - item.away_all }}</div>
            <div v-if="item.status === '未开赛'">-</div>
            <div v-if="item.status === '未开赛'">-</div>
          </div>
          <div>
            <div>和数</div>
            <div v-if="item.status !== '未开赛' && item.home_2 !== '-'">半：{{
                item.home_1 * 1 + item.home_2 * 1 + item.away_1 * 1 + item.away_2 * 1
              }}
            </div>
            <div v-if="item.status !== '未开赛' && item.home_2 === '-'">半：{{
                item.home_1 * 1 + item.away_1 * 1
              }}
            </div>
            <div v-if="item.status !== '未开赛'">全：{{ item.home_all * 1 + item.away_all * 1 }}</div>
            <div v-if="item.status === '未开赛'">-</div>
            <div v-if="item.status === '未开赛'">-</div>
          </div>
          <div style="background: #f0f5fa;color: #cb4a4a">
            <div>胜负</div>
            <div v-if="value === '365' && item.bet365_current_odds">{{ item.bet365_current_odds.home }}</div>
            <div v-if="value === '365' && item.bet365_current_odds">{{ item.bet365_current_odds.away }}</div>

            <div v-if="value === '易胜博' && item.easybets_current_odds">{{ item.easybets_current_odds.home }}</div>
            <div v-if="value === '易胜博' && item.easybets_current_odds">{{ item.easybets_current_odds.away }}</div>

            <div v-if="value === '澳门' && item.macauslot_current_odds">{{ item.macauslot_current_odds.home }}</div>
            <div v-if="value === '澳门' && item.macauslot_current_odds">{{ item.macauslot_current_odds.away }}</div>

            <div v-if="value === '韦德' && item.vcbet_current_odds">{{ item.vcbet_current_odds.home }}</div>
            <div v-if="value === '韦德' && item.vcbet_current_odds">{{ item.vcbet_current_odds.away }}</div>
          </div>
          <div class="asian" style="background: #f0f5fa">
            <div>让分</div>
            <div v-if="value === '365' && item.bet365_current_asian">
              <span v-show="item.bet365_current_asian.handicap>0">{{ item.bet365_current_asian.handicap }}</span>
              <span>{{ item.bet365_current_asian.home }}</span>
            </div>
            <div v-if="value === '365' && item.bet365_current_asian">
              <span v-show="item.bet365_current_asian.handicap<0">{{ -item.bet365_current_asian.handicap }}</span>
              <span>{{ item.bet365_current_asian.away }}</span>
            </div>

            <div v-if="value === '易胜博' && item.easybets_current_asian">
              <span v-show="item.easybets_current_asian.handicap>0">{{ item.easybets_current_asian.handicap }}</span>
              <span>{{ item.easybets_current_asian.home }}</span>
            </div>
            <div v-if="value === '易胜博' && item.easybets_current_asian">
              <span v-show="item.easybets_current_asian.handicap<0">{{ -item.easybets_current_asian.handicap }}</span>
              <span>{{ item.easybets_current_asian.away }}</span>
            </div>

            <div v-if="value === '澳门' && item.macauslot_current_asian">
              <span v-show="item.macauslot_current_asian.handicap>0">{{ item.macauslot_current_asian.handicap }}</span>
              <span>{{ item.macauslot_current_asian.home }}</span>
            </div>
            <div v-if="value === '澳门' && item.macauslot_current_asian">
              <span v-show="item.macauslot_current_asian.handicap<0">{{ -item.macauslot_current_asian.handicap }}</span>
              <span>{{ item.macauslot_current_asian.away }}</span>
            </div>

            <div v-if="value === '韦德' && item.vcbet_current_asian">
              <span v-show="item.vcbet_current_asian.handicap>0">{{ item.vcbet_current_asian.handicap }}</span>
              <span>{{ item.vcbet_current_asian.home }}</span>
            </div>
            <div v-if="value === '韦德' && item.vcbet_current_asian">
              <span v-show="item.vcbet_current_asian.handicap<0">{{ -item.vcbet_current_asian.handicap }}</span>
              <span>{{ item.vcbet_current_asian.away }}</span>
            </div>
          </div>
          <div style="background: #f0f5fa">
            <div>总分</div>
            <div v-if="value === '365' && item.bet365_current_over">
              大<span>{{ item.bet365_current_over.handicap }}</span>
              <span>{{ item.bet365_current_over.home }}</span>
            </div>
            <div v-if="value === '365' && item.bet365_current_over">
              小<span>{{ item.bet365_current_over.handicap }}</span>
              <span>{{ item.bet365_current_over.away }}</span>
            </div>

            <div v-if="value === '易胜博' && item.easybets_current_over">
              大<span>{{ item.easybets_current_over.handicap }}</span>
              <span>{{ item.easybets_current_over.home }}</span>
            </div>
            <div v-if="value === '易胜博' && item.easybets_current_over">
              小<span>{{ item.easybets_current_over.handicap }}</span>
              <span>{{ item.easybets_current_over.away }}</span>
            </div>

            <div v-if="value === '澳门' && item.macauslot_current_over">
              大<span>{{ item.macauslot_current_over.handicap }}</span>
              <span>{{ item.macauslot_current_over.home }}</span>
            </div>
            <div v-if="value === '澳门' && item.macauslot_current_over">
              小<span>{{ item.macauslot_current_over.handicap }}</span>
              <span>{{ item.macauslot_current_over.away }}</span>
            </div>

            <div v-if="value === '韦德' && item.vcbet_current_over">
              大<span>{{ item.vcbet_current_over.handicap }}</span>
              <span>{{ item.vcbet_current_over.home }}</span></div>
            <div v-if="value === '韦德' && item.vcbet_current_over">
              小<span>{{ item.vcbet_current_over.handicap }}</span>
              <span>{{ item.vcbet_current_over.away }}</span></div>
          </div>
          <div class="btnPoint">
            <div>统计</div>
            <div>
              <div class="canClick" @click="jumpDetail(item.competition_id,'data',item.status)">数据</div>
              <div class="canClick" @click="jumpDetail(item.competition_id,'index',item.status)">指数</div>
            </div>
            <div>
              <div class="canClick" @click="jumpDetail(item.competition_id,'player',item.status)">球员</div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import {gameDay, index} from "@/api/NBL/point";

export default {
  name: "team",
  data() {
    return {
      date: '',
      value: '365',
      seasons: [],
      matchDay: [],
      matchList: [],
      loading: false,
      seasonValue: '',
      options: ['365', '易胜博', '澳门', '韦德']
    }
  },
  methods: {
    dateChange(type) {
      for (let x = 0; x < this.matchDay.length; x++) {
        let oDate = new Date(this.date)
        let oDay = oDate.getFullYear() + '-' + (oDate.getMonth() + 1 < 10 ? '0' + (oDate.getMonth() + 1) : oDate.getMonth() + 1) + '-' + (oDate.getDate() < 10 ? '0' + oDate.getDate() : oDate.getDate());
        if (this.matchDay[x] === oDay) {
          this.date = type ? this.matchDay[x - 1] : this.matchDay[x + 1]
          break;
        }
      }
      this.getMatch()
    },
    pickTime() {
      this.getMatch()
    },
    getMatch() {
      this.loading = true
      index(this.date).then(res => {
        this.loading = false
        this.matchList = res.data.data
        for (let x in this.matchList) {
          this.matchList[x].competition_time = this.matchList[x].competition_time.substring(10, this.matchList[x].competition_time.length - 3)
          this.matchList[x].status = this.matchList[x].status === 'Final' ? '已完结' : this.matchList[x].status
          this.matchList[x].status = this.matchList[x].status === '' ? '未开赛' : this.matchList[x].status
        }
      })
    },
    jumpDetail(id, type) {
      // if (state !== '未开赛'){
      const page = this.$router.resolve({path: '/NBL/pointDetail', query: {id: id, type: type}})
      window.open(page.href, '_blank')
      // }
    }
  },
  mounted() {
    gameDay().then(res => {
      this.matchDay = res.data.data
      let oDate = new Date()
      let oDay = oDate.getFullYear() + '-' + (oDate.getMonth() + 1 < 10 ? '0' + (oDate.getMonth() + 1) : oDate.getMonth() + 1) + '-' + (oDate.getDate() < 10 ? '0' + oDate.getDate() : oDate.getDate());
      if (this.matchDay.indexOf(oDay) !== -1) {
        this.date = oDay
      } else {
        if (new Date(oDay).getTime() - new Date(this.matchDay[0]).getTime() < 0) {
          for (let x = 0; x < 130; x++) {
            oDate = new Date(oDate.getTime() + 24 * 60 * 60 * 1000)
            oDay = oDate.getFullYear() + '-' + (oDate.getMonth() + 1 < 10 ? '0' + (oDate.getMonth() + 1) : oDate.getMonth() + 1) + '-' + (oDate.getDate() < 10 ? '0' + oDate.getDate() : oDate.getDate());
            if (this.matchDay.indexOf(oDay) !== -1) {
              this.date = oDay
              break;
            }
          }
        } else {
          this.date = this.matchDay[0]
        }
      }
      this.getMatch()
    })
  },
  computed: {
    pickerOptionsO() {
      let that = this
      return {
        disabledDate(time) {
          let oDate = time.getFullYear() + '-' + (time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1) + '-' + (time.getDate() < 10 ? '0' + time.getDate() : time.getDate());
          return that.matchDay.indexOf(oDate) === -1;
        }
      }
    },
  }
}
</script>

<style scoped>
.btnGroup {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.btnGroup > :first-child > :nth-child(n) {
  margin-right: 10px;
}

.btnGroup button {
  width: 100px;
}

.btnGroup > :last-child {
  display: flex;
  font-size: 14px;
  line-height: 32px;
}

.btnGroup > :last-child > :nth-child(n) {
  margin-left: 10px;
}

.btnGroup > :last-child > :last-child {
  width: 90px;
}

.el-button--primary {
  width: 80px;
  background-color: #1A71C8;
  border-color: #1A71C8;
}

.el-card {
  height: 124px;
  margin: 12px 0;
}

.matchList {
  display: flex;
  font-size: 14px;
  text-align: center;
}

.matchList > div:first-child {
  width: 60px;
  padding: 16px 0;
}

.matchList > div:first-child > div {
  padding: 6px 0;
}

.line {
  margin-right: 12px;
  border: 1px solid #E9EAEA;
}

.match {
  width: 1100px;
  border: 1px solid #EBEEF5;
  display: flex;
  justify-content: space-around;
}

.match > div > div {
  width: 73px;
  height: 28px;
  line-height: 28px;
  border: 1px solid #EBEEF5;
}

.match > div > div:first-child {
  color: #222222;
  background: #f1f1f1;
}

.match > div:nth-child(11) > div {
  width: 61px;
}

.match > div:nth-child(12) > div {
  width: 100px;
}

.match > div:last-child > div {
  width: 146px;
}

.match span {
  margin: 0 4px;
}

.match span:last-child {
  color: #cb4a4a;
}

.asian > div {
  padding: 0 10px;
}

.asian > div:nth-child(n+2) {
  display: flex;
  justify-content: flex-end;
}

.btnPoint > div {
  display: flex;
  justify-content: space-around;
}

.btnPoint > div > div {
  width: 60px;
}

>>> .el-input--suffix .el-input__inner {
  padding-right: 0;
}
</style>