<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">收风篮球</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/NBA' }">NBA</el-breadcrumb-item>
      <el-breadcrumb-item>伤停</el-breadcrumb-item>
    </el-breadcrumb>
    <div v-for="(item,key) in injured" :key="item">
      <div style="height: 18px" v-if="key>0"></div>
      <div class="headTop" @click="jumpTeam('NBA',item.name)">
        <img :src="item.logo" alt="">
        <span>{{item.chinese_mini_name}}</span>
        <span v-if="item.injury.length">({{item.injury.length}})</span>
      </div>
      <el-table :data="item.injury" style="width: 100%" @row-click="jumpToPlayer">
        <el-table-column prop="player" label="球员" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.players.chinese_name">{{scope.row.players.chinese_name}}</span>
            <span v-if="!scope.row.players.chinese_name">{{scope.row.player}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="position" label="位置" width="90"></el-table-column>
        <el-table-column prop="chinese_status" label="状态" width="300">
          <template slot-scope="scope">
            <span v-if="scope.row.display">{{scope.row.chinese_status}}</span>
            <span v-if="!scope.row.display">{{scope.row.status}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="chinese_content" label="详情">
          <template slot-scope="scope">
            <span v-if="scope.row.display">{{scope.row.chinese_content}}</span>
            <span v-if="!scope.row.display">{{scope.row.content}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import {injured} from '@/api/NBA/other'
export default {
  name: "injured",
  data(){
    return{
      injured: []
    }
  },
  methods:{
    jumpToPlayer(row){
      const page = this.$router.resolve({path: '/NBA/playerDetail', query: {id: row.player_id}})
      window.open(page.href,'_blank')
    }
  },
  mounted() {
    injured().then( res => {
      this.injured = res.data.data
    })
  }
}
</script>

<style scoped>
  .headTop{
    padding: 12px 6px;
    background: #FFFFFF;
    border-bottom: 1px solid #EBEEF5;
  }
</style>