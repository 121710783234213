<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">收风篮球</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/WNBA' }">WNBA</el-breadcrumb-item>
      <el-breadcrumb-item>球员</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="player">
      <div class="teamN">
        <div>
          <div class="blue">东部联盟</div>
          <div v-for="item in bigRank.east" :key="item.id" class="team canClick" @click="rush(item.name)" :class="[item.name === team ? 'currentTeam':'']">
            <img :src="item.team.logo" alt="">
            <span>{{ item.team.chinese_mini_name }}</span>
          </div>
        </div>
        <div>
          <div class="blue">西部联盟</div>
          <div v-for="item in bigRank.west" :key="item.id" class="team canClick" @click="rush(item.name)" :class="[item.name === team ? 'currentTeam':'']">
            <img :src="item.team.logo" alt="">
            <span>{{ item.team.chinese_mini_name }}</span>
          </div>
        </div>
      </div>
      <div class="teamP">
        <el-table :data="players" style="width: 892px" stripe v-loading="loading" @row-click="jumpDetail">
          <el-table-column prop="name" label="球员">
            <template slot-scope="scope">
              <img :src="scope.row.avatar" width="30" class="head_pic" alt=""/>
              <span v-if="scope.row.chinese_name">{{scope.row.chinese_name}}</span>
              <span v-if="!scope.row.chinese_name">{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="position" label="位置" width="120"></el-table-column>
          <el-table-column prop="num" label="号码" width="120"></el-table-column>
          <el-table-column prop="height" label="身⾼(米)" width="120"></el-table-column>
          <el-table-column prop="weight" label="体重(公斤)" width="120"></el-table-column>
          <el-table-column prop="birthdate" label="出⽣⽇期"></el-table-column>
        </el-table>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import {index} from "@/api/WNBA/player"
import {bigRank} from "@/api/WNBA/team";

export default {
  name: "team",
  data(){
    return{
      team: '',
      bigRank: {},
      players: [],
      loading: false,
    }
  },
  methods:{
    rush(team){
      if (team !== this.team){
        this.team = team
        this.getIndex(this.team)
      }
    },
    jumpDetail(row){
      const page = this.$router.resolve({path: '/WNBA/playerDetail', query: {id: row.player_id}})
      window.open(page.href,'_blank')
    },
    getIndex(team) {
      this.loading = true
      index(team).then(res => {
        this.loading = false
        this.players = res.data.data
      })
    },
  },
  mounted() {
    bigRank().then(res => {
      this.bigRank = res.data.data
      this.team = this.bigRank.east[0].name
      this.getIndex(this.team)
    })
  }
}
</script>

<style scoped>
  .player{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .teamN {
    width: 292px;
    display: flex;
    justify-content: space-between;
  }

  .teamN > div {
    width: 50%;
    text-align: center;
  }

  .teamN > div > div {
    height: 50px;
    line-height: 50px;
    background: #FFFFFF;
    border-bottom: 1px solid #EBEEF5;
  }

  .teamN > div > div:nth-child(2n) {
    background: #FAFAFA;
  }

  .teamN img {
    width: 36px;
    height: 36px;
    margin: 8px 20px 0;
    vertical-align: middle;
  }

  .teamN .team {
    display: flex;
    font-size: 14px;
  }

  .teamP img{
    margin-right: 6px;
    vertical-align: middle;
  }

  .currentTeam{
    background: #ececec !important;
  }

  >>> .el-table__row{
    cursor: pointer;
  }
</style>