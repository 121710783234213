import Vue from "vue";
import VueRouter from "vue-router";
//一级页面
import index from "../views/index"

//NBA页面
import NBA from "../views/NBA/main";
import home from "../views/NBA/pages/home"
import point from "../views/NBA/pages/point/point";
import team from "../views/NBA/pages/team/team";
import ranking from "../views/NBA/pages/others/ranking";
import injured from "../views/NBA/pages/others/injured";
import player from "../views/NBA/pages/player/player";
import news from "../views/NBA/pages/news/news";
import referee from "../views/NBA/pages/referee/referee";
import pointDetail from "../views/NBA/pages/point/pointDetail";
import teamDetail from "../views/NBA/pages/team/teamDetail";
import playerDetail from "../views/NBA/pages/player/playerDetail";
import newsDetail from "../views/NBA/pages/news/newsDetail";
import refereeDetail from "../views/NBA/pages/referee/refereeDetail";

//WNBA页面
import WNBA from "../views/WNBA/main";
import WNBA_Home from "../views/WNBA/pages/home"
import WNBA_Point from "../views/WNBA/pages/point/point";
import WNBA_Team from "../views/WNBA/pages/team/team";
import WNBA_Ranking from "../views/WNBA/pages/others/ranking";
import WNBA_Injured from "../views/WNBA/pages/others/injured";
import WNBA_Player from "../views/WNBA/pages/player/player";
import WNBA_News from "../views/WNBA/pages/news/news";
import WNBA_Referee from "../views/WNBA/pages/referee/referee";
import WNBA_PointDetail from "../views/WNBA/pages/point/pointDetail";
import WNBA_TeamDetail from "../views/WNBA/pages/team/teamDetail";
import WNBA_PlayerDetail from "../views/WNBA/pages/player/playerDetail";
import WNBA_NewsDetail from "../views/WNBA/pages/news/newsDetail";
import WNBA_RefereeDetail from "../views/WNBA/pages/referee/refereeDetail";

//CBA页面
import CBA from "../views/CBA/main";
import CBA_Home from "../views/CBA/pages/home"
import CBA_Point from "../views/CBA/pages/point/point";
import CBA_Team from "../views/CBA/pages/team/team";
import CBA_Ranking from "../views/CBA/pages/others/ranking";
import CBA_Injured from "../views/CBA/pages/others/injured";
import CBA_Player from "../views/CBA/pages/player/player";
import CBA_News from "../views/CBA/pages/news/news";
import CBA_PointDetail from "../views/CBA/pages/point/pointDetail";
import CBA_TeamDetail from "../views/CBA/pages/team/teamDetail";
import CBA_PlayerDetail from "../views/CBA/pages/player/playerDetail";
import CBA_NewsDetail from "../views/CBA/pages/news/newsDetail";

//NBL页面
import NBL from "../views/NBL/main";
import NBL_Home from "../views/NBL/pages/home"
import NBL_Point from "../views/NBL/pages/point/point";
import NBL_Team from "../views/NBL/pages/team/team";
import NBL_Ranking from "../views/NBL/pages/others/ranking";
import NBL_Injured from "../views/NBL/pages/others/injured";
import NBL_Player from "../views/NBL/pages/player/player";
import NBL_News from "../views/NBL/pages/news/news";
import NBL_Referee from "../views/NBL/pages/referee/referee";
import NBL_PointDetail from "../views/NBL/pages/point/pointDetail";
import NBL_TeamDetail from "../views/NBL/pages/team/teamDetail";
import NBL_PlayerDetail from "../views/NBL/pages/player/playerDetail";
import NBL_NewsDetail from "../views/NBL/pages/news/newsDetail";
import NBL_RefereeDetail from "../views/NBL/pages/referee/refereeDetail";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
  },
  {
    path: "/NBA",
    name: "NBA",
    component: NBA,
    redirect: "/NBA",
    children:[{path:'/NBA',component:home},
      {path:'/NBA/point',component:point},
      {path:'/NBA/team',component:team},
      {path:'/NBA/ranking',component:ranking},
      {path:'/NBA/injured',component:injured},
      {path:'/NBA/player',component:player},
      {path:'/NBA/news',component:news},
      {path:'/NBA/referee',component:referee},
      {path:'/NBA/pointDetail',component:pointDetail},
      {path:'/NBA/teamDetail',component:teamDetail},
      {path:'/NBA/playerDetail',component:playerDetail},
      {path:'/NBA/newsDetail',component:newsDetail},
      {path:'/NBA/refereeDetail',component:refereeDetail}]
  },
  {
    path: "/WNBA",
    name: "WNBA",
    component: WNBA,
    redirect: "/WNBA",
    children:[{path:'/WNBA',component:WNBA_Home},
      {path:'/WNBA/point',component:WNBA_Point},
      {path:'/WNBA/team',component:WNBA_Team},
      {path:'/WNBA/ranking',component:WNBA_Ranking},
      {path:'/WNBA/injured',component:WNBA_Injured},
      {path:'/WNBA/player',component:WNBA_Player},
      {path:'/WNBA/news',component:WNBA_News},
      {path:'/WNBA/referee',component:WNBA_Referee},
      {path:'/WNBA/pointDetail',component:WNBA_PointDetail},
      {path:'/WNBA/teamDetail',component:WNBA_TeamDetail},
      {path:'/WNBA/playerDetail',component:WNBA_PlayerDetail},
      {path:'/WNBA/newsDetail',component:WNBA_NewsDetail},
      {path:'/WNBA/refereeDetail',component:WNBA_RefereeDetail}]
  },
  {
    path: "/CBA",
    name: "CBA",
    component: CBA,
    redirect: "/CBA",
    children:[{path:'/CBA',component:CBA_Home},
      {path:'/CBA/point',component:CBA_Point},
      {path:'/CBA/team',component:CBA_Team},
      {path:'/CBA/ranking',component:CBA_Ranking},
      {path:'/CBA/injured',component:CBA_Injured},
      {path:'/CBA/player',component:CBA_Player},
      {path:'/CBA/news',component:CBA_News},
      {path:'/CBA/pointDetail',component:CBA_PointDetail},
      {path:'/CBA/teamDetail',component:CBA_TeamDetail},
      {path:'/CBA/playerDetail',component:CBA_PlayerDetail},
      {path:'/CBA/newsDetail',component:CBA_NewsDetail}]
  },
  {
    path: "/NBL",
    name: "NBL",
    component: NBL,
    redirect: "/NBL",
    children:[{path:'/NBL',component:NBL_Home},
      {path:'/NBL/point',component:NBL_Point},
      {path:'/NBL/team',component:NBL_Team},
      {path:'/NBL/ranking',component:NBL_Ranking},
      {path:'/NBL/injured',component:NBL_Injured},
      {path:'/NBL/player',component:NBL_Player},
      {path:'/NBL/news',component:NBL_News},
      {path:'/NBL/referee',component:NBL_Referee},
      {path:'/NBL/pointDetail',component:NBL_PointDetail},
      {path:'/NBL/teamDetail',component:NBL_TeamDetail},
      {path:'/NBL/playerDetail',component:NBL_PlayerDetail},
      {path:'/NBL/newsDetail',component:NBL_NewsDetail},
      {path:'/NBL/refereeDetail',component:NBL_RefereeDetail}]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  next()
})

export default router;
