import ajax from 'axios'
import url from '../baseUrl'

// 球队名称
export const teamsName = params => { return ajax.get(url + `/api/nbl/teams/rank`, params,{headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

//球队详情页
export const teamsDetail = teamName => { return ajax.get(url + `/api/nbl/teams/detail`, { params: { team_id: teamName },headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

//赛季获取
export const seasons = params => { return ajax.get(url + `/api/nbl/teams/seasons`, params,{headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

//球队赛程
export const competitions = params => { return ajax.get(url + `/api/nbl/teams/competitions`, { params: { team_id: params.team_id , season: params.season , phase_id: params.phase_id},headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

//球队球员
export const players = params => { return ajax.get(url + `/api/nbl/teams/players`, { params: { team_id:params.team_id, team_name: params.team_name, season: params.season, phase_id: params.phase_id},headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}
