<template>
  <div class="team">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">收风篮球</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/WNBA' }">WNBA</el-breadcrumb-item>
      <el-breadcrumb-item>球队</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <div>
        <div class="blue">东部赛区</div>
        <router-link v-for="item in teamsName.east" :key="item.id" class="canClick" :to="{path:'/WNBA/teamDetail',query:{team:item.name}}" target="_blank">
          <div>
            <img :src="item.team.logo" alt="">
            <span>{{item.team.chinese_mini_name}}</span>
          </div>
        </router-link>
      </div>
      <div style="width: 10px;background: transparent"></div>
      <div>
        <div class="blue">西部赛区</div>
        <router-link v-for="item in teamsName.west" :key="item.id" class="canClick" :to="{path:'/WNBA/teamDetail',query:{team:item.name}}" target="_blank">
          <div>
            <img :src="item.team.logo" alt="">
            <span>{{item.team.chinese_mini_name}}</span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {teamsName} from "@/api/WNBA/team";

export default {
  name: "team",
  data(){
    return{
      teamsName: {}
    }
  },
  methods:{
    sortNum(a,b){
      return a.rank - b.rank
    }
  },
  mounted() {
    teamsName().then(res => {
      this.teamsName = res.data.data
      this.teamsName.Southeast.sort(this.sortNum)
      this.teamsName.Central.sort(this.sortNum)
      this.teamsName.Atlantic.sort(this.sortNum)
      this.teamsName.Pacific.sort(this.sortNum)
      this.teamsName.Northwest.sort(this.sortNum)
      this.teamsName.Southwest.sort(this.sortNum)
    })
  }
}
</script>

<style scoped>
  .content{
    display: flex;
  }
  .content > div{
    width: 49%;
    text-align: center;
    background-color: #FFFFFF;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .content > div > div:first-child{
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #E9EAEA;
  }
  .content > div > a:nth-child(n+2){
    width: 50%;
    height: 83px;
    line-height: 83px;
  }
  .content > div > a:nth-child(4){
    background-color: #EFEFEF;
  }
  .content > div > a:nth-child(5){
    background-color: #EFEFEF;
  }
  a div{
    display: flex;
    justify-content: center;
  }
  .blue{
    width: 100%;
  }
  img{
    width: 50px;
    height: 50px;
    margin-top: 18px;
    margin-left: 30px;
    vertical-align:middle;
  }
  .canClick span{
    min-width: 90px;
    font-size: 16px;
  }
</style>