<template>
  <div class="main">
    <div class="Header">
      <el-menu
          :default-active="this.$route.path"
          class="el-menu-demo"
          mode="horizontal"
          background-color="#FFF"
          text-color="#000"
          active-text-color="#1A71C8"
          router>
        <el-menu-item index="/" class="blue"><img src="@/assets/logo.png" alt=""/>收风数据</el-menu-item>
      </el-menu>
    </div>
    <div class="back">
      <el-carousel height="240px" style="margin-bottom: 12px;">
        <el-carousel-item v-for="item in banner" :key="item">
          <div class="shuffling">
            <img :src="item" alt="">
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="Qr_code">
        <div>
          <div style="width: 6px;background: #376DF5;margin-right: 6px"></div>
          <div>收风篮球数据网站</div>
        </div>
        <div>
          <el-card class="canClick" @click.native="jumpLeague('NBA')">
            <img src="../assets/logo/NBA.png" alt="">
            <div>收风NBA网</div>
          </el-card>
          <el-card class="canClick" @click.native="jumpLeague('WNBA')">
            <img src="../assets/logo/WNBA.png" alt="">
            <div>收风WNBA网</div>
          </el-card>
          <el-card class="canClick" @click.native="jumpLeague('CBA')">
            <img src="../assets/logo/CBA.png" alt="">
            <div>收风CBA网</div>
          </el-card>
          <el-card class="canClick" @click.native="jumpLeague('NBL')">
            <img src="../assets/logo/NBL.png" alt="">
            <div>收风NBL网</div>
          </el-card>
        </div>
      </div>
      <div class="Qr_code">
        <div>
          <div style="width: 6px;background: #376DF5;margin-right: 6px"></div>
          <div>收风会员服务</div>
        </div>
        <div>
          <div>
            <img src="../assets/Qr_code/sotips_vip service.png" alt="">
            <div>收风会员服务</div>
          </div>
          <div>
            <img src="../assets/Qr_code/Pre-sale service.png" alt="">
            <div>售前服务</div>
          </div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="Qr_code">
        <div>
          <div style="width: 6px;background: #376DF5;margin-right: 6px"></div>
          <div>收风微信公众号</div>
        </div>
        <div>
          <div>
            <img src="../assets/Qr_code/sotips_weixin.png" alt="">
            <div>微信公众号</div>
          </div>
          <div>
            <img src="../assets/Qr_code/xgtips_salary.png" alt="">
            <div>NBA薪资小程序</div>
          </div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div style="height: 40px"></div>
      <div class="info">
        <div class="copyright">&copy;收风数据信息技术（天津）有限公司</div>
        <div @click="bottom">津ICP备2020006586号-1</div>
        <div><a href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12010402001265'
                target="view_window"><img src="@/assets/police.png" alt=""/>津公网安备 12010402001265</a></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  data() {
    return {
      user: {},
      bounced: 0,
      infoState: 0,
      showModal: false,
      banner: [
        "https://lq.sotips.cn/upload/banner/pc/banner1_pc.png",
        "https://lq.sotips.cn/upload/banner/pc/banner2_pc.png",
        "https://lq.sotips.cn/upload/banner/pc/banner3_pc.png"
      ]
    };
  },
  methods: {
    jumpLeague(league){
      this.$router.push(`/${league}`)
    },
    bottom() {
      window.location.replace("https://beian.miit.gov.cn/");
    }
  }
};
</script>

<style scoped>
img {
  width: 100%;
}

.main {
  height: 100%;
  min-width: 1200px;
}

.Header {
  min-width: 850px;
  position: relative;
}

.Header div {
  float: right;
  color: white;
  font-size: 18px;
  line-height: 60px;
  margin-right: 5px;
}

.Header img {
  width: 40px;
  height: 40px;
  margin-top: -6px;
  margin-right: 8px;
  border-radius: 50%;
  vertical-align: middle;
}

.Qr_code > div {
  padding: 4px 0;
  margin-top: 12px;
  display: flex;
}

.Qr_code > :last-child {
  margin-top: 6px;
  padding: 24px 0;
  font-size: 14px;
  text-align: center;
  background: #FFFFFF;
  justify-content: space-around;
}

.Qr_code > :last-child > div {
  width: 22%;
}

.Qr_code > :last-child > div > div > div {
  margin-top: 6px;
}

.Qr_code img {
  width: auto;
  height: 60px;
}

.el-menu-demo {
  padding: 0 calc((100% - 1300px) / 2);
}

>>> .el-menu-item:first-child {
  width: 180px;
  padding-left: 0;
  font-size: 24px;
  transition: none;
  border: none;
  text-align: center;
  border-bottom-color: rgba(255, 255, 255, 0);
}

>>> .el-menu-item:first-child:hover {
  background: rgba(0, 0, 0, 0) !important;
}

.el-menu--horizontal > .el-menu-item.is-active:first-child {
  border-bottom: none;
}

.back {
  position: relative;
  min-height: calc(100% - 192px);
  background: #f5f5f5;
  padding: 12px calc((100% - 1200px) / 2) 0;
}

.info {
  width: 1200px;
  bottom: -118px;
  font-size: 14px;
  color: #313131;
  background: #fdfdfc;
  margin-left: calc((1200px - 100%) / 2);
  padding: 30px calc((100% - 1200px) / 2) 20px;
  position: absolute;
  text-align: center;
}

.info > div {
  margin: 5px 0;
}

.info img {
  width: 20px;
  height: 20px;
}

.nav > a {
  color: #313131;
}

.nav > span {
  cursor: pointer;
}

.info > :last-child {
  cursor: pointer;
  text-align: center;
}
</style>
