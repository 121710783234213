<template>
  <div id="app">
    <router-view v-if="isRouterAlive"/>
  </div>
</template>

<script>
export default {
  name: 'App',
  provide(){
    return{
      reload: this.reload
    }
  },
  data(){
    return{
      isRouterAlive: true
    }
  },
  methods:{
    reload(){
      this.isRouterAlive = false
      this.$nextTick(function (){
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style>
html,body{
  margin: 0;
  padding: 0;
  height: 100%;
  background: #F7F7F8;
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
}
#app {
  height: 100%;
  position: relative;
}
a {
  color: #222222;
  text-decoration: none;
}
h1,h2,h3,h4,h5{
  margin: 0;
  padding: 0;
}
button{
  border: none;
  outline: none;
}
.canClick{
  cursor: pointer;
}
.title {
  width: 100%;
  padding: 12px 0;
  text-align: center;
}
.router-link-active {
  text-decoration: none;
}
.el-breadcrumb{
  width: 100%;
  padding-bottom: 12px;
}
.blue{
  color: #1A71C8 !important;
}
.grey{
  color: #FAFAFA !important ;
}
.grey_bac{
  background: #FAFAFA !important ;
}
.current_team {
  background: #ebf7ff !important;
}
.headTop img{
  width: 30px;
  height: 30px;
  margin: -6px 4px 0;
  vertical-align: middle;
}
.btn {
  width: calc(100% - 16px);
  background: #FFFFFF;
  border-bottom: 1px solid #EBEEF5;
  padding: 10px 8px;
}
.el-backtop{
  left: calc(50% + 630px);
}
.el-message-box{
  width: 500px !important;
}
</style>
