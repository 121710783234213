<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">收风篮球</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/NBL' }">NBL</el-breadcrumb-item>
      <el-breadcrumb-item>球员</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="player">
      <div class="teamN">
        <div class="blue">NBL</div>
        <div v-for="item in bigRank" :key="item.id" class="team canClick" @click="rush(item.team_id)"
             :class="[item.team_id === team ? 'currentTeam':'']">
          <img :src="item.logo" alt="">
          <span>{{ item.team_name_abb }}</span>
        </div>
      </div>
      <div class="teamP">
        <el-table :data="players" style="width: 892px" stripe v-loading="loading" @row-click="jumpDetail">
          <el-table-column prop="name" label="球员" width="150">
            <template slot-scope="scope">
              <img :src="scope.row.avatar" width="30" class="head_pic" alt=""/>
              <span>{{ scope.row.player_name_cn }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="position_cn" label="位置" width="140"></el-table-column>
          <el-table-column prop="number" label="号码" width="140"></el-table-column>
          <el-table-column prop="height" label="身⾼(米)" width="140"></el-table-column>
          <el-table-column prop="weight" label="体重(公斤)" width="140"></el-table-column>
          <el-table-column prop="birthdate" label="出⽣⽇期"></el-table-column>
        </el-table>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import {index} from "@/api/NBL/player"
import {teamsName} from "@/api/NBL/team";

export default {
  name: "team",
  data() {
    return {
      team: '',
      bigRank: {},
      players: [],
      loading: false,
    }
  },
  methods: {
    rush(team) {
      if (team !== this.team) {
        this.team = team
        this.getIndex(this.team)
      }
    },
    jumpDetail(row) {
      const page = this.$router.resolve({path: '/NBL/playerDetail', query: {id: row.player_id}})
      window.open(page.href, '_blank')
    },
    getIndex(team_id) {
      this.loading = true
      index(team_id).then(res => {
        this.loading = false
        this.players = res.data.data
      })
    },
  },
  mounted() {
    teamsName().then(res => {
      this.bigRank = res.data.data
      this.team = this.bigRank[0].team_id
      this.getIndex(this.team)
    })
  }
}
</script>

<style scoped>
.player {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.teamN {
  width: 292px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.teamN > :first-child{
  width: 100%;
}

.teamN > div {
  width: 50%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #FFFFFF;
  border-bottom: 1px solid #EBEEF5;
}

.teamN > div:nth-child(2n) {
  background: #FAFAFA;
}

.teamN img {
  width: 36px;
  height: 36px;
  margin: 8px 20px 0;
  vertical-align: middle;
}

.teamN .team {
  display: flex;
  font-size: 14px;
}

.teamP img {
  margin-right: 6px;
  vertical-align: middle;
}

.currentTeam {
  background: #ececec !important;
}

>>> .el-table__row {
  cursor: pointer;
}
</style>