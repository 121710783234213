import { render, staticRenderFns } from "./playerDetail.vue?vue&type=template&id=43853e43&scoped=true&"
import script from "./playerDetail.vue?vue&type=script&lang=js&"
export * from "./playerDetail.vue?vue&type=script&lang=js&"
import style0 from "./playerDetail.vue?vue&type=style&index=0&id=43853e43&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43853e43",
  null
  
)

export default component.exports