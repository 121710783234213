<template>
  <div class="main">
    <div class="Header">
      <el-menu
        :default-active="this.$route.path"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#FFF"
        text-color="#000"
        active-text-color="#1A71C8"
        router>
        <el-menu-item index="/NBA" class="blue"><img src="@/assets/logo.png" alt="" />收风篮球</el-menu-item>
        <el-menu-item index="/NBA">首页</el-menu-item>
        <el-menu-item index="/NBA/point">比分</el-menu-item>
        <el-menu-item index="/NBA/news">火线</el-menu-item>
        <el-menu-item index="/NBA/injured">伤停</el-menu-item>
        <el-menu-item index="/NBA/ranking">排名</el-menu-item>
        <el-menu-item index="/NBA/team">球队</el-menu-item>
        <el-menu-item index="/NBA/player">球员</el-menu-item>
        <el-menu-item index="/NBA/referee">裁判</el-menu-item>
      </el-menu>
    </div>
    <div class="back">
      <router-view class="asd"></router-view>
      <div class="info">
        <div class="copyright">&copy;收风数据信息技术（天津）有限公司</div>
        <div @click="bottom">津ICP备2020006586号-1</div>
        <div><a href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12010402001265' target="view_window"><img src="../../assets/police.png" alt=""/>津公网安备 12010402001265</a></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  data() {
    return {
      user: {},
      bounced: 0,
      infoState: 0,
      showModal: false,
    };
  },
  methods: {
    bottom() {
      window.location.replace("https://beian.miit.gov.cn/");
    },
  },
  mounted() {
    // info().then((res) => {
    //   // console.log(res.data)
    //   if (res.data.code !== 1) {
    //     this.infoState = 0;
    //   } else {
    //     this.infoState = 1;
    //     this.user = res.data.data;
    //   }
    // });
    // console.log(this.$route.path)
  }
};
</script>

<style scoped>
img {
  width: 40px;
  height: 40px;
  margin-top: -6px;
  margin-right: 8px;
  vertical-align: middle;
}
.main {
  height: 100%;
  min-width: 1200px;
}
.Header {
  min-width: 850px;
  position: relative;
}
.Header div {
  float: right;
  color: white;
  font-size: 18px;
  line-height: 60px;
  margin-right: 5px;
}
.Header img {
  border-radius: 50%;
  vertical-align: middle;
}
.el-menu-demo {
  padding: 0 calc((100% - 1300px) / 2);
}
>>> .el-menu-item:nth-child(n + 2).is-active {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
>>> .el-menu-item {
  width: 70px;
  border: none;
  font-size: 16px;
  text-align: center;
  border-bottom-color: rgba(255, 255, 255, 0);
}
>>> .el-menu-item:first-child {
  width: 180px;
  padding-left: 0;
  font-size: 24px;
  transition: none;
}
>>> .el-menu-item:first-child:hover {
  background: rgba(0, 0, 0, 0) !important;
}
.el-menu--horizontal > .el-menu-item.is-active:first-child {
  border-bottom: none;
}
.asd{
  padding: 0 calc((100% - 1200px) / 2) 0;
}
.back {
  position: relative;
  min-height: calc(100% - 192px);
  background: #f5f5f5;
  padding: 12px calc((100% - 1200px) / 2) 0;
}
.info {
  width: 1200px;
  bottom: -118px;
  font-size: 14px;
  color: #313131;
  background: #fdfdfc;
  margin-left: calc((1200px - 100%) / 2);
  padding: 30px calc((100% - 1200px) / 2) 20px;
  position: absolute;
  text-align: center;
}
.info > div{
  margin: 5px 0;
}
.info img{
  width: 20px;
  height: 20px;
}
.nav > a {
  color: #313131;
}
.nav > span {
  cursor: pointer;
}
.info > :last-child {
  cursor: pointer;
  text-align: center;
}
</style>
