<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">收风篮球</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/NBA' }">NBA</el-breadcrumb-item>
      <el-breadcrumb-item>裁判</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="btnGroup">
      <div>
        <el-button size="small" type="primary" @click="dateChange(0)">前一比赛日</el-button>
        <el-date-picker
            v-model="date"
            type="date"
            size="small"
            @change="pickTime"
            style="width: 130px"
            placeholder="选择日期"
            :picker-options="pickerOptionsO"
            :start-placeholder="matchDay[0]">
        </el-date-picker>
        <el-button size="small" type="primary" @click="dateChange(1)">后一比赛日</el-button>
      </div>
    </div>
    <div class="blue title">赛事裁判安排</div>
    <el-card v-for="item in match" :key="item" v-loading="loading">
      <div class="matchList">
        <div>
          <div>{{ item.competition_time }}</div>
          <div class="blue">{{ item.status }}</div>
        </div>
        <div class="line"></div>
        <div class="match">
          <div>
            <div class="blue">NBA</div>
            <div>{{ item.chinese_mini_home }}</div>
            <div>{{ item.chinese_mini_away }}</div>
          </div>
          <div>
            <div>1</div>
            <div>{{ item.home_1 }}</div>
            <div>{{ item.away_1 }}</div>
          </div>
          <div>
            <div>2</div>
            <div>{{ item.home_2 }}</div>
            <div>{{ item.away_2 }}</div>
          </div>
          <div>
            <div>3</div>
            <div>{{ item.home_3 }}</div>
            <div>{{ item.away_3 }}</div>
          </div>
          <div>
            <div>4</div>
            <div>{{ item.home_4 }}</div>
            <div>{{ item.away_4 }}</div>
          </div>
          <div>
            <div>加时</div>
            <div>{{ item.home_ot }}</div>
            <div>{{ item.away_ot }}</div>
          </div>
          <div>
            <div>全场</div>
            <div>{{ item.home_all }}</div>
            <div>{{ item.away_all }}</div>
          </div>
          <div>
            <div>分差</div>
            <div v-if="item.home_all !== '-' && item.away_all !== '-'">{{ item.home_all - item.away_all }}</div>
            <div v-if="item.home_all !== '-' && item.away_all !== '-'">{{ item.away_all - item.home_all }}</div>
            <div v-if="item.home_all === '-' || item.away_all === '-'">-</div>
            <div v-if="item.home_all === '-' || item.away_all === '-'">-</div>
          </div>
          <div>
            <div>和数</div>
            <div v-if="item.home_all !== '-' && item.away_all !== '-'">{{ item.home_all * 1 + item.away_all * 1 }}</div>
            <div v-if="item.home_all !== '-' && item.away_all !== '-'">{{ item.away_all * 1 + item.home_all * 1 }}</div>
            <div v-if="item.home_all === '-' || item.away_all === '-'">-</div>
            <div v-if="item.home_all === '-' || item.away_all === '-'">-</div>
          </div>
          <div style="background: #f0f5fa;color: #cb4a4a">
            <div>胜负</div>
            <div v-if="item.bet365_current_odds">{{ item.bet365_current_odds.home }}</div>
            <div v-if="item.bet365_current_odds">{{ item.bet365_current_odds.away }}</div>
          </div>
          <div class="asian" style="background: #f0f5fa">
            <div>让分</div>
            <div v-if="item.bet365_current_asian">
              <span v-show="item.bet365_current_asian.handicap>0">{{ item.bet365_current_asian.handicap }}</span>
              <span>{{ item.bet365_current_asian.home }}</span>
            </div>
            <div v-if="item.bet365_current_asian">
              <span v-show="item.bet365_current_asian.handicap<0">{{ -item.bet365_current_asian.handicap }}</span>
              <span>{{ item.bet365_current_asian.away }}</span>
            </div>
          </div>
          <div style="background: #f0f5fa">
            <div>总分</div>
            <div v-if="item.bet365_current_over">
              大<span>{{ item.bet365_current_over.handicap }}</span>
              <span>{{ item.bet365_current_over.home }}</span>
            </div>
            <div v-if="item.bet365_current_over">
              小<span>{{ item.bet365_current_over.handicap }}</span>
              <span>{{ item.bet365_current_over.away }}</span>
            </div>
          </div>
          <div class="btnPoint">
            <div>裁判</div>
            <div>
              <div class="canClick" @click="jumpDetail(item.referees[0].name)" v-if="item.referees[0] && !item.referees[0].chinese_name">{{ item.referees[0].name }}</div>
              <div class="canClick" @click="jumpDetail(item.referees[0].name)" v-if="item.referees[0] && item.referees[0].chinese_name">{{ item.referees[0].chinese_name }}</div>
              <div class="canClick" @click="jumpDetail(item.referees[1].name)" v-if="item.referees[1] && !item.referees[1].chinese_name">{{ item.referees[1].name }}</div>
              <div class="canClick" @click="jumpDetail(item.referees[1].name)" v-if="item.referees[1] && item.referees[1].chinese_name">{{ item.referees[1].chinese_name }}</div>
            </div>
            <div>
              <div class="canClick" @click="jumpDetail(item.referees[2].name)" v-if="item.referees[2] && !item.referees[2].chinese_name">{{ item.referees[2].name }}</div>
              <div class="canClick" @click="jumpDetail(item.referees[2].name)" v-if="item.referees[2] && item.referees[2].chinese_name">{{ item.referees[2].chinese_name }}</div>
              <div class="canClick" @click="jumpDetail(item.referees[3].name)" v-if="item.referees[3] && !item.referees[3].chinese_name">{{ item.referees[3].name }}</div>
              <div class="canClick" @click="jumpDetail(item.referees[3].name)" v-if="item.referees[3] && item.referees[3].chinese_name">{{ item.referees[3].chinese_name }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card v-if="!match.length">
      <el-empty description="当日暂无赛事" image="https://lq.sotips.cn/img/empty.png" v-loading="loading"></el-empty>
    </el-card>
    <div class="blue title">裁判排名</div>
    <el-table :data="rank" stripe style="width: 100%" @row-click="jumpDetail">
      <el-table-column type="index" label="排名"></el-table-column>
      <el-table-column prop="name" label="裁判">
        <template slot-scope="scope">
          <span v-if="!scope.row.referee_info.chinese_name">{{scope.row.name}}</span>
          <span v-if="scope.row.referee_info.chinese_name">{{scope.row.referee_info.chinese_name}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="played" label="场次" sortable></el-table-column>
      <el-table-column prop="" label="胜负" :sortable="true" :sort-method="sortByResult">
        <template slot-scope="scope">
          <span>{{scope.row.win}} - {{scope.row.lose}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="" label="让分" :sortable="true" :sort-method="sortByAsian">
        <template slot-scope="scope">
          <span>{{scope.row.asian_win}} - {{scope.row.asian_draw}} - {{scope.row.asian_lose}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="gap" label="总分" :sortable="true" :sort-method="sortByOver">
        <template slot-scope="scope">
          <span>{{scope.row.over_win}} - {{scope.row.over_draw}} - {{scope.row.over_lose}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="points_per_game" label="场均得分" sortable></el-table-column>
      <el-table-column prop="points_per_game_lose" label="场均失分" sortable></el-table-column>
      <el-table-column prop="points" label="场均净胜" sortable></el-table-column>
      <el-table-column prop="points_all_per_game" label="场均和数" sortable></el-table-column>
    </el-table>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import {gameDay} from "@/api/NBA/point";
import {index,rank} from '@/api/NBA/rederee'

export default {
  name: "team",
  data(){
    return{
      date: '',
      rank: [],
      match: [],
      matchDay: [],
      loading: false,
    }
  },
  methods:{
    dateChange(type) {
      let oDate = new Date(this.date)
      let oDay = oDate.getFullYear() + '-' + (oDate.getMonth() + 1 < 10 ? '0' + (oDate.getMonth() + 1) : oDate.getMonth() + 1) + '-' + (oDate.getDate() < 10 ? '0' + oDate.getDate() : oDate.getDate());
      if (this.matchDay.indexOf(oDay) !== -1) {
        for(let x = 0; x < this.matchDay.length; x++){
          if (this.matchDay[x] === oDay){
            this.date = type ? this.matchDay[x-1] : this.matchDay[x+1]
            break;
          }
        }
      } else {
        if (new Date(oDay).getTime() - new Date(this.matchDay[0]).getTime() < 0) {
          for (let x = 0; x < 130;) {
            if (type){
              oDate = new Date(oDate.getTime() + 24 * 60 * 60 * 1000)
            } else {
              oDate = new Date(oDate.getTime() - 24 * 60 * 60 * 1000)
            }
            oDay = oDate.getFullYear() + '-' + (oDate.getMonth() + 1 < 10 ? '0' + (oDate.getMonth() + 1) : oDate.getMonth() + 1) + '-' + (oDate.getDate() < 10 ? '0' + oDate.getDate() : oDate.getDate());
            if (this.matchDay.indexOf(oDay) !== -1) {
              this.date = oDay
              break;
            }
            x = type ? ++x : --x
          }
        } else {
          this.date = this.matchDay[0]
        }
      }
      this.getMatch()
    },
    pickTime(){
      this.getMatch()
    },
    jumpDetail(row){
      if(row.name){
        const page = this.$router.resolve({path: '/NBA/refereeDetail', query: {name: row.name}})
        window.open(page.href,'_blank')
      } else {
        const page = this.$router.resolve({path: '/NBA/refereeDetail', query: {name: row}})
        window.open(page.href,'_blank')
      }
    },
    getMatch(){
      this.loading = true
      index(this.date).then(res => {
        this.loading = false
        this.match = res.data.data
        for (let x in this.match) {
          this.match[x].competition_time = this.match[x].competition_time.substring(10, this.match[x].competition_time.length - 3)
          this.match[x].status = this.match[x].status === 'Final' ? '已完结' : this.match[x].status
          this.match[x].status = this.match[x].status === '' ? '未开赛' : this.match[x].status
        }
      })
    },
    sortByResult(obj1,obj2){
      let num1 = obj1.win/(obj1.win+obj1.lose)
      let num2 = obj2.win/(obj2.win+obj2.lose)
      return num1 - num2
    },
    sortByAsian(obj1,obj2){
      let num1 = obj1.asian_win/(obj1.asian_win+obj1.asian_draw+obj1.asian_lose)
      let num2 = obj2.asian_win/(obj2.asian_win+obj2.asian_draw+obj2.asian_lose)
      return num1 - num2
    },
    sortByOver(obj1,obj2){
      let num1 = obj1.over_win/(obj1.over_win+obj1.over_draw+obj1.over_lose)
      let num2 = obj2.over_win/(obj2.over_win+obj2.over_draw+obj2.over_lose)
      return num1 - num2
    },
  },
  mounted() {
    let oDate = new Date()
    this.date = oDate.getFullYear() + '-' + (oDate.getMonth() + 1 < 10 ? '0' + (oDate.getMonth() + 1) : oDate.getMonth() + 1) + '-' + (oDate.getDate() < 10 ? '0' + oDate.getDate() : oDate.getDate());
    gameDay().then(res => {
      this.matchDay = res.data.data
      this.getMatch()
    })
    rank().then(res => {
      this.rank = res.data.data
    })
  },
  computed: {
    pickerOptionsO() {
      let that = this
      return {
        disabledDate(time) {
          let oDate = time.getFullYear() + '-' + (time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1) + '-' + (time.getDate() < 10 ? '0' + time.getDate() : time.getDate());
          return that.matchDay.indexOf(oDate) === -1;
        }
      }
    },
  }
}
</script>

<style scoped>
  .btnGroup {
    display: flex;
    justify-content: space-between;
  }

  .btnGroup > :first-child > :nth-child(n) {
    margin-right: 10px;
  }

  .btnGroup button{
    width: 100px;
  }

  .btnGroup > :last-child {
    display: flex;
    font-size: 14px;
    line-height: 32px;
  }

  .el-button--primary {
    width: 80px;
    background-color: #1A71C8;
    border-color: #1A71C8;
  }

  .matchList {
    display: flex;
    font-size: 14px;
    text-align: center;
  }

  .matchList > div:first-child{
    width: 60px;
    padding: 16px 0;
  }

  .matchList > div:first-child > div {
    padding: 6px 0;
  }

  .line {
    margin-right: 12px;
    border: 1px solid #E9EAEA;
  }

  .match {
    width: 1100px;
    border: 1px solid #EBEEF5;
    display: flex;
    justify-content: space-around;
  }

  .match > div > div {
    width: 66px;
    height: 28px;
    line-height: 28px;
    border: 1px solid #EBEEF5;
  }

  .match > div > div:first-child {
    color: #222222;
    background: #f1f1f1;
  }

  .match > div:nth-child(11) > div {
    width: 61px;
  }

  .match > div:nth-child(12) > div {
    width: 100px;
  }

  .match > div:last-child > div {
    width: 220px;
  }

  .match span {
    margin: 0 4px;
  }

  .match span:last-child {
    color: #cb4a4a;
  }

  .asian > div {
    padding: 0 10px;
  }

  .asian > div:nth-child(n+2) {
    display: flex;
    justify-content: flex-end;
  }

  .btnPoint > div {
    display: flex;
    justify-content: space-around;
  }

  .btnPoint > div > div{
    width: 110px;
  }

  >>> .el-table__row{
    cursor: pointer;
  }

  .el-empty{
    padding: 0;
  }

  .el-card {
    margin-bottom: 12px;
  }
</style>