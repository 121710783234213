import ajax from 'axios'
import url from '../baseUrl'

// 新闻轮播图
export const banner = team => { return ajax.get(url + `/api/cba/newsletter/banner`,{params: {team: team},headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

// 新闻首页
export const index = params => { return ajax.get(url + `/api/cba/newsletter/index`,{params: {team_id: params.team_id,page: params.page,paginate: 16},headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}

// 新闻详情
export const detail = id => { return ajax.get(url + `/api/cba/newsletter/detail`,{params: {id: id},headers: {
        'X-Requested-With':'XMLHttpRequest',
        'content-type': 'application/json;charset=utf-8',
    }})
}