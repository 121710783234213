import ajax from 'axios'
import url from '../baseUrl'

/* 一级页 */
//获取比赛日期
export const gameDay = season => {
    return ajax.get(url + `/api/wnba/competitions/gameDay`, {
        params: {season: season}, headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'content-type': 'application/json;charset=utf-8',
        }
    })
}

// 球队名称
export const index = time => {
    return ajax.get(url + `/api/wnba/competitions/index`, {
        params: {competition_time: time}, headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'content-type': 'application/json;charset=utf-8',
        }
    })
}

/* 二级页 */
//比赛详情
export const detail = id => {
    return ajax.get(url + `/api/wnba/competitions/detail`, {
        params: {competition_id: id}, headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'content-type': 'application/json;charset=utf-8',
        }
    })
}

//今日赛程
export const todayCompetitions = time => {
    return ajax.get(url + `/api/nba/competitions/todayCompetitions`, {
        params: {competition_time: time}, headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'content-type': 'application/json;charset=utf-8',
        }
    })
}

//获取数据
export const getData = id => {
    return ajax.get(url + `/api/wnba/competitions/data`, {
        params: {competition_id: id}, headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'content-type': 'application/json;charset=utf-8',
        }
    })
}

//交锋战绩
export const sameResult = params => {
    return ajax.get(url + `/api/wnba/competitions/sameResult`, {
        params: {
            home: params.home,
            away: params.away,
            competition_time: params.time,
            is_same: params.same,
            category: params.category,
        }, headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'content-type': 'application/json;charset=utf-8',
        }
    })
}


//主队战绩
export const homeResult = params => {
    return ajax.get(url + `/api/wnba/competitions/homeResult`, {
        params: {
            team: params.team,
            competition_time: params.time,
            is_home: params.home,
            category: params.category,
        }, headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'content-type': 'application/json;charset=utf-8',
        }
    })
}

//客队战绩
export const awayResult = params => {
    return ajax.get(url + `/api/wnba/competitions/awayResult`, {
        params: {
            team: params.team,
            competition_time: params.time,
            is_away: params.away,
            category: params.category,
        }, headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'content-type': 'application/json;charset=utf-8',
        }
    })
}

//获取指数
export const handicap = id => {
    return ajax.get(url + `/api/wnba/competitions/handicap`, {
        params: {competition_id: id}, headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'content-type': 'application/json;charset=utf-8',
        }
    })
}

//交易量
export const consensus = id => {
    return ajax.get(url + `/api/wnba/competitions/consensus`, {
        params: {competition_id: id}, headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'content-type': 'application/json;charset=utf-8',
        }
    })
}

//球员统计
export const players = id => {
    return ajax.get(url + `/api/wnba/competitions/players`, {
        params: {competition_id: id}, headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'content-type': 'application/json;charset=utf-8',
        }
    })
}
