<template>
  <div v-loading="loading">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">收风篮球</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/CBA' }">CBA</el-breadcrumb-item>
      <el-breadcrumb-item>比分详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="head">
      <div v-if="matchDetail">
        <div class="teamName canClick" style="text-align: right" @click="jumpTeam('CBA',matchDetail.home_id)">{{matchDetail.home_name_abb}}</div>
        <img class="canClick" :src="matchDetail.home.logo" alt="" @click="jumpTeam('CBA',matchDetail.home_id)">
        <div class="point">{{matchDetail.home_all}}</div>
        <div class="center">
          <div>{{matchDetail.competitionTime}}</div>
          <div>VS</div>
          <div>{{matchDetail.status}}</div>
        </div>
        <div class="point">{{matchDetail.away_all}}</div>
        <img class="canClick" :src="matchDetail.away.logo" alt="" @click="jumpTeam('CBA',matchDetail.away_id)">
        <div class="teamName canClick" style="text-align: left" @click="jumpTeam('CBA',matchDetail.away_id)">{{matchDetail.away_name_abb}}</div>
      </div>
      <div class="pointTable">
        <div>
          <div>球队</div>
          <div @click="jumpTeam('CBA',matchDetail.home_id)" class="canClick">{{matchDetail.home_name_abb}}</div>
          <div @click="jumpTeam('CBA',matchDetail.away_id)" class="canClick">{{matchDetail.away_name_abb}}</div>
        </div>
        <div>
          <div>第一节</div>
          <div>{{matchDetail.home_1}}</div>
          <div>{{matchDetail.away_1}}</div>
        </div>
        <div>
          <div>第二节</div>
          <div>{{matchDetail.home_2}}</div>
          <div>{{matchDetail.away_2}}</div>
        </div>
        <div>
          <div>第三节</div>
          <div>{{matchDetail.home_3}}</div>
          <div>{{matchDetail.away_3}}</div>
        </div>
        <div>
          <div>第四节</div>
          <div>{{matchDetail.home_4}}</div>
          <div>{{matchDetail.away_4}}</div>
        </div>
        <div>
          <div>加时</div>
          <div>{{ matchDetail.home_t1+matchDetail.home_t2+matchDetail.home_t3+matchDetail.home_t4 }}</div>
          <div>{{ matchDetail.away_t1+matchDetail.away_t2+matchDetail.away_t3+matchDetail.away_t4 }}</div>
        </div>
        <div>
          <div>全场</div>
          <div>{{matchDetail.home_all}}</div>
          <div>{{matchDetail.away_all}}</div>
        </div>
        <div>
          <div>分差</div>
          <div v-if="matchDetail.status!=='未开赛'">半：{{ matchDetail.home_1*1 + matchDetail.home_2*1 - matchDetail.away_1*1 - matchDetail.away_2*1 }}</div>
          <div v-if="matchDetail.status!=='未开赛'">全：{{ matchDetail.home_all - matchDetail.away_all }}</div>
          <div v-if="matchDetail.status==='未开赛'">-</div>
          <div v-if="matchDetail.status==='未开赛'">-</div>
        </div>
        <div>
          <div>和数</div>
          <div v-if="matchDetail.status!=='未开赛'">半：{{ matchDetail.home_1*1 + matchDetail.home_2*1 + matchDetail.away_1*1 + matchDetail.away_2*1 }}</div>
          <div v-if="matchDetail.status!=='未开赛'">全：{{ matchDetail.home_all * 1 + matchDetail.away_all * 1 }}</div>
          <div v-if="matchDetail.status==='未开赛'">-</div>
          <div v-if="matchDetail.status==='未开赛'">-</div>
        </div>
        <div>
          <div>胜负</div>
          <div v-if="matchDetail.bet365_current_odds">{{matchDetail.bet365_current_odds.home}}</div>
          <div v-if="matchDetail.bet365_current_odds">{{matchDetail.bet365_current_odds.away}}</div>
          <div v-if="!matchDetail.bet365_current_odds">-</div>
          <div v-if="!matchDetail.bet365_current_odds">-</div>
        </div>
        <div>
          <div>让分</div>
          <div v-if="matchDetail.bet365_current_asian">
            <span v-show="matchDetail.bet365_current_asian.handicap>0">{{ matchDetail.bet365_current_asian.handicap }}</span>&nbsp;
            <span>{{ matchDetail.bet365_current_asian.home }}</span>
          </div>
          <div v-if="matchDetail.bet365_current_asian">
            <span v-show="matchDetail.bet365_current_asian.handicap<0">{{ -matchDetail.bet365_current_asian.handicap }}</span>&nbsp;
            <span>{{ matchDetail.bet365_current_asian.away }}</span>
          </div>
          <div v-if="!matchDetail.bet365_current_asian">-</div>
          <div v-if="!matchDetail.bet365_current_asian">-</div>
        </div>
        <div>
          <div>总分</div>
          <div v-if="matchDetail.bet365_current_over">
            大&nbsp;<span>{{ matchDetail.bet365_current_over.handicap }}</span>&nbsp;
            <span>{{ matchDetail.bet365_current_over.home }}</span>
          </div>
          <div v-if="matchDetail.bet365_current_over">
            小&nbsp;<span>{{ matchDetail.bet365_current_over.handicap }}</span>&nbsp;
            <span>{{ matchDetail.bet365_current_over.away }}</span>
          </div>
          <div v-if="!matchDetail.bet365_current_over">-</div>
          <div v-if="!matchDetail.bet365_current_over">-</div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="total">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="数据" name="data" class="fontSize">
            <div class="blue title" id="total" ref="total">战绩统计</div>
            <div class="boxHeight panTotal">
              <div>
                <div></div>
                <div>总</div>
                <div>主</div>
                <div>客</div>
              </div>
              <div>
                <div></div>
                <div>
                  <div>战绩</div>
                  <div>让分</div>
                  <div>总分</div>
                </div>
                <div>
                  <div>战绩</div>
                  <div>让分</div>
                  <div>总分</div>
                </div>
                <div>
                  <div>战绩</div>
                  <div>让分</div>
                  <div>总分</div>
                </div>
              </div>
              <div>
                <div @click="jumpTeam('CBA',matchDetail.home_id)" class="canClick">{{matchDetail.home_name_abb}}</div>
                <div>
                  <div>{{dataShow.home_result_all}}</div>
                  <div>{{dataShow.home_asian_all}}</div>
                  <div>{{dataShow.home_over_all}}</div>
                </div>
                <div>
                  <div>{{dataShow.home_result_home}}</div>
                  <div>{{dataShow.home_asian_home}}</div>
                  <div>{{dataShow.home_over_home}}</div>
                </div>
                <div>
                  <div>{{dataShow.home_result_away}}</div>
                  <div>{{dataShow.home_asian_away}}</div>
                  <div>{{dataShow.home_over_away}}</div>
                </div>
              </div>
              <div>
                <div @click="jumpTeam('CBA',matchDetail.away_id)" class="canClick">{{matchDetail.away_name_abb}}</div>
                <div>
                  <div>{{dataShow.away_result_all}}</div>
                  <div>{{dataShow.away_asian_all}}</div>
                  <div>{{dataShow.away_over_all}}</div>
                </div>
                <div>
                  <div>{{dataShow.away_result_home}}</div>
                  <div>{{dataShow.away_asian_home}}</div>
                  <div>{{dataShow.away_over_home}}</div>
                </div>
                <div>
                  <div>{{dataShow.away_result_away}}</div>
                  <div>{{dataShow.away_asian_away}}</div>
                  <div>{{dataShow.away_over_away}}</div>
                </div>
              </div>
            </div>
            <div class="blue title" id="meet" ref="meet">交锋战绩</div>
            <div class="boxHeight">
              <div class="btnMatch">
                <el-radio-group v-model="games.bout" size="small" @change="changeNum">
                  <el-radio-button label="1">近10场</el-radio-button>
                  <el-radio-button label="2">近20场</el-radio-button>
                </el-radio-group>
                <el-checkbox v-model="radioBtn.same" @change="handleCheckedCitiesChange(1)">主客相同</el-checkbox>
                <el-checkbox-group v-model="sessionChoose.same" :min="1" @change="handleCheckedCitiesChange(1)">
                  <el-checkbox v-for="item in session" :label="item.e" :key="item.e">{{item.c}}</el-checkbox>
                </el-checkbox-group>
              </div>
              <el-table :data="showMeeting" stripe style="width: 100%" v-loading="meetLoading.same">
                <el-table-column prop="competition_time" label="日期"></el-table-column>
                <el-table-column prop="home_name_abb" label="主队">
                  <template slot-scope="scope">
                    <span @click="jumpTeam('CBA',scope.row.home_id)">{{scope.row.home_name_abb}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="point" label="比分" width="90">
                  <template slot-scope="scope">
                    <span @click="jumpMatch('CBA',scope.row.competition_id)">{{scope.row.home_all}} - {{scope.row.away_all}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="away_name_abb" label="客队">
                  <template slot-scope="scope">
                    <span @click="jumpTeam('CBA',scope.row.away_id)">{{scope.row.away_name_abb}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="competition_result" label="胜负">
                  <template slot-scope="scope">
                    <span :style="result_in(scope.row.competition_result)">{{scope.row.competition_result}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="gap" label="分差"></el-table-column>
                <el-table-column prop="total" label="和数"></el-table-column>
                <el-table-column prop="bet365_current_asian.handicap" label="让分"></el-table-column>
                <el-table-column prop="asian_current_result" label="走势">
                  <template slot-scope="scope">
                    <span :style="result_in(scope.row.asian_current_result)">{{scope.row.asian_current_result}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="bet365_current_over.handicap" label="总分"></el-table-column>
                <el-table-column prop="total_current_result" label="走势">
                  <template slot-scope="scope">
                    <span :style="result_in(scope.row.total_current_result)">{{scope.row.total_current_result}}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="blue title" id="match" ref="match">主客战绩</div>
            <div class="boxHeight">
              <div>
                <div class="btnMatch">
                  <el-radio-group v-model="games.home" size="small" @change="changeNum">
                    <el-radio-button label="1">近10场</el-radio-button>
                    <el-radio-button label="2">近20场</el-radio-button>
                  </el-radio-group>
                  <el-checkbox v-model="radioBtn.home" @change="handleCheckedCitiesChange(2)">主场</el-checkbox>
                  <el-checkbox-group v-model="sessionChoose.home" :min="1" @change="handleCheckedCitiesChange(2)">
                    <el-checkbox v-for="item in session" :label="item.e" :key="item.e">{{item.c}}</el-checkbox>
                  </el-checkbox-group>
                </div>
                <el-table :data="showHome" stripe style="width: 100%" v-loading="meetLoading.home">
                  <el-table-column prop="competition_time" width="100">
                    <template slot="header">
                      <img :src="matchDetail.home.logo" class="teamLogo" alt="">
                      <span>{{matchDetail.home_name_abb}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="home_name_abb" label="主队">
                    <template slot-scope="scope">
                      <span @click="jumpTeam('CBA',scope.row.home_id)">{{scope.row.home_name_abb}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="point" label="比分" width="90">
                    <template slot-scope="scope">
                      <span @click="jumpMatch('CBA',scope.row.competition_id)">{{scope.row.home_all}} - {{scope.row.away_all}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="away_name_abb" label="客队">
                    <template slot-scope="scope">
                      <span @click="jumpTeam('CBA',scope.row.away_id)">{{scope.row.away_name_abb}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="competition_result" label="胜负" width="76">
                    <template slot-scope="scope">
                      <span :style="result_in(scope.row.competition_result)">{{scope.row.competition_result}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="gap" label="分差" width="76"></el-table-column>
                  <el-table-column prop="total" label="和数" width="76"></el-table-column>
                  <el-table-column prop="bet365_current_asian.handicap" label="让分" width="76"></el-table-column>
                  <el-table-column prop="asian_current_result" label="走势" width="76">
                    <template slot-scope="scope">
                      <span :style="result_in(scope.row.asian_current_result)">{{scope.row.asian_current_result}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="bet365_current_over.handicap" label="总分" width="76"></el-table-column>
                  <el-table-column prop="total_current_result" label="走势" width="76">
                    <template slot-scope="scope">
                      <span :style="result_in(scope.row.total_current_result)">{{scope.row.total_current_result}}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div style="height: 12px"></div>
              <div>
                <div class="btnMatch">
                  <el-radio-group v-model="games.away" size="small" @change="changeNum">
                    <el-radio-button label="1">近10场</el-radio-button>
                    <el-radio-button label="2">近20场</el-radio-button>
                  </el-radio-group>
                  <el-checkbox v-model="radioBtn.away" @change="handleCheckedCitiesChange(3)">客场</el-checkbox>
                  <el-checkbox-group v-model="sessionChoose.away" :min="1" @change="handleCheckedCitiesChange(3)">
                    <el-checkbox v-for="item in session" :label="item.e" :key="item.e">{{item.c}}</el-checkbox>
                  </el-checkbox-group>
                </div>
                <el-table :data="showAway" stripe style="width: 100%" v-loading="meetLoading.away">
                  <el-table-column prop="competition_time" width="100">
                    <template slot="header">
                      <img :src="matchDetail.away.logo" class="teamLogo" alt="">
                      <span>{{matchDetail.away_name_abb}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="home_name_abb" label="主队">
                    <template slot-scope="scope">
                      <span @click="jumpTeam('CBA',scope.row.home_id)">{{scope.row.home_name_abb}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="point" label="比分" width="90">
                    <template slot-scope="scope">
                      <span @click="jumpMatch('CBA',scope.row.competition_id)">{{scope.row.home_all}} - {{scope.row.away_all}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="away_name_abb" label="客队">
                    <template slot-scope="scope">
                      <span @click="jumpTeam('CBA',scope.row.away_id)">{{scope.row.away_name_abb}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="competition_result" label="胜负" width="76">
                    <template slot-scope="scope">
                      <span :style="result_in(scope.row.competition_result)">{{scope.row.competition_result}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="gap" label="分差" width="76"></el-table-column>
                  <el-table-column prop="total" label="和数" width="76"></el-table-column>
                  <el-table-column prop="bet365_current_asian.handicap" label="让分" width="76"></el-table-column>
                  <el-table-column prop="asian_current_result" label="走势" width="76">
                    <template slot-scope="scope">
                      <span :style="result_in(scope.row.asian_current_result)">{{scope.row.asian_current_result}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="bet365_current_over.handicap" label="总分" width="76"></el-table-column>
                  <el-table-column prop="total_current_result" label="走势" width="76">
                    <template slot-scope="scope">
                      <span :style="result_in(scope.row.total_current_result)">{{scope.row.total_current_result}}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="blue title" id="future" ref="future">未来三场</div>
            <div class="boxHeight future headTop">
              <div>
                <div>
                  <div @click="jumpTeam('CBA',matchDetail.home_id)" class="canClick">
                    <img :src="matchDetail.home.logo" alt="">
                    <span>{{matchDetail.home_name_abb}}</span>
                  </div>
                </div>
                <div>
                  <div>时间</div>
                  <div>主队</div>
                  <div>客队</div>
                  <div>间隔</div>
                </div>
                <div v-for="item in dataShow.next_home_games" :key="item">
                  <div>{{item.competitionTime}}</div>
                  <div @click="jumpTeam('CBA',item.home_id)">{{item.home_name_abb}}</div>
                  <div @click="jumpTeam('CBA',item.away_id)">{{item.away_name_abb}}</div>
                  <div>{{item.interval}}天</div>
                </div>
              </div>
              <div>
                <div>
                  <div @click="jumpTeam('CBA',matchDetail.away_id)" class="canClick">
                    <img :src="matchDetail.away.logo" alt="">
                    <span>{{matchDetail.away_name_abb}}</span>
                  </div>
                </div>
                <div>
                  <div>时间</div>
                  <div>主队</div>
                  <div>客队</div>
                  <div>间隔</div>
                </div>
                <div v-for="item in dataShow.next_away_games" :key="item">
                  <div>{{item.competitionTime}}</div>
                  <div @click="jumpTeam('CBA',item.home_id)">{{item.home_name_abb}}</div>
                  <div @click="jumpTeam('CBA',item.away_id)">{{item.away_name_abb}}</div>
                  <div>{{item.interval}}天</div>
                </div>
              </div>
            </div>
            <div class="blue title" id="rank" ref="rank">攻防排名</div>
            <div class="boxHeight ranking headTop">
              <div>
                <div>
                  <div>
                    <div>
                      <div @click="jumpTeam('CBA',matchDetail.home_id)" class="canClick">
                        <img :src="matchDetail.home.logo" alt="">
                        <span>{{matchDetail.home_name_abb}}</span>
                      </div>
                    </div>
                    <div>数据</div>
                    <div>排名</div>
                  </div>
                  <div>
                    <div>场均得分</div>
                    <div v-if="dataShow.home_statistic">{{dataShow.home_statistic.points_per_game}}</div>
                    <div v-if="dataShow.home_statistic">{{dataShow.home_statistic.rank_points_per}}</div>
                  </div>
                  <div>
                    <div>投篮命中率</div>
                    <div v-if="dataShow.home_statistic">{{dataShow.home_statistic.shot_percentage}}</div>
                    <div v-if="dataShow.home_statistic">{{dataShow.home_statistic.rank_shot_percentage}}</div>
                  </div>
                  <div>
                    <div>3分命中率</div>
                    <div v-if="dataShow.home_statistic">{{dataShow.home_statistic.three_percentage}}</div>
                    <div v-if="dataShow.home_statistic">{{dataShow.home_statistic.rank_three_percentage}}</div>
                  </div>
                  <div>
                    <div>罚篮命中率</div>
                    <div v-if="dataShow.home_statistic">{{dataShow.home_statistic.foul_percentage}}</div>
                    <div v-if="dataShow.home_statistic">{{dataShow.home_statistic.rank_foul_percentage  }}</div>
                  </div>
                  <div>
                    <div>场均篮板</div>
                    <div v-if="dataShow.home_statistic">{{dataShow.home_statistic.rebound}}</div>
                    <div v-if="dataShow.home_statistic">{{dataShow.home_statistic.rank_rebound}}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <div @click="jumpTeam('CBA',matchDetail.home_id)" class="canClick">
                        <img :src="matchDetail.home.logo" alt="">
                        <span>{{matchDetail.home_name_abb}}</span>
                      </div>
                    </div>
                    <div>数据</div>
                    <div>排名</div>
                  </div>
                  <div>
                    <div>场均失分</div>
                    <div v-if="dataShow.home_statistic">{{dataShow.home_statistic.points_per_game_rival}}</div>
                    <div v-if="dataShow.home_statistic">{{dataShow.home_statistic.rank_points_per_rival}}</div>
                  </div>
                  <div>
                    <div>对方投篮命中率</div>
                    <div v-if="dataShow.home_statistic">{{dataShow.home_statistic.shot_percentage_rival}}</div>
                    <div v-if="dataShow.home_statistic">{{dataShow.home_statistic.rank_shot_percentage_rival}}</div>
                  </div>
                  <div>
                    <div>对方3分命中率</div>
                    <div v-if="dataShow.home_statistic">{{dataShow.home_statistic.three_percentage_rival}}</div>
                    <div v-if="dataShow.home_statistic">{{dataShow.home_statistic.rank_three_percentage_rival}}</div>
                  </div>
                  <div>
                    <div>对方罚篮命中率</div>
                    <div v-if="dataShow.home_statistic">{{dataShow.home_statistic.foul_percentage_rival}}</div>
                    <div v-if="dataShow.home_statistic">{{dataShow.home_statistic.rank_foul_percentage_rival}}</div>
                  </div>
                  <div>
                    <div>对方场均篮板</div>
                    <div v-if="dataShow.home_statistic">{{dataShow.home_statistic.rebound_rival}}</div>
                    <div v-if="dataShow.home_statistic">{{dataShow.home_statistic.rank_rebound_rival}}</div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <div>
                      <div @click="jumpTeam('CBA',matchDetail.away_id)" class="canClick">
                        <img :src="matchDetail.away.logo" alt="">
                        <span>{{matchDetail.away_name_abb}}</span>
                      </div>
                    </div>
                    <div>数据</div>
                    <div>排名</div>
                  </div>
                  <div>
                    <div>场均得分</div>
                    <div v-if="dataShow.away_statistic">{{dataShow.away_statistic.points_per_game}}</div>
                    <div v-if="dataShow.away_statistic">{{dataShow.away_statistic.rank_points_per}}</div>
                  </div>
                  <div>
                    <div>投篮命中率</div>
                    <div v-if="dataShow.away_statistic">{{dataShow.away_statistic.shot_percentage}}</div>
                    <div v-if="dataShow.away_statistic">{{dataShow.away_statistic.rank_shot_percentage}}</div>
                  </div>
                  <div>
                    <div>3分命中率</div>
                    <div v-if="dataShow.away_statistic">{{dataShow.away_statistic.three_percentage}}</div>
                    <div v-if="dataShow.away_statistic">{{dataShow.away_statistic.rank_three_percentage}}</div>
                  </div>
                  <div>
                    <div>罚篮命中率</div>
                    <div v-if="dataShow.away_statistic">{{dataShow.away_statistic.foul_percentage}}</div>
                    <div v-if="dataShow.away_statistic">{{dataShow.away_statistic.rank_foul_percentage  }}</div>
                  </div>
                  <div>
                    <div>场均篮板</div>
                    <div v-if="dataShow.away_statistic">{{dataShow.away_statistic.rebound}}</div>
                    <div v-if="dataShow.away_statistic">{{dataShow.away_statistic.rank_rebound}}</div>
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <div @click="jumpTeam('CBA',matchDetail.away_id)" class="canClick">
                        <img :src="matchDetail.away.logo" alt="">
                        <span>{{matchDetail.away_name_abb}}</span>
                      </div>
                    </div>
                    <div>数据</div>
                    <div>排名</div>
                  </div>
                  <div>
                    <div>场均失分</div>
                    <div v-if="dataShow.away_statistic">{{dataShow.away_statistic.points_per_game_rival}}</div>
                    <div v-if="dataShow.away_statistic">{{dataShow.away_statistic.rank_points_per_rival}}</div>
                  </div>
                  <div>
                    <div>对方投篮命中率</div>
                    <div v-if="dataShow.away_statistic">{{dataShow.away_statistic.shot_percentage_rival}}</div>
                    <div v-if="dataShow.away_statistic">{{dataShow.away_statistic.rank_shot_percentage_rival}}</div>
                  </div>
                  <div>
                    <div>对方3分命中率</div>
                    <div v-if="dataShow.away_statistic">{{dataShow.away_statistic.three_percentage_rival}}</div>
                    <div v-if="dataShow.away_statistic">{{dataShow.away_statistic.rank_three_percentage_rival}}</div>
                  </div>
                  <div>
                    <div>对方罚篮命中率</div>
                    <div v-if="dataShow.away_statistic">{{dataShow.away_statistic.foul_percentage_rival}}</div>
                    <div v-if="dataShow.away_statistic">{{dataShow.away_statistic.rank_foul_percentage_rival}}</div>
                  </div>
                  <div>
                    <div>对方场均篮板</div>
                    <div v-if="dataShow.away_statistic">{{dataShow.away_statistic.rebound_rival}}</div>
                    <div v-if="dataShow.away_statistic">{{dataShow.away_statistic.rank_rebound_rival}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="blue title" id="injured" ref="injured">球员伤停</div>
            <div class="boxHeight headTop">
              <div>
                <div>
                  <div class="btnMatch">
                    <div @click="jumpTeam('CBA',matchDetail.home_id)" class="canClick">
                      <img :src="matchDetail.home.logo" alt="">
                      <span>{{matchDetail.home_name_abb}}</span>
                    </div>
                  </div>
                  <el-table :data="dataShow.home_injury" style="width: 100%" v-loading="meetLoading.away" @row-click="jumpToPlayer">
                    <el-table-column prop="player_name" label="球员" width="120"></el-table-column>
                    <el-table-column prop="player_position" label="位置" width="80"></el-table-column>
                    <el-table-column prop="status" label="状态" width="300"></el-table-column>
                    <el-table-column prop="content" label="详情"></el-table-column>
                  </el-table>
                </div>
                <div style="height: 12px"></div>
                <div>
                  <div class="btnMatch">
                    <div @click="jumpTeam('CBA',matchDetail.away_id)" class="canClick">
                      <img :src="matchDetail.away.logo" alt="">
                      <span>{{matchDetail.away_name_abb}}</span>
                    </div>
                  </div>
                  <el-table :data="dataShow.away_injury" style="width: 100%" v-loading="meetLoading.away" @row-click="jumpToPlayer">
                    <el-table-column prop="player_name" label="球员" width="120"></el-table-column>
                    <el-table-column prop="player_position" label="位置" width="90"></el-table-column>
                    <el-table-column prop="status" label="状态" width="300"></el-table-column>
                    <el-table-column prop="content" label="详情"></el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="指数" name="index">
            <el-radio-group v-model="radio" size="medium" @change="changeNum" class="btn" v-if="showIndex.length">
              <el-radio-button label="1">让分</el-radio-button>
              <el-radio-button label="2">总分</el-radio-button>
              <el-radio-button label="3">胜负</el-radio-button>
            </el-radio-group>
            <el-table stripe :data="showIndex" style="width: 100%" v-if="showIndex.length" :header-cell-style="{'text-align':'center'}"
                      :cell-style="{'text-align':'center'}">
              <el-table-column prop="company" label="公司"></el-table-column>
              <el-table-column label="初">
                <el-table-column prop="home" :label="showLabel[0]"></el-table-column>
                <el-table-column prop="handicap" :label="showLabel[2]" v-if="showIndex[0].handicap"></el-table-column>
                <el-table-column prop="away" :label="showLabel[1]"></el-table-column>
              </el-table-column>
              <el-table-column label="即">
                <el-table-column prop="current_last.home" :label="showLabel[0]"></el-table-column>
                <el-table-column prop="current_last.handicap" :label=showLabel[2] v-if="showIndex[0].handicap"></el-table-column>
                <el-table-column prop="current_last.away" :label="showLabel[1]"></el-table-column>
              </el-table-column>
            </el-table>
            <el-empty description="暂无指数数据" v-if="!showIndex.length" image="https://lq.sotips.cn/img/empty.png" :image-size="100" style="background: #FFFFFF"></el-empty>
          </el-tab-pane>
          <el-tab-pane label="球员" name="player" :disabled="!player.home.player.length">
            <el-radio-group v-model="team" size="medium" @change="changeNum" class="btn">
              <el-radio-button label="1">{{matchDetail.home_name_abb}}</el-radio-button>
              <el-radio-button label="2">{{matchDetail.away_name_abb}}</el-radio-button>
            </el-radio-group>
            <el-table stripe :data="showTeam" style="width: 100%" @row-click="jumpToPlayer" v-if="player.home">
              <el-table-column prop="player" label="球员" width="120">
                <template slot-scope="scope">
                  <span v-if="scope.row.player_name">{{scope.row.player_name}}</span>
                  <span v-if="!scope.row.player_name">{{scope.row.player_en}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="player.position" label="位置" width="50"></el-table-column>
              <el-table-column prop="minutes" label="时间" width="60"></el-table-column>
              <el-table-column prop="shot" label="投篮" width="60">
                <template slot-scope="scope">
                  <span>{{scope.row.shot_on}} - {{scope.row.shot}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="three_points_on" label="3分" width="60">
                <template slot-scope="scope">
                  <span>{{scope.row.three_points_on}} - {{scope.row.three_points}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="foul_shot_on" label="罚篮" width="60">
                <template slot-scope="scope">
                  <span>{{scope.row.foul_shot_on}} - {{scope.row.foul_shot}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="points" label="得分" width="53"></el-table-column>
              <el-table-column prop="offensive_rebound" label="进攻" width="53"></el-table-column>
              <el-table-column prop="defensive_rebound" label="防守" width="53"></el-table-column>
              <el-table-column prop="rebound" label="篮板" width="53"></el-table-column>
              <el-table-column prop="assists" label="助攻" width="53"></el-table-column>
              <el-table-column prop="fouls" label="犯规" width="53"></el-table-column>
              <el-table-column prop="steals" label="抢断" width="53"></el-table-column>
              <el-table-column prop="blocks" label="盖帽" width="53"></el-table-column>
              <el-table-column prop="positive_negative_value" label="±值" width="53"></el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="sidebar">
        <div>
          <div>当日比赛</div>
          <div v-for="item in todayMatch" :key="item" @click="jumpDetail(item.competition_id)" :class="[item.competition_id !== $route.query.id*1? '':'currentTeam']">
            <div>
              <img :src="item.home.logo" alt="">
              <div>{{item.home_name_abb}}</div>
            </div>
            <div>
              <div>{{item.status}}</div>
              <div>{{item.home_all}} : {{item.away_all}}</div>
            </div>
            <div>
              <img :src="item.away.logo" alt="">
              <div>{{item.away_name_abb}}</div>
            </div>
          </div>
          <div v-if="!todayMatch.length" style="height: 160px">
            <el-empty description="今日暂无赛事" image="https://lq.sotips.cn/img/empty.png" :image-size="100"></el-empty>
          </div>
        </div>
      </div>
    </div>
    <div class="floatBar" v-if="activeName==='data'">
        <a href="#total"><div :class="[show===1? 'choose':'']" @click="show = 1">战绩统计</div></a>
        <a href="#meet"><div :class="[show===2? 'choose':'']" @click="show = 2">交锋战绩</div></a>
        <a href="#match"><div :class="[show===3? 'choose':'']" @click="show = 3">主客战绩</div></a>
        <a href="#future"><div :class="[show===4? 'choose':'']" @click="show = 4">未来三场</div></a>
        <a href="#rank"><div :class="[show===5? 'choose':'']" @click="show = 5">攻防排名</div></a>
        <a href="#injured"><div :class="[show===7? 'choose':'']" @click="show = 7">球员伤停</div></a>
    </div>
    <div style="height: 50px"></div>
    <el-backtop :bottom="150"></el-backtop>
  </div>
</template>

<script>
import {
  awayResult,
  detail,
  getData,
  handicap,
  homeResult,
  players,
  sameResult,
  todayCompetitions
} from '@/api/CBA/point'

export default {
  name: "team",
  data(){
    return{
      //外层变量
      loading: false,
      todayMatch: [],
      matchDetail: {},
      //数据
        //选项卡
        activeName: 'data',
        //走势统计、未来三场、攻防排名、球员伤停
        dataShow: [],
        //交锋战绩
        meetingTop: {},
        showMeeting: [],
        //主队战绩
        homeTop: {},
        showHome: [],
        //客队战绩
        awayTop: {},
        showAway: [],
        //战绩按钮组
        meetLoading: {
          same: false,
          home: false,
          away: false,
        },
        radioBtn: {
          same: false,
          home: false,
          away: false,
        },
        games: {
          bout: '1',
          home: '1',
          away: '1',
        },
        sessionChoose: {
          same: ['1','2'],
          home: ['1','2'],
          away: ['1','2'],
        },
        session: [{e:'1',c:'常规赛'},{e:'2',c:'季后赛'}],
      //球队
      team: '1',
      player: [],
      showTeam: [],
      //指数
      radio: '1',
      index: {},
      showIndex: [],
      showLabel: ['主','客','让分'],
      radioLabel: [
        ['主','客','让分'],
        ['大','小','总分'],
        ['主','客','']
      ],
      //浮动栏
      show: 1,
    }
  },
  methods:{
    //比赛详情跳转
    jumpDetail(id){
      if (id !== this.$route.query.id*1){
        this.$router.push({query: {id: id,type: this.activeName}});
        this.getDetail(id)
      }
    },
    //裁判统计跳转
    jumpToReferee(row){
      const page = this.$router.resolve({path: '/CBA/refereeDetail', query: {name: row.name}})
      window.open(page.href,'_blank')
    },
    //伤病球员跳转
    jumpToPlayer(row){
      const page = this.$router.resolve({path: '/CBA/playerDetail', query: {id: row.player_id}})
      window.open(page.href,'_blank')
    },
    soTips(){
      window.open('http://vip.sotips.cn/','_blank')
    },
    //按钮切换
    changeNum(){
      switch (this.radio){
        case "1": this.showIndex = this.index.first_asian; this.showLabel = this.radioLabel[0]; break;
        case "2": this.showIndex = this.index.first_over; this.showLabel = this.radioLabel[1]; break;
        case "3": this.showIndex = this.index.first_odds; this.showLabel = this.radioLabel[2]; break;
      }
      switch (this.team){
        case "1": this.showTeam = this.player.home.player; break;
        case "2": this.showTeam = this.player.away.player; break;
      }
      switch (this.games.bout){
        case "1": this.showMeeting = this.meetingTop.ten; break;
        case "2": this.showMeeting = this.meetingTop.twenty; break;
      }
      switch (this.games.home){
        case "1": this.showHome = this.homeTop.ten; break;
        case "2": this.showHome = this.homeTop.twenty; break;
      }
      switch (this.games.away){
        case "1": this.showAway = this.awayTop.ten; break;
        case "2": this.showAway = this.awayTop.twenty; break;
      }
    },
    //展示切换
    handleClick() {
      if (this.activeName !== this.$route.query.type){
        this.$router.push({query: {id: this.$route.query.id,type: this.activeName}});
      }
    },
    //勾选请求数据
    handleCheckedCitiesChange(type){
      switch (type){
        case 1: this.getMeeting(); break;
        case 2: this.getHomeMatch(); break;
        case 3: this.getAwayMatch(); break;
      }
    },
    //获取比赛详情
    getDetail(id){
      this.loading = true
      detail(id).then( res => {
        this.loading = false
        this.matchDetail = res.data.data[0]
        this.matchDetail.competitionTime = this.matchDetail.competition_time.substring(5, this.matchDetail.competition_time.length - 3)
        this.matchDetail.status = this.matchDetail.status === 'Final' ? '已完结' : this.matchDetail.status
        this.matchDetail.status = this.matchDetail.status === '' ? '未开赛' : this.matchDetail.status
        this.getData(id)
        this.getIndex(id)
        if (this.matchDetail.status !== '未开赛'){
          this.getPlayer(id)
        }
        //获取今日比赛
        todayCompetitions(this.matchDetail.competition_time).then( res => {
          this.todayMatch = res.data.data
          for (let x in this.todayMatch){
            this.todayMatch[x].status = this.todayMatch[x].status === 'Final' ? '已完结' : this.todayMatch[x].status
            this.todayMatch[x].status = this.todayMatch[x].status === '' ? '未开赛' : this.todayMatch[x].status
          }
        })
      })
    },
    //获取指数详情
    getIndex(id){
      handicap(id).then(res => {
        this.index = res.data.data
        this.showIndex = this.index.first_asian
      })
    },
    //获取球员详情
    getPlayer(id){
      players(id).then(res => {
        this.player = res.data.data
        this.showTeam = this.player.home.player
      })
    },
    //走势统计、未来三场、攻防排名、球员伤停
    getData(id){
      getData(id).then(res => {
        this.dataShow = res.data.data
        let matchTime = new Date(this.matchDetail.competition_time.replace(/-/g, "/")).getTime()
        for (let x in this.dataShow.next_home_games){
          let time = this.dataShow.next_home_games[x].competition_time.replace(/-/g, "/")
          let futureMatch = new Date(time).getTime()
          this.dataShow.next_home_games[x].interval = Math.round((futureMatch - matchTime)/(24*60*60*1000))
          this.dataShow.next_home_games[x].competitionTime = this.dataShow.next_home_games[x].competition_time.substring(5,this.dataShow.next_home_games[x].competition_time.length-9)
        }
        for (let x in this.dataShow.next_away_games){
          let time = this.dataShow.next_away_games[x].competition_time.replace(/-/g, "/")
          let futureMatch = new Date(time).getTime()
          this.dataShow.next_away_games[x].interval = Math.round((futureMatch - matchTime)/(24*60*60*1000))
          this.dataShow.next_away_games[x].competitionTime = this.dataShow.next_away_games[x].competition_time.substring(5,this.dataShow.next_away_games[x].competition_time.length-9)
        }
      })
      this.getMeeting()
      this.getHomeMatch()
      this.getAwayMatch()
    },
    //交锋战绩
    getMeeting(){
      this.meetLoading.same = true
      sameResult({
        home_id: this.matchDetail.home.team_id,
        away_id: this.matchDetail.away.team_id,
        time: this.matchDetail.competition_time,
        same: this.radioBtn.same,
        phase_id: this.sessionChoose.same
      }).then(res => {
        this.meetLoading.same = false
        for (let x in res.data.data) {
          res.data.data[x].competition_time = res.data.data[x].competition_time.substring(2, res.data.data[x].competition_time.length - 9)
          if(res.data.data[x].status){
            res.data.data[x].competition_result = this.resultN(res.data.data[x].competition_result)
            res.data.data[x].gap = res.data.data[x].home_all - res.data.data[x].away_all
            res.data.data[x].total = res.data.data[x].home_all * 1 + res.data.data[x].away_all * 1
            res.data.data[x].asian_current_result = this.resultN(res.data.data[x].asian_current_result, 'asian')
            res.data.data[x].total_current_result = this.resultN(res.data.data[x].total_current_result, 'over')
          }
        }
        this.meetingTop.ten = JSON.parse(JSON.stringify(res.data.data))
        this.meetingTop.ten.length = 10
        this.meetingTop.twenty = JSON.parse(JSON.stringify(res.data.data))
        this.meetingTop.twenty.length = 20
        if (this.games.bout === '2'){
          this.showMeeting = this.meetingTop.twenty
        } else {
          this.showMeeting = this.meetingTop.ten
        }
      })
    },
    //主队战绩
    getHomeMatch(){
      this.meetLoading.home = true
      homeResult({
        team_id: this.matchDetail.home.team_id,
        time: this.matchDetail.competition_time,
        home: this.radioBtn.home,
        phase_id: this.sessionChoose.home
      }).then(res => {
        this.meetLoading.home = false
        for (let x in res.data.data) {
          res.data.data[x].competition_time = res.data.data[x].competition_time.substring(2, res.data.data[x].competition_time.length - 9)
          if(res.data.data[x].status){
            res.data.data[x].competition_result = this.resultN(res.data.data[x].competition_result)
            res.data.data[x].gap = res.data.data[x].home_all - res.data.data[x].away_all
            res.data.data[x].total = res.data.data[x].home_all * 1 + res.data.data[x].away_all * 1
            res.data.data[x].asian_current_result = this.resultN(res.data.data[x].asian_current_result, 'asian')
            res.data.data[x].total_current_result = this.resultN(res.data.data[x].total_current_result, 'over')
          }
        }
        this.homeTop.ten = JSON.parse(JSON.stringify(res.data.data))
        this.homeTop.ten.length = 10
        this.homeTop.twenty = JSON.parse(JSON.stringify(res.data.data))
        this.homeTop.twenty.length = 20
        if (this.games.home === '2'){
          this.showHome = this.homeTop.twenty
        } else {
          this.showHome = this.homeTop.ten
        }
      })
    },
    //客队战绩
    getAwayMatch(){
      this.meetLoading.away = true
      awayResult({
        team_id: this.matchDetail.away.team_id,
        time: this.matchDetail.competition_time,
        away: this.radioBtn.away,
        phase_id: this.sessionChoose.away
      }).then(res => {
        this.meetLoading.away = false
        for (let x in res.data.data) {
          res.data.data[x].competition_time = res.data.data[x].competition_time.substring(2, res.data.data[x].competition_time.length - 9)
          if(res.data.data[x].status){
            res.data.data[x].point = res.data.data[x].home_all + ' - ' + res.data.data[x].away_all
            res.data.data[x].competition_result = this.resultN(res.data.data[x].competition_result)
            res.data.data[x].gap = res.data.data[x].home_all - res.data.data[x].away_all
            res.data.data[x].total = res.data.data[x].home_all * 1 + res.data.data[x].away_all * 1
            res.data.data[x].asian_current_result = this.resultN(res.data.data[x].asian_current_result, 'asian')
            res.data.data[x].total_current_result = this.resultN(res.data.data[x].total_current_result, 'over')
          }
        }
        this.awayTop.ten = JSON.parse(JSON.stringify(res.data.data))
        this.awayTop.ten.length = 10
        this.awayTop.twenty = JSON.parse(JSON.stringify(res.data.data))
        this.awayTop.twenty.length = 20
        if (this.games.away === '2'){
          this.showAway = this.awayTop.twenty
        } else {
          this.showAway = this.awayTop.ten
        }
      })
    },
    //悬浮栏监听
    handleScrollX() {
      if (this.activeName === 'data'){
        let title = document.getElementsByClassName('blue title')
        let boxHeight = document.getElementsByClassName('boxHeight')
        if (400 >= title[6].getBoundingClientRect().top){
          if (window.location.href.indexOf('referee') === -1){
            this.show = 7
          }
        } else if (260 >= title[5].getBoundingClientRect().top && title[5].getBoundingClientRect().top > -boxHeight[5].clientHeight){
          this.show = 6
        } else if (0 >= title[4].getBoundingClientRect().top && title[4].getBoundingClientRect().top > -boxHeight[4].clientHeight){
          this.show = 5
        } else if (0 >= title[3].getBoundingClientRect().top && title[3].getBoundingClientRect().top > -boxHeight[3].clientHeight){
          this.show = 4
        }else if (0 >= title[2].getBoundingClientRect().top && title[2].getBoundingClientRect().top > -boxHeight[2].clientHeight){
          this.show = 3
        } else if (0 >= title[1].getBoundingClientRect().top && title[1].getBoundingClientRect().top > -boxHeight[1].clientHeight){
          this.show = 2
        } else if (title[0].getBoundingClientRect().top > -boxHeight[0].clientHeight){
          this.show = 1
        }
      }
    },
  },
  mounted() {
    window.addEventListener('scroll',this.handleScrollX,true)
    this.activeName = this.$route.query.type? this.$route.query.type : this.activeName
    this.getDetail(this.$route.query.id)
  },
  computed: {
    result_in() {
      return (icontent) => {
        if (icontent === "主") {
          return {'color': '#DE3C31'}
        } else if (icontent === "平") {
          return {'color': '#67c23a'}
        } else if (icontent === "客") {
          return {'color': '#1a71c8'}
        } else if (icontent === "赢") {
          return {'color': '#DE3C31'}
        } else if (icontent === "走") {
          return {'color': '#67c23a'}
        } else if (icontent === "输") {
          return {'color': '#1a71c8'}
        } else if (icontent === "大") {
          return {'color': '#DE3C31'}
        } else if (icontent === "小") {
          return {'color': '#1a71c8'}
        } else if (icontent === "胜") {
          return {'color': '#DE3C31'}
        } else if (icontent === "负") {
          return {'color': '#1a71c8'}
        }
      }
    },
    win_lose(){
      return (icontent) => {
        if (icontent >= 60) {
          return {'color': '#DE3C31'}
        } else if (icontent <= 40) {
          return {'color': '#67c23a'}
        }
      }
    }
  }
}
</script>

<style scoped>
  .head{
    height: 213px;
    padding: 10px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 12px;
    background-image: url("../../../../assets/pointBack.png");
  }
  .head img{
    width: 70px;
    height: 70px;
    margin-top: -10px;
  }
  .head > :first-child{
    padding: 34px 150px 10px;
    display: flex;
    justify-content: space-around;
  }
  .teamName{
    width: 200px;
    font-size: 26px;
    margin: 6px 20px 0;
  }
  .point{
    font-size: 26px;
    margin: 6px 40px 0;
  }
  .center{
    width: 120px;
    margin-top: -8px;
    font-size: 14px;
  }
  .center > :nth-child(2){
    font-size: 22px;
    margin: 4px 0;
  }
  .pointTable{
    margin-top: 8px;
    display: flex;
    justify-content: space-around;
  }
  .pointTable > div > div{
    width: 80px;
    padding: 4px 0;
    line-height: 22px;
  }
  .pointTable > :last-child > div {
    width: 102px ;
  }
  .content{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .fontSize > :nth-child(2n){
    font-size: 14px;
  }
  .panTotal{
    text-align: center;
    background: #FFFFFF;
  }
  .panTotal > div{
    display: flex;
    justify-content: space-between;
  }
  .panTotal > div > div{
    width: 30%;
    padding: 6px;
  }
  .panTotal > div > :first-child{
    width: 10%;
  }
  .panTotal > div:nth-child(n+2) > div{
    border: 1px solid #EBEEF5;
  }
  .panTotal > div:nth-child(2n){
    background: #FAFAFA;
  }
  .panTotal > :nth-child(n+2) > :nth-child(n+2){
    display: flex;
    justify-content: space-between;
  }
  .panTotal > div > div > div{
    width: 33%;
  }
  .total{
    width: 892px;
    background: #f1f1f1;
  }
  .future{
    display: flex;
    justify-content: space-between;
  }
  .future > div{
    width: 49%;
    background: #FFFFFF;
    border-bottom: 1px solid #EBEEF5;
  }
  .future > div > div{
    padding: 12px;
    border-bottom: 1px solid #E9EAEA;
    display: flex;
    justify-content: space-between;
  }
  .future > div > div > div{
    width: 25%;
  }
  .future > :first-child > :nth-child(n+3){
    background: #FBF7E7;
  }
  .future > :first-child > :nth-child(4){
    background: #FEFDEE;
  }
  .future > :last-child > :nth-child(n+3){
    background: #E8F0FA;
  }
  .future > :last-child > :nth-child(4){
    background: #EEF5FF;
  }
  .ranking > div{
    display: flex;
    justify-content: space-between;
  }
  .ranking > :first-child{
    margin-bottom: 12px;
  }
  .ranking > div > div{
    width: 49%;
    text-align: center;
    background: #FFFFFF;
  }
  .ranking > div > div > div{
    border-bottom: 1px solid #E9EAEA;
    display: flex;
    justify-content: space-between;
  }
  .ranking > div > div > div > div{
    width: 33%;
    padding: 12px;
  }
  .ranking > div > div > :nth-child(2n){
    background: #FAFAFA;
  }
  .total .btn{
    padding: 12px;
  }
  .totalBar{
    font-size: 14px;
    margin-top: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .totalBar > div{
    width: 49%;
    background: #FFFFFF;
  }
  .totalBar > :first-child > div{
    padding: 12px;
    border-bottom: 1px solid #EBEEF5;
    display: flex;
    justify-content: space-between;
  }
  .totalBar > div > div > div{
    width: 33%;
  }
  .totalBar > div > div > div:nth-child(2){
    text-align: center;
  }
  .totalBar > div > div > div:last-child{
    text-align: right;
  }
  .totalBar > :last-child > div{
    display: flex;
    justify-content: space-between;
  }
  .totalBar > :last-child > :first-child{
    padding: 12px;
    border-bottom: 1px solid #EBEEF5;
  }
  .totalBar > :last-child > div > div{
    width: 50%;
  }
  .totalBar > :last-child > div > div > div{
    padding: 12px;
    border-bottom: 1px solid #EBEEF5;
  }
  .btnMatch{
    height: 50px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-bottom: 1px solid #EBEEF5;
  }
  .btnMatch > :nth-child(n+2){
    margin-left: 36px;
  }
  >>> .el-tabs__header {
    margin: 0;
    padding: 0 266px;
    background: #FFFFFF;
  }
  >>> .el-tabs__nav > div {
    padding: 0;
    width: 120px;
    font-size: 15px;
    text-align: center;
  }
  >>> .el-tabs__content > div {
    background: #F7F7F8;
  }
  .sidebar{
    width: 291px;
    background: #f1f1f1;
  }
  .sidebar > div{
    padding: 12px;
    background: #FFFFFF;
  }
  .sidebar > :first-child{
    margin-bottom: 12px;
  }
  .sidebar > :first-child > :nth-child(n+2){
    padding: 6px 0;
    font-size: 14px;
  }
  .sidebar > :first-child > :first-child{
    padding: 6px 0;
    font-weight: bold;
    margin-bottom: 6px;
    border-bottom: 2px solid #F7F7F8;
  }
  .sidebar > :first-child span{
    color: #999999;
  }
  .sidebar img{
    width: 40px;
    height: 40px;
  }
  .sidebar > :last-child > :first-child {
    padding: 6px 0;
    font-weight: bold;
    margin-bottom: 6px;
    border-bottom: 2px solid #F7F7F8;
  }
  .sidebar > :last-child > :nth-child(n+2){
    height: 66px;
    padding: 6px 0;
    font-size: 14px;
    text-align: center;
    border-bottom: 2px solid #F7F7F8;
    display: flex;
    justify-content: space-around;
  }
  .sidebar > :last-child > :nth-child(n+2) > :nth-child(2){
    margin-top: 14px;
  }
  .floatBar{
    top: 220px;
    right: calc(50% + 620px);
    width: 90px;
    height: 260px;
    position: fixed;
    text-align: center;
    background: #FFFFFF;
  }
  .floatBar div{
    margin: 10px;
    padding: 6px 0;
    font-size: 14px;
  }
  .choose{
    color: #FFFFFF;
    border-radius: 4px;
    background: #409EFF;
  }
  .currentTeam{
    background: #ececec;
  }
  .none{
    color: #aaaaaa;
  }
  .teamLogo{
    width: 20px;
    height: 20px;
    margin-top: -4px;
    margin-right: 4px;
    vertical-align: middle;
  }
</style>